import baseUrl, { sugarUrl } from "../config/baseUrl";

const getAllCaseDetails = async ({ token, caseParams }) => {
  const queryString = new URLSearchParams(caseParams);

  try {
    const response = await fetch(
      `${baseUrl}/api/get-case-details?${queryString}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },

        // body: JSON.stringify(caseParams),
      }
    );

    const data = await response.json();
    return data;
  } catch (error) {
    return error;
  }
};

export default getAllCaseDetails;
