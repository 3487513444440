/**
 * Dashboard Component:
 * - Displays the main dashboard with document/user management and analytics.
 *
 * Features:
 * - Dynamic greeting based on time and day.
 * - Toggle forms for adding documents and users.
 * - Displays document counts, pending requests, recent uploads, and charts.
 *
 * Props:
 * - Uses context (AuthContext, ShortcutContext) for state and actions.
 *
 * Key Components:
 * - Navbar: Navigation bar.
 * - AddDocument: Form to add documents.
 * - AddUser: Form to add users.
 * - DocCountCard: Displays document statistics.
 * - ShortcutCards: Quick actions for admin and users.
 * - BarChartComponent: Displays document analytics.
 * - PendingRequestsCard: Shows pending document requests.
 * - RecentUploadsCard: Lists recent document uploads.
 *
 * Usage:
 * - Ensure the component is wrapped with the necessary providers.
 */

import { BarChartComponent } from "../components/charts/BarChart";
import { useContext } from "react";
import { AuthContext } from "../context/Auth/AuthContext";
import Navbar from "../components/common/Navbar";
import "../styles/Dashboard.css";
import { useShortcut } from "../context/Shortcuts/ShortcutContext";
import AddDocument from "../components/document-table/AddDocument";
import AddUser from "../components/user-table/AddUser";
import DocCountCard from "../components/dashboard/DocCountCard";
import ShortcutCards from "../components/dashboard/ShortcutCards";
import PendingRequestsCard from "../components/dashboard/PendingRequestsCard";
import RecentUploadsCard from "../components/dashboard/RecentUploadsCard";

export default function Dashboard() {
  const {
    showAddDocumentForm,
    toggleAddDocumentForm,
    setDocumentAddedToTrue,
    subCategoryOptions,
    showAddUserForm,
    onAddUserButtonClick,
    setUserAddedToTrue,
  } = useShortcut();

  const { is_admin, token, firstName, lastName } = useContext(AuthContext);

  const onAddDocumentButtonClick = () => {
    toggleAddDocumentForm();
  };

  const fulldate = () => {
    let dd = new Date();
    let month = dd.toLocaleString("en-US", { month: "long" });
    let dt = dd.toLocaleString("en-US", { day: "2-digit" });
    let yr = dd.getFullYear();
    return `${month} ${dt}, ${yr}`;
  };

  const greet = () => {
    let hrs = new Date().getHours();
    if (hrs < 12) return "Good Morning";
    else if (hrs >= 21) return "Good Night";
    else if (hrs >= 16) return "Good Evening";
    else return "Good Afternoon";
  };

  const greetMsg = () => {
    let day = new Date().getDay();
    return day === 0 || day === 6
      ? "HAPPY WEEKEND !!! :)"
      : "Welcome to CLG DMS";
  };

  // BOXSHADOW
  const customBoxShadow = {
    boxShadow: "0px 1px 3px #007bff",
  };

  return (
    <>
      <div className=" flex-col md:flex w-full  overflow-y-scroll">
        <div className="navbar">
          <Navbar />
        </div>
        {showAddDocumentForm || showAddUserForm ? (
          <div className="document-table w-full  p-8 pt-6">
            {/* ADD DOCUMENT FORM  */}
            {showAddDocumentForm && (
              <AddDocument
                onAddDocumentButtonClick={onAddDocumentButtonClick}
                setDocumentAddedToTrue={setDocumentAddedToTrue}
                //categoryOptions={categoryOptions}
                subCategoryOptions={subCategoryOptions}
              />
            )}
            {/* ADD USER FORM  */}
            {showAddUserForm && (
              <AddUser
                onAddUserButtonClick={onAddUserButtonClick}
                setUserAddedToTrue={setUserAddedToTrue}
              />
            )}
          </div>
        ) : (
          <div className="flex-1 space-y-4 p-8 pt-6 w-full">
            <div className="flex flex-col gap-2 w-full p-6 bg-[#3395ff] rounded-lg shadow-md ">
              <div className="flex items-center justify-between w-full">
                <p className="text-2xl font-semibold text-[#001933]">{`${greet()}, ${firstName} ${lastName}`}</p>
                <p className="text-lg text-[#001933] font-mono font-medium">
                  {fulldate()}
                </p>
              </div>
              <div className="flex justify-between w-full">
                <p className="text-base text-[#001933] italic">{greetMsg()}</p>
              </div>
            </div>

            <DocCountCard
              customBoxShadow={customBoxShadow}
              token={token}
              is_admin={is_admin}
            />
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full">
              <ShortcutCards
                customBoxShadow={customBoxShadow}
                toggleAddDocumentForm={toggleAddDocumentForm}
                onAddUserButtonClick={onAddUserButtonClick}
                is_admin={is_admin}
              />

              <BarChartComponent
                customBoxShadow={customBoxShadow}
                token={token}
              />
              <PendingRequestsCard
                customBoxShadow={customBoxShadow}
                token={token}
                is_admin={is_admin}
              />
              <RecentUploadsCard
                customBoxShadow={customBoxShadow}
                token={token}
                is_admin={is_admin}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
}
