import baseUrl from "../config/baseUrl";

const markClientUnknown = async function ({ documentID, token }) {
  try {
    const res = await fetch(
      `${baseUrl}/api/update-client-unknown?document_id=${documentID}`,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const data = await res.json();
    return data;
  } catch (error) {
    return error;
  }
};

export default markClientUnknown;
