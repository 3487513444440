import React, { useEffect, useState, useContext } from "react";
import Navbar from "../components/common/Navbar";
import StarredTable from "../components/starred-document-table/StarredTable";
import { AuthContext } from "../context/Auth/AuthContext";
import starredDocuments from "../api/starred";
import baseUrl from "../config/baseUrl";

import {
  CaretSortIcon,
  ChevronDownIcon,
  StarIcon,
  StarFilledIcon,
} from "@radix-ui/react-icons";

import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";

import { Button } from "../shadcn-components/ui/button";
import { Checkbox } from "../shadcn-components/ui/checkbox";
import { fileIcon } from "../components/common/FileIcons";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../shadcn-components/ui/dropdown-menu";
import "../styles/Starred.css";
import removeDocumentFromFavourite from "../api/removeFavouriteDocument";
import { useDebounce } from "use-debounce";

/*** Main component for listing all the starred documents ***/
const Starred = () => {
  const [loading, setLoading] = useState(true);
  const [starredDocumentsResponse, setStarredDocumentsResponse] = useState([]);
  const [documentFavouriteState, setDocumentFavouriteState] = useState(0);
  const { token, user_id, is_admin } = useContext(AuthContext);

  const [sorting, setSorting] = useState([]);
  const [columnFilters, setColumnFilters] = useState([]);
  const [columnVisibility, setColumnVisibility] = useState({});
  const [rowSelection, setRowSelection] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [globalFilter, setGlobalFilter] = useState("");
  const [debouncedGlobalFilter] = useDebounce(globalFilter, 1000);

  const [favouriteDocuments, setFavouriteDocuments] = useState(false);
  const [bulkFavouriteDocument, setBulkFavouriteDocument] = useState(false);

  const fetchStarredDocuments = async () => {
    setLoading(true);
    const offset = currentPage - 1;
    const filterParams = {};

    columnFilters.forEach((filterItem) => {
      filterParams[filterItem.id] = filterItem.value;
    });

    const sortParam = sorting
      .map((sortItem) => {
        return `${sortItem.id}=${sortItem.desc ? "desc" : "asc"}`;
      })
      .join(",");

    const params = {
      isAdmin: 1,
      userId: 1,
      limit: pageSize,
      offset: offset,
      ...filterParams,
      global_search: debouncedGlobalFilter,
      sort: sortParam,
    };
    const queryString = new URLSearchParams(params).toString();
    const url = `${baseUrl}/api/get-fav-data?${queryString}`;
    const response = await starredDocuments({ token, api: url });

    if (response.code == 200) {
      setStarredDocumentsResponse(response?.data);
      setTotalPages(Math.ceil(response?.user_count / pageSize));
    } else {
      setStarredDocumentsResponse([]);
      setTotalPages(0);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchStarredDocuments();
  }, [
    documentFavouriteState,
    debouncedGlobalFilter,
    currentPage,
    columnFilters,
    bulkFavouriteDocument,
    favouriteDocuments,
    sorting,
    pageSize,
  ]);

  const removeDocumentsFromFavouriteList = (documentList) => {
    setDocumentFavouriteState((count) => count + 1);
  };

  const handleRemoveFavourites = async (row) => {
    const { document_id } = row;
    try {
      await removeDocumentFromFavourite({ token, ids: document_id });
      setFavouriteDocuments(true);
    } catch (err) {
      console.log(err);
    }
  };

  const handleBulkAction = async (actionType) => {
    const selectedRowsData = table
      .getFilteredSelectedRowModel()
      .rows.map((row) => row.original);
    const ids = selectedRowsData.map((row) => row.document_id).join(",");
    if (actionType === "favourite") {
      setBulkFavouriteDocument(true);
      await removeDocumentFromFavourite({ token, ids: ids });
    } else {
      setBulkFavouriteDocument(false);
    }
  };

  const columns = [
    {
      id: "select",
      header: ({ table }) => (
        <div className="flex items-center">
          <Checkbox
            checked={
              table.getIsAllPageRowsSelected() ||
              (table.getIsSomePageRowsSelected() && "indeterminate")
            }
            onCheckedChange={(value) =>
              table.toggleAllPageRowsSelected(!!value)
            }
            aria-label="Select all"
          />
          {(table.getIsSomePageRowsSelected() ||
            table.getIsAllPageRowsSelected()) && (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button
                  variant="outline"
                  className="p-0 !border-none !shadow-none bg-transparent"
                >
                  <ChevronDownIcon className="" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuItem
                  onSelect={() => handleBulkAction("favourite")}
                >
                  Remove Favourites
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          )}
        </div>
      ),
      cell: ({ row }) => (
        <Checkbox
          checked={row.getIsSelected()}
          onCheckedChange={(value) => row.toggleSelected(!!value)}
          aria-label="Select row"
        />
      ),
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: "favourite",
      header: ({ table }) => <StarFilledIcon className="text-yellow-500" />,
      cell: ({ row }) =>
        row.getValue("favourite") === "1" ? (
          <StarFilledIcon
            className="text-yellow-500 cursor-pointer"
            onClick={() => handleRemoveFavourites(row.original)}
          />
        ) : (
          <StarIcon className="cursor-pointer" />
        ),
      enableSorting: false,
      enableHiding: false,
      enableColumnFilter: false,
    },
    {
      accessorKey: "name",
      displayName: "Client Name",
      filterable: true,
      header: ({ column }) => (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className="p-0"
        >
          Client Name
          <CaretSortIcon className="ml-2 h-4 w-4" />
        </Button>
      ),
      cell: ({ row }) => (
        <div className="capitalize">{row.getValue("name")}</div>
      ),
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
    },
    {
      accessorKey: "document_type",
      filterable: true,
      displayName: "Type",
      header: ({ column }) => (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className="p-0"
        >
          Type
          <CaretSortIcon className="ml-2 h-4 w-4" />
        </Button>
      ),
      cell: ({ row }) => (
        <div className="w-full flex items-center">
          {fileIcon[row.getValue("document_type").toLowerCase()]}
        </div>
      ),
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
    },
    {
      accessorKey: "document_name",
      filterable: true,
      displayName: "Document Name",
      header: ({ column }) => (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className="p-0"
        >
          Document Name
          <CaretSortIcon className="ml-2 h-4 w-4" />
        </Button>
      ),
      cell: ({ row }) => (
        <div className="capitalize">{row.getValue("document_name")}</div>
      ),
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
    },
    // {
    //   accessorKey: "document_status",
    //   displayName: "Status",
    //   header: ({ column }) => (
    //     <Button
    //       variant="ghost"
    //       onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
    //       className="p-0"
    //     >
    //       Status
    //       <CaretSortIcon className="ml-2 h-4 w-4" />
    //     </Button>
    //   ),
    //   cell: ({ row }) => (
    //     <div className="text-left">
    //       {row.getValue("document_status").toLowerCase() === "active"
    //         ? "Active"
    //         : "Inactive"}
    //     </div>
    //   ),
    //   filterFn: (row, id, value) => {
    //     return value.includes(row.getValue(id));
    //   },
    // },
    {
      accessorKey: "created_by_name",
      displayName: "Uploaded By",
      header: ({ column }) => (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className="p-0"
        >
          Uploaded By
          <CaretSortIcon className="ml-2 h-4 w-4" />
        </Button>
      ),
      cell: ({ row }) => (
        <div className="capitalize">{row.getValue("created_by_name")}</div>
      ),
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
    },
  ];

  const table = useReactTable({
    data: starredDocumentsResponse,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getCoreRowModel: getCoreRowModel(),
    initialState: {
      pageSize: 20,
    },
    manualPagination: true,
    manualSorting: true,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
      pageSize,
    },

    globalFilterFn: (rows, columnFilters) => {
      const [globalFilter] = columnFilters.filter(
        (filter) => filter.id === "global"
      );
      if (!globalFilter || !globalFilter.value) return rows;

      return rows.filter((row) => {
        const name = row.original.name.toLowerCase(); // Assuming 'name' is the column key
        return name.includes(globalFilter.value.toLowerCase());
      });
    },
    onGlobalFilterChange: (filterValue) => {
      setGlobalFilter(filterValue); // Update the global filter state
    },
  });

  return (
    <div className="starred-container overflow-auto">
      <div className="navbar">
        <Navbar />
      </div>
      <div className="starred-content">
        <div className="starred-table-content">
          <StarredTable
            table={table}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            columns={columns}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            totalPages={totalPages}
            removeDocumentsFromFavouriteList={removeDocumentsFromFavouriteList}
            starredDocumentsResponse={starredDocumentsResponse}
            pageSize={pageSize}
            setPageSize={setPageSize}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
};

export default Starred;
