import { format } from "date-fns";

export const formatDate = (dateString) => {
  if (!dateString) return "-";

  // Convert "YYYY-MM-DD HH:MM:SS" to a UTC date
  const [datePart, timePart] = dateString.split(" ");
  const [year, month, day] = datePart.split("-").map(Number);
  const [hours, minutes, seconds] = timePart.split(":").map(Number);

  const dt = new Date(Date.UTC(year, month - 1, day, hours, minutes, seconds));

  // Convert to local timezone
  const localYear = dt.getFullYear();
  const localMonth = String(dt.getMonth() + 1).padStart(2, "0");
  const localDay = String(dt.getDate()).padStart(2, "0");
  const localHours = String(dt.getHours()).padStart(2, "0");
  const localMinutes = String(dt.getMinutes()).padStart(2, "0");
  const localSeconds = String(dt.getSeconds()).padStart(2, "0");

  return `${localYear}-${localMonth}-${localDay} ${localHours}:${localMinutes}:${localSeconds}`;
};

export const convertToUTC = (localTime) => {
  if (!localTime) return "-";

  // Ensure localTime is a Date object
  const localDate = localTime instanceof Date ? localTime : new Date(localTime);

  // Check if the date is valid
  if (isNaN(localDate.getTime())) {
    throw new Error("Invalid date format");
  }

  // Extract UTC components
  const utcYear = localDate.getUTCFullYear();
  const utcMonth = String(localDate.getUTCMonth() + 1).padStart(2, "0");
  const utcDay = String(localDate.getUTCDate()).padStart(2, "0");
  const utcHours = String(localDate.getUTCHours()).padStart(2, "0");
  const utcMinutes = String(localDate.getUTCMinutes()).padStart(2, "0");
  const utcSeconds = String(localDate.getUTCSeconds()).padStart(2, "0");

  // Return UTC formatted date string
  return `${utcYear}-${utcMonth}-${utcDay} ${utcHours}:${utcMinutes}:${utcSeconds}`;
};
