import baseUrl, { sugarUrl } from "../config/baseUrl";

const addTask = async ({ token, payloadParams }) => {
  try {
    const response = await fetch(`${baseUrl}/api/add-tasks`, {
      method: "POST",
      headers: {
        //  "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        ...payloadParams,
        task_category_c: "Immigration", // Ensure task_category_c is included
      }),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    return error;
  }
};

export default addTask;
