import React, { useState, useContext, useEffect } from "react";
import { toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { AiFillCloseCircle } from "react-icons/ai";
import InputLabel from "@mui/material/InputLabel";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputUI from "../material-ui/InputUI";
import ButtonUI from "../material-ui/ButtonUI";
import { AuthContext } from "../../context/Auth/AuthContext";
import addDocumentRequestSettings from "../../api/addDocumentRequestSettings";
import getAllServiceList from "../../api/getAllServiceList";
import addDocumentRequestSettingsImg from "../../images/add-user.png";
import "../../styles/AddDocument.css";
import getAllSubTypeList from "../../api/getAllSubTypeList";
import baseUrl from "../../config/baseUrl";

const addDocumentRequestSettingsFormSchema = yup.object().shape({
  documentName: yup.string().required("Document Name is required field"),
  description: yup.string().required("Description is required field"),
  requestType: yup.string().required("Request Type is required field"),
  // stage: yup.string().nullable().required("Service is required field"),
  ocrStatus: yup.string().required("OCR Status is required field"),
  clientType: yup.string().required("Client Type is required field"),
  clientSubType: yup
    .string()
    .nullable()
    .required("Client Sub Type is required field"),
  status: yup.string().required("Status is required field"),
});

/*** Component for adding the Document Request Settings ***/
const AddDocumentRequestSettings = ({
  typeOptions,
  onAddDocumentRequestSettingsButtonClick,
  setDocumentRequestSettingsAddedToTrue,
}) => {
  const [loading, setLoading] = useState(false);
  const { token, is_admin, user_id } = useContext(AuthContext);
  const [subTypeOptions, setSubTypeOptions] = useState([]);
  // const [services, setServices] = useState([]);
  // const [defaultStage, setDefaultStage] = useState(null);
  // const [openStage, setOpenStage] = useState(false);
  const [openSubType, setOpenSubType] = useState(false);
  // const loadingServices = openStage && services.length === 0;
  const [loadingSubTypes, setLoadingSubTypes] = useState(false);

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(addDocumentRequestSettingsFormSchema),
    // defaultValues: {
    //   stage: defaultStage,
    // },
  });

  const selectedClientType = watch("clientType");

  // const fetchAllServices = async () => {
  //   if (!loadingServices) return;
  //   const url = `${baseUrl}/api/get-document-stages`;
  //   try {
  //     const res = await getAllServiceList({ api: url, token });
  //     const stages = res.document_stages.map((stage) => ({
  //       label: stage.document_stage,
  //       id: stage.document_request_setting_id,
  //     }));
  //     setServices(stages);

  //     if (stages.length > 0) {
  //       setDefaultStage(stages[0]);
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  const fetchClientSubTypes = async (clientType) => {
    setSubTypeOptions([]); // Clear previous options
    setOpenSubType(true); // Open the Autocomplete dropdown
    try {
      const data = await getAllSubTypeList({ token, typeId: clientType });
      if (data.code == 200) {
        const filteredTypeList =
          data?.data !== null
            ? data?.data.map((type) => ({
                label: type.fields_name,
                typeId: type.field_setting_id,
              }))
            : [];
        setSubTypeOptions(filteredTypeList);
        setLoadingSubTypes(false);
      } else {
        setSubTypeOptions([]);
        setLoadingSubTypes(false);
      }
    } catch (err) {
      console.log(err);
      setSubTypeOptions([]);
      setLoadingSubTypes(false);
    } finally {
      setOpenSubType(false); // Close the dropdown after loading
    }
  };

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    ignoreCase: true, // Ignore case sensitivity
    stringify: (option) => option.label,
  });

  const onAddDocumentRequestSettingsFormSubmit = async (
    addDocumentRequestSettingsFormData
  ) => {
    setLoading(true);
    const addDocumentRequestSettingsFormResponse =
      await addDocumentRequestSettings({
        token: token,
        userId: user_id,
        document_name: addDocumentRequestSettingsFormData.documentName,
        document_desc: addDocumentRequestSettingsFormData.description,
        client_type: addDocumentRequestSettingsFormData.clientType,
        service_sub_type_c: addDocumentRequestSettingsFormData.clientSubType,
        request_type: addDocumentRequestSettingsFormData.requestType,
        // document_stage: addDocumentRequestSettingsFormData.stage,
        ocr_status: addDocumentRequestSettingsFormData.ocrStatus,
        status: addDocumentRequestSettingsFormData.status,
        is_admin: is_admin,
      });

    if (addDocumentRequestSettingsFormResponse?.code === 200) {
      onAddDocumentRequestSettingsButtonClick();
      setDocumentRequestSettingsAddedToTrue();
      toast.success("Document Request Settings is added successfully", {
        position: "top-center",
        autoClose: 3000,
        theme: "dark",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.error("Error while adding Document Request Settings", {
        position: "top-center",
        autoClose: 5000,
        theme: "dark",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    setLoading(false);
  };

  // useEffect(() => {
  //   if (loadingServices) {
  //     fetchAllServices();
  //   }
  // }, [loadingServices]);

  useEffect(() => {
    if (selectedClientType) {
      setLoadingSubTypes(true);
      fetchClientSubTypes(selectedClientType);
    }
  }, [selectedClientType]);

  // useEffect(() => {
  //   if (!openStage) {
  //     setServices([]);
  //   }
  // }, [openStage]);

  return (
    <div className="add-document-page">
      <div className="add-document-container">
        <div className="add-document-header">
          <div className="add-document-title-with-img">
            <img
              src={addDocumentRequestSettingsImg}
              height="100px"
              width="120px"
              alt="Add Document Request Template"
            />
            <div className="add-document-title">
              Add Document Request Template
            </div>
          </div>
          <ButtonUI
            formButton={false}
            variant="contained"
            buttonText={<AiFillCloseCircle />}
            style={{
              background: "none",
              padding: "0px",
              minWidth: "auto",
            }}
            onClickFunc={onAddDocumentRequestSettingsButtonClick}
          ></ButtonUI>
        </div>
        <div className="add-document-form">
          <div className="add-document-inputs">
            <div className="add-document-inputs-with-error-message">
              <InputUI
                name="documentName"
                label="Enter Document Request Name"
                type="text"
                variant="outlined"
                cssStyles={{
                  margin: "10px 0px",
                }}
                register={register}
              />
              <span className="error-message">
                {errors["documentName"]?.message}
              </span>
            </div>
            <div className="add-document-inputs-with-error-message">
              <InputUI
                name="description"
                label="Enter Description"
                type="text"
                variant="outlined"
                cssStyles={{ margin: "10px 0px" }}
                register={register}
              />
              <span className="error-message">
                {errors["description"]?.message}
              </span>
            </div>
          </div>
          <div className="add-document-inputs">
            <div className="add-document-inputs-with-error-message select-ui">
              <FormControl fullWidth>
                <InputLabel id="select-label">Request Type</InputLabel>
                <Controller
                  control={control}
                  name="requestType"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      name="requestType"
                      sx={{ margin: "10px 0px" }}
                      labelId="select-label"
                      variant="outlined"
                      id="simple-select"
                      value={value}
                      onChange={onChange}
                      label="Request Type"
                      {...register("requestType")}
                    >
                      <MenuItem value="required">Required</MenuItem>
                      <MenuItem value="recommended">Recommended</MenuItem>
                    </Select>
                  )}
                />
              </FormControl>
              <span className="error-message">
                {errors["requestType"]?.message}
              </span>
            </div>
            {/* <div className="add-document-inputs-with-error-message">
              <FormControl fullWidth>
                <Controller
                  control={control}
                  name="stage"
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      open={openStage}
                      onOpen={() => setOpenStage(true)}
                      onClose={() => setOpenStage(false)}
                      options={services}
                      loading={loadingServices}
                      filterOptions={filterOptions} // Apply custom filter logic
                      isOptionEqualToValue={(option, value) =>
                        option.label === value.label
                      }
                      getOptionLabel={(option) => {
                        // Find all services with the same label
                        const duplicates = services.filter(
                          (item) => item.label === option.label
                        );

                        // Find the index of the current option in the list of duplicates
                        const index = duplicates.indexOf(option);

                        // Return the label with a sequential number for duplicates
                        return duplicates.length > 1
                          ? `${option.label} (${index + 1})`
                          : option.label;
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Services"
                          variant="outlined"
                          sx={{ margin: "10px 0px", paddingTop: "0" }}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {loadingServices ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                      onChange={(e, value) => {
                        onChange(value ? value.label : "");
                      }}
                    />
                  )}
                />
              </FormControl>
              <span className="error-message">{errors["stage"]?.message}</span>
            </div> */}
            <div className="add-document-inputs-with-error-message select-ui">
              <FormControl fullWidth>
                <InputLabel id="select-label">Status</InputLabel>
                <Controller
                  control={control}
                  name="status"
                  defaultValue="active"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      name="status"
                      sx={{ margin: "10px 0px" }}
                      labelId="select-label"
                      variant="outlined"
                      id="simple-select"
                      value={value}
                      onChange={onChange}
                      label="Status"
                      {...register("status")}
                    >
                      <MenuItem value="active">Active</MenuItem>
                      <MenuItem value="inactive">Inactive</MenuItem>
                    </Select>
                  )}
                />
              </FormControl>
              <span className="error-message">{errors["status"]?.message}</span>
            </div>
          </div>

          <div className="add-document-inputs">
            <div className="add-document-inputs-with-error-message ">
              <FormControl fullWidth>
                <Controller
                  control={control}
                  name="clientType"
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      options={typeOptions}
                      filterOptions={filterOptions} // Apply custom filter logic
                      getOptionLabel={(option) => option.label || ""}
                      onChange={(e, value) => {
                        setValue("clientSubType", null);
                        onChange(value ? value.typeId : "");
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Client Type"
                          variant="outlined"
                          sx={{ margin: "10px 0px" }}
                        />
                      )}
                    />
                  )}
                />
              </FormControl>
              <span className="error-message">
                {errors["clientType"]?.message}
              </span>
            </div>
            <div className="add-document-inputs-with-error-message">
              <FormControl fullWidth>
                <Controller
                  control={control}
                  name="clientSubType"
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      open={openSubType}
                      onOpen={() => setOpenSubType(true)}
                      onClose={() => setOpenSubType(false)}
                      options={subTypeOptions || []}
                      loading={loadingSubTypes}
                      filterOptions={filterOptions} // Apply custom filter logic
                      getOptionLabel={(option) => option.label || ""}
                      // If no value is selected, use "Client Sub Type" as the label
                      value={
                        subTypeOptions.find(
                          (option) => option.typeId === value
                        ) || null
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={value ? "Client Sub Type" : "Client Sub Type"} // You can customize based on conditions
                          variant="outlined"
                          sx={{ margin: "10px 0px" }}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {loadingSubTypes ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                      onChange={(e, newValue) => {
                        onChange(newValue ? newValue.typeId : ""); // Handle typeId or empty string
                      }}
                    />
                  )}
                />
              </FormControl>

              <span className="error-message">
                {errors["clientSubType"]?.message}
              </span>
            </div>
          </div>

          <div className="add-document-inputs">
            <div className="add-document-inputs-with-error-message select-ui">
              <FormControl fullWidth>
                <InputLabel id="select-label">OCR Status</InputLabel>
                <Controller
                  control={control}
                  name="ocrStatus"
                  defaultValue="False"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      name="ocrStatus"
                      sx={{ margin: "10px 0px" }}
                      labelId="select-label"
                      variant="outlined"
                      id="simple-select"
                      value={value}
                      onChange={onChange}
                      label="OCR Status"
                      {...register("ocrStatus")}
                    >
                      <MenuItem value="True">True</MenuItem>
                      <MenuItem value="False">False</MenuItem>
                    </Select>
                  )}
                />
              </FormControl>
              <span className="error-message">
                {errors["ocrStatus"]?.message}
              </span>
            </div>
          </div>
          <div className="add-document-button">
            <ButtonUI
              loading={loading}
              variant="contained"
              buttonText="Add Document Request Template"
              style={{
                width: "300px",
                height: "35px",
                backgroundColor: "#007bff",
                boxShadow: "none",
                border: "1px solid #007bff",
                fontWeight: "500",
                borderRadius: "4px",
                letterSpacing: "0.07rem",
                marginTop: "20px",
              }}
              handleSubmit={handleSubmit}
              onFormSubmit={onAddDocumentRequestSettingsFormSubmit}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddDocumentRequestSettings;
