import {
	Card,
	CardContent,
	CardHeader,
	CardTitle,
} from "../shadcn-components/ui/card";
import Navbar from "../components/common/Navbar";
import React, { useContext, useEffect, useState } from "react";
import { VerificationTable } from "../components/client-verification/VerificationTable";
import { AuthContext } from "../context/Auth/AuthContext";
import { useQuery } from "@tanstack/react-query";
import { fetchClientList } from "../api/client-verification/client-verfication-queries";

const ClientVerification = () => {
	const user = useContext(AuthContext);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [previousTotalClients, setPreviousTotalClients] = useState(0);
	const offset = (currentPage - 1) * pageSize;

	const { data, isLoading } = useQuery({
		queryKey: ["fetchClient", user.id, currentPage, pageSize],
		queryFn: () => fetchClientList(user?.token, pageSize, offset),
		keepPreviousData: true,
	});

	const totalClients = data?.data?.[0]?.allclientsCount ?? 0;
	const totalPages =
		totalClients > 0
			? Math.ceil(totalClients / pageSize)
			: Math.ceil(previousTotalClients / pageSize);

	useEffect(() => {
		if (totalClients > 0) {
			setPreviousTotalClients(totalClients);
		}
	}, [totalClients]);

	return (
		<div className="document-container">
			<div className="navbar">
				<Navbar />
			</div>
			<div className="w-11/12 mt-5 mx-auto">
				<Card>
					<CardHeader>
						<CardTitle className="text-2xl font-semibold bg-gradient-to-r from-primary to-primary/80 bg-clip-text text-transparent">
							Client Verification
						</CardTitle>
					</CardHeader>
					<CardContent>
						<VerificationTable
							{...data}
							isLoading={isLoading}
							currentPage={currentPage}
							setCurrentPage={setCurrentPage}
							totalPages={
								totalPages || Math.ceil(previousTotalClients / pageSize)
							}
							pageSize={pageSize}
						/>
					</CardContent>
				</Card>
			</div>
		</div>
	);
};

export default ClientVerification;
