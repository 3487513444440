import baseUrl, { sugarUrl } from "../config/baseUrl";

const sendSms = async ({
  token,
  id,
  message,
  messageTemplate,
  toNumber,
  channel,
  media,
  checkCondition,
  templateId,
}) => {
  try {
    const response = await fetch(`${baseUrl}/api/send-dms-message`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id,
        message,
        messageTemplate,
        toNumber,
        channel,
        media,
        checkCondition,
        templateId,
      }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching template description:", error);
    return error;
  }
};

export default sendSms;
