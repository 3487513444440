import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../context/Auth/AuthContext";
import baseUrl from "../../config/baseUrl";
import DeleteContractsPopup from "./DeleteContractsPopup";
import documentRequestSettingsData from "../../api/documentRequestSettings";
import AddContracts from "./AddContracts";
import getAllTypeList from "../../api/getAllFieldTypeList";
import "../../styles/Table.css";
import BulkDeletePopup from "./BulkDeletePopup";
import clientsData from "../../api/clients";
import TableSkeleton from "../common/TableSkeleton";
import PaginationSection from "../common/PaginationSection";
import DataToolbar from "./DataToolbar";
import { useDebounce } from "use-debounce";

import {
  CaretSortIcon,
  ChevronDownIcon,
  MixerHorizontalIcon,
  StarIcon,
  StarFilledIcon,
} from "@radix-ui/react-icons";

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../shadcn-components/ui/select";

import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";

import { Button } from "../../shadcn-components/ui/button";
import { Checkbox } from "../../shadcn-components/ui/checkbox";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../shadcn-components/ui/dropdown-menu";
import { Input } from "../../shadcn-components/ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../shadcn-components/ui/table";
import { fileIcon } from "../common/FileIcons";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../shadcn-components/ui/tooltip";
import { formatDate } from "../../utils/formatDate";

const ContractsTable = ({
  addContractTab,
  showAddContractsForm,
  onAddContractsButtonClick,
  contractsType,
}) => {
  const [loading, setLoading] = useState(false);

  const [clientNames, setClientNames] = useState([]);
  const [contractsAdded, setContractsAdded] = useState(0);

  const { token, user_id, is_admin } = useContext(AuthContext);
  const [documentRequestSettingsResponse, setDocumentRequestSettingsResponse] =
    useState([]);

  const [bulkDeleteRequestSetting, setBulkDeleteRequestSetting] =
    useState(false);
  const [bulkDeleteRequestSettingIds, setBulkDeleteRequestSettingIds] =
    useState();

  const [sorting, setSorting] = useState([]);
  const [columnFilters, setColumnFilters] = useState([]);
  const [columnVisibility, setColumnVisibility] = useState({});
  const [rowSelection, setRowSelection] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [totalPages, setTotalPages] = useState(1);
  const [globalFilter, setGlobalFilter] = useState("");
  const [debouncedGlobalFilter] = useDebounce(globalFilter, 1000);

  const [selectAllChecked, setSelectAllChecked] = useState();

  const [showFilterButton, setShowFilterButton] = useState(false);

  const DocumentRequestSettingsResponseFunc = async () => {
    setLoading(true);
    const offset = currentPage - 1;
    const filterParams = {};

    columnFilters.forEach((filterItem) => {
      filterParams[filterItem.id] = filterItem.value;
    });

    const sortParam = sorting
      .map((sortItem) => {
        return `${sortItem.id}=${sortItem.desc ? "desc" : "asc"}`;
      })
      .join(",");

    const params = {
      isAdmin: is_admin,
      userId: user_id,
      requestType: contractsType,
      limit: pageSize,
      offset: offset,
      ...filterParams,
      global_search: debouncedGlobalFilter,
      sort: sortParam,
    };
    const queryString = new URLSearchParams(params).toString();
    const url = `${baseUrl}/api/get-contract-request-data?${queryString}`;
    const response = await documentRequestSettingsData({ token, api: url });
    if (response.code == 200) {
      setTotalPages(
        Math.ceil(response?.data[0].allContractRequestCount / pageSize)
      );
      setDocumentRequestSettingsResponse(response?.data[0].contractRequests);
    } else {
      setTotalPages(0);
      setDocumentRequestSettingsResponse([]);
    }
    setLoading(false);
  };

  useEffect(() => {
    DocumentRequestSettingsResponseFunc();
  }, [
    contractsAdded,
    bulkDeleteRequestSetting,
    debouncedGlobalFilter,
    currentPage,
    columnFilters,
    pageSize,
    sorting,
  ]);

  const setContractsAddedToTrue = () => {
    setContractsAdded((count) => count + 1);
  };

  const handleBulkAction = async (actionType) => {
    const selectedRowsData = table
      .getFilteredSelectedRowModel()
      .rows.map((row) => row.original);
    const ids = selectedRowsData
      .map((row) => row.document_request_setting_id)
      .join(",");
    if (actionType === "delete") {
      setBulkDeleteRequestSettingIds(ids);
      setBulkDeleteRequestSetting(true);
    }
  };

  let columns;
  if (contractsType == "RequestedContracts") {
    columns = [
      {
        id: "select",
        header: ({ table }) => (
          <div className="flex items-center">
            <Checkbox
              checked={
                table.getIsAllPageRowsSelected() ||
                (table.getIsSomePageRowsSelected() && "indeterminate")
              }
              onCheckedChange={(value) =>
                table.toggleAllPageRowsSelected(!!value)
              }
              aria-label="Select all"
            />
            {(table.getIsSomePageRowsSelected() ||
              table.getIsAllPageRowsSelected()) && (
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button
                    variant="outline"
                    className="p-0 !border-none !shadow-none bg-transparent"
                  >
                    <ChevronDownIcon className="" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end">
                  <DropdownMenuItem onSelect={() => handleBulkAction("delete")}>
                    Delete
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            )}
          </div>
        ),
        cell: ({ row }) => (
          <Checkbox
            checked={row.getIsSelected()}
            onCheckedChange={(value) => row.toggleSelected(!!value)}
            aria-label="Select row"
          />
        ),
        enableSorting: false,
        enableHiding: false,
      },
      {
        accessorKey: "name",
        displayName: "Client Name",
        header: ({ column }) => (
          <Button
            variant="ghost"
            className="p-0"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            Client Name
            <CaretSortIcon className="ml-2 h-4 w-4" />
          </Button>
        ),
        cell: ({ row }) => (
          <div className="text-left">{row.getValue("name")}</div>
        ),
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id));
        },
      },
      {
        accessorKey: "contract_name",
        displayName: "Contract Name",
        header: ({ column }) => (
          <Button
            variant="ghost"
            className="p-0"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            Contract Name
            <CaretSortIcon className="ml-2 h-4 w-4" />
          </Button>
        ),
        cell: ({ row }) => (
          <div
            className="text-left underline cursor-pointer text-blue-500"
            onClick={() => {
              addContractTab(row.original);
            }}
          >
            {row.getValue("contract_name")}
          </div>
        ),
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id));
        },
      },
      {
        accessorKey: "client_type",
        displayName: "Client Type",
        header: ({ column }) => (
          <Button
            variant="ghost"
            className="p-0"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            Client Type
            <CaretSortIcon className="ml-2 h-4 w-4" />
          </Button>
        ),
        cell: ({ row }) => (
          <div className="capitalize">{row.getValue("client_type")}</div>
        ),
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id));
        },
      },
      {
        accessorKey: "document_type",
        displayName: "Document Type",
        header: ({ column }) => (
          <Button
            variant="ghost"
            className="p-0"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            Document Type
            <CaretSortIcon className="ml-2 h-4 w-4" />
          </Button>
        ),
        cell: ({ row }) => (
          <div className="capitalize">{row.getValue("document_type")}</div>
        ),
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id));
        },
      },
      {
        accessorKey: "contract_status",
        displayName: "Status",
        header: ({ column }) => (
          <Button
            variant="ghost"
            className="p-0"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            Status
            <CaretSortIcon className="ml-2 h-4 w-4" />
          </Button>
        ),
        cell: ({ row }) => (
          <div className="capitalize">{row.getValue("contract_status")}</div>
        ),
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id));
        },
      },
      {
        accessorKey: "created_at",
        displayName: "Created At",
        header: ({ column }) => (
          <Button
            variant="ghost"
            className="p-0"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            Sent Date
            <CaretSortIcon className="ml-2 h-4 w-4" />
          </Button>
        ),
        cell: ({ row }) => {
          const sentDate = row.getValue("created_at");
          return (
            <div className="capitalize text-wrap">{formatDate(sentDate)}</div>
          );
        },
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id));
        },
      },
      {
        id: "actions",
        header: ({ column }) => (
          <Button variant="ghost" className="p-0 !cursor-default">
            Actions
          </Button>
        ),
        cell: ({ row }) => (
          <div className="flex gap-px">
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <DeleteContractsPopup
                    setContractsAddedToTrue={setContractsAddedToTrue}
                    id={row.original.contract_request_id}
                  />
                </TooltipTrigger>
                <TooltipContent className="bg-gray-600">
                  <p>delete contract</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        ),
        enableHiding: false,
      },
    ];
  } else {
    columns = [
      {
        id: "select",
        header: ({ table }) => (
          <div className="flex items-center">
            <Checkbox
              checked={
                table.getIsAllPageRowsSelected() ||
                (table.getIsSomePageRowsSelected() && "indeterminate")
              }
              onCheckedChange={(value) =>
                table.toggleAllPageRowsSelected(!!value)
              }
              aria-label="Select all"
            />
            {(table.getIsSomePageRowsSelected() ||
              table.getIsAllPageRowsSelected()) && (
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button
                    variant="outline"
                    className="p-0 !border-none !shadow-none bg-transparent"
                  >
                    <ChevronDownIcon className="" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end">
                  <DropdownMenuItem onSelect={() => handleBulkAction("delete")}>
                    Delete
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            )}
          </div>
        ),
        cell: ({ row }) => (
          <Checkbox
            checked={row.getIsSelected()}
            onCheckedChange={(value) => row.toggleSelected(!!value)}
            aria-label="Select row"
          />
        ),
        enableSorting: false,
        enableHiding: false,
      },
      {
        accessorKey: "name",
        displayName: "Client Name",
        header: ({ column }) => (
          <Button
            variant="ghost"
            className="p-0"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            Client Name
            <CaretSortIcon className="ml-2 h-4 w-4" />
          </Button>
        ),
        cell: ({ row }) => (
          <div className="text-left">{row.getValue("name")}</div>
        ),
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id));
        },
      },
      {
        accessorKey: "contract_name",
        displayName: "Contract Name",
        header: ({ column }) => (
          <Button
            variant="ghost"
            className="p-0"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            Contract Name
            <CaretSortIcon className="ml-2 h-4 w-4" />
          </Button>
        ),
        cell: ({ row }) => (
          <div
            className="text-left underline cursor-pointer text-blue-500"
            onClick={() => {
              addContractTab(row.original);
            }}
          >
            {row.getValue("contract_name")}
          </div>
        ),
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id));
        },
      },
      {
        accessorKey: "client_type",
        displayName: "Client Type",
        header: ({ column }) => (
          <Button
            variant="ghost"
            className="p-0"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            Client Type
            <CaretSortIcon className="ml-2 h-4 w-4" />
          </Button>
        ),
        cell: ({ row }) => (
          <div className="capitalize">{row.getValue("client_type")}</div>
        ),
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id));
        },
      },
      {
        accessorKey: "document_type",
        displayName: "Document Type",
        header: ({ column }) => (
          <Button
            variant="ghost"
            className="p-0"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            Document Type
            <CaretSortIcon className="ml-2 h-4 w-4" />
          </Button>
        ),
        cell: ({ row }) => (
          <div className="capitalize">{row.getValue("document_type")}</div>
        ),
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id));
        },
      },
      {
        accessorKey: "contract_status",
        displayName: "Status",
        header: ({ column }) => (
          <Button
            variant="ghost"
            className="p-0"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            Status
            <CaretSortIcon className="ml-2 h-4 w-4" />
          </Button>
        ),
        cell: ({ row }) => (
          <div className="capitalize">{row.getValue("contract_status")}</div>
        ),
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id));
        },
      },
      {
        accessorKey: "created_at",
        displayName: "Created At",
        header: ({ column }) => (
          <Button
            variant="ghost"
            className="p-0"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            Sent Date
            <CaretSortIcon className="ml-2 h-4 w-4" />
          </Button>
        ),
        cell: ({ row }) => (
          <div className="capitalize">{row.getValue("created_at")}</div>
        ),
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id));
        },
      },
      {
        accessorKey: "contracts_revision_signed_date",
        displayName: "Created At",
        header: ({ column }) => (
          <Button
            variant="ghost"
            className="p-0"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            Signed On
            <CaretSortIcon className="ml-2 h-4 w-4" />
          </Button>
        ),
        cell: ({ row }) => (
          <div className="capitalize">
            {row.getValue("contracts_revision_signed_date")}
          </div>
        ),
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id));
        },
      },
      {
        id: "actions",
        header: ({ column }) => (
          <Button variant="ghost" className="p-0 !cursor-default">
            Actions
          </Button>
        ),
        cell: ({ row }) => (
          <div className="flex gap-px">
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <DeleteContractsPopup
                    setContractsAddedToTrue={setContractsAddedToTrue}
                    id={row.original.contract_request_id}
                  />
                </TooltipTrigger>
                <TooltipContent className="bg-gray-600">
                  <p>delete contract</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        ),
        enableHiding: false,
      },
    ];
  }

  const table = useReactTable({
    data: documentRequestSettingsResponse,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    initialState: {
      pageSize: 20,
    },
    manualPagination: true,
    manualSorting: true,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
      pageSize,
    },

    globalFilterFn: (rows, columnFilters) => {
      const [globalFilter] = columnFilters.filter(
        (filter) => filter.id === "global"
      );
      if (!globalFilter || !globalFilter.value) return rows;

      return rows.filter((row) => {
        const name = row.original.name.toLowerCase(); // Assuming 'name' is the column key
        return name.includes(globalFilter.value.toLowerCase());
      });
    },
    onGlobalFilterChange: (filterValue) => {
      setGlobalFilter(filterValue); // Update the global filter state
    },
  });

  const getClientList = async () => {
    const url = `${baseUrl}/api/get-client-name-for-contract?isAdmin=${is_admin}&userId=${user_id}`;
    const res = await clientsData({ token, api: url });
    setClientNames(res?.data);
  };

  useEffect(() => {
    getClientList();
  }, [contractsAdded]);

  return (
    <>
      {bulkDeleteRequestSetting ? (
        <BulkDeletePopup
          RequestSettingbulkid={bulkDeleteRequestSettingIds}
          bulkDeleteRequestSetting={bulkDeleteRequestSetting}
          setBulkDeleteRequestSetting={setBulkDeleteRequestSetting}
          setRowSelection={setRowSelection}
        />
      ) : null}
      <div className="user-table">
        {showAddContractsForm ? (
          <AddContracts
            onAddContractsButtonClick={onAddContractsButtonClick}
            setContractsAddedToTrue={setContractsAddedToTrue}
            clientNames={clientNames}
          />
        ) : (
          <div className="document__table mt-3">
            <div className="w-full bg-white px-2">
              <div className="flex justify-between items-center py-4 flex-wrap gap-4">
                <Input
                  placeholder="Search..."
                  value={globalFilter}
                  onChange={(event) =>
                    table.setGlobalFilter(event.target.value)
                  }
                  className="max-w-sm"
                />
                <div className="flex gap-2">
                  <Button
                    variant="outline"
                    className=""
                    onClick={() => setShowFilterButton(!showFilterButton)}
                  >
                    <MixerHorizontalIcon className="mr-1" />
                    Filter
                  </Button>

                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button variant="outline" className="ml-auto">
                        Columns <ChevronDownIcon className="ml-2 h-4 w-4" />
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="end">
                      {table
                        .getAllColumns()
                        .filter((column) => column.getCanHide())
                        .map((column) => {
                          return (
                            <DropdownMenuCheckboxItem
                              key={column.id}
                              className="capitalize"
                              checked={column.getIsVisible()}
                              onCheckedChange={(value) =>
                                column.toggleVisibility(!!value)
                              }
                            >
                              {column.columnDef.displayName}
                            </DropdownMenuCheckboxItem>
                          );
                        })}
                    </DropdownMenuContent>
                  </DropdownMenu>
                </div>
              </div>
              {showFilterButton && <DataToolbar table={table} />}
              <div className="rounded-md border">
                {loading ? (
                  <TableSkeleton />
                ) : (
                  <Table>
                    <TableHeader className="pb-4 sticky top-0 bg-white z-[1]">
                      {table.getHeaderGroups().map((headerGroup) => (
                        <TableRow key={headerGroup.id}>
                          {headerGroup.headers.map((header) => {
                            return (
                              <TableHead key={header.id}>
                                {header.isPlaceholder
                                  ? null
                                  : flexRender(
                                      header.column.columnDef.header,
                                      header.getContext()
                                    )}
                              </TableHead>
                            );
                          })}
                        </TableRow>
                      ))}
                    </TableHeader>
                    <TableBody>
                      {table.getRowModel().rows?.length ? (
                        table.getRowModel().rows.map((row) => (
                          <TableRow
                            key={row.id}
                            data-state={row.getIsSelected() && "selected"}
                          >
                            {row.getVisibleCells().map((cell) => (
                              <TableCell key={cell.id}>
                                {flexRender(
                                  cell.column.columnDef.cell,
                                  cell.getContext()
                                )}
                              </TableCell>
                            ))}
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell
                            colSpan={columns.length}
                            className="h-24 text-center"
                          >
                            {loading ? <TableSkeleton /> : "no records found"}
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                )}
              </div>
              <div className="flex items-center justify-between space-x-2 py-4 flex-wrap gap-4">
                <div className="flex items-center gap-5">
                  <div className="flex-1 text-sm text-muted-foreground">
                    {table.getFilteredSelectedRowModel().rows.length} of{" "}
                    {table.getFilteredRowModel().rows.length} row(s) selected.
                  </div>
                  <div className="flex items-center space-x-2">
                    <p className="text-sm font-medium">Rows per page</p>
                    <Select
                      value={`${table.getState().pageSize}`}
                      onValueChange={(value) => {
                        setPageSize(Number(value));
                        setCurrentPage(1);
                      }}
                    >
                      <SelectTrigger className="w-auto">
                        <SelectValue placeholder={table.getState().pageSize} />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectGroup>
                          {[10, 20, 30, 40, 50].map((pageSize) => (
                            <SelectItem key={pageSize} value={`${pageSize}`}>
                              {pageSize}
                            </SelectItem>
                          ))}
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                  </div>
                </div>

                <div className="space-x-2">
                  <PaginationSection
                    setCurrentPage={setCurrentPage}
                    totalPages={totalPages}
                    currentPage={currentPage}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ContractsTable;
