import React, { useState, useEffect, useContext, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import Navbar from "../components/common/Navbar";
import PdfViewer from "../components/pdf-viewer/PdfViewer";
import DocumentRequestSettingsTable from "../components/document-request-setting/DocumentRequestSettingsTable";
import { AuthContext } from "../context/Auth/AuthContext";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { IoIosArrowDropleftCircle } from "react-icons/io";
import { IoIosArrowDroprightCircle } from "react-icons/io";

import {
  CaretSortIcon,
  ChevronDownIcon,
  StarIcon,
  StarFilledIcon,
} from "@radix-ui/react-icons";

import "../styles/Users.css";
import { useRoleContext } from "../context/RolesContext";

/*** Main component for listing the document request settings & for showing the 'Add Document Request Template' button ***/
const DocumentRequestSettings = () => {
  const [
    showAddDocumentRequestSettingsForm,
    setShowAddDocumentRequestSettingsForm,
  ] = useState(false);

  const [typeOptions, setTypeOptions] = useState([]);
  const { token, user_id, is_admin } = useContext(AuthContext);

  // Role based permission
  // Function to check if the user has a specific permission.
  // Admin users ("is_admin" === "1") automatically have access to all permissions.
  const { permissions } = useRoleContext();
  const hasPermission = (permission) => {
    if (is_admin === "1") {
      return true;
    }

    return permissions.includes(permission);
  };

  const canAddDocumentRequestTemplate = hasPermission(
    "create_document_requests"
  );
  const canEditDocumentRequestTemplate = hasPermission(
    "edit_document_requests"
  );
  const canDeleteDocumentRequestTemplate = hasPermission(
    "delete_document_requests"
  );

  //To hide the action column if there is not a single permission
  const shouldShowActionColumn =
    canEditDocumentRequestTemplate || canDeleteDocumentRequestTemplate;

  const [tab, setTab] = useState([
    {
      id: uuidv4(),
      documentID: null,
      title: "All Document Request",
      content: (
        addDocumentTab,
        showAddDocumentRequestSettingsForm,
        onAddDocumentRequestSettingsButtonClick,
        typeOptions,
        setTypeOptions
      ) => (
        <DocumentRequestSettingsTable
          addDocumentTab={addDocumentTab}
          showAddDocumentRequestSettingsForm={
            showAddDocumentRequestSettingsForm
          }
          onAddDocumentRequestSettingsButtonClick={
            onAddDocumentRequestSettingsButtonClick
          }
          typeOptions={typeOptions}
          setTypeOptions={setTypeOptions}
          canEditDocumentRequestTemplate={canEditDocumentRequestTemplate}
          canDeleteDocumentRequestTemplate={canDeleteDocumentRequestTemplate}
          shouldShowActionColumn={shouldShowActionColumn}
        />
      ),
      canClose: false,
    },
  ]);
  const [tabValue, setTabValue] = useState(tab[0].id);
  const firstTabId = tab[0].id;

  const scrollRef = useRef(null);

  const truncate = (string, n) => {
    return string?.length > n ? string.substr(0, n - 1) + "..." : string;
  };

  const scrollLeft = (scrollOffset) => {
    scrollRef.current.scrollLeft += scrollOffset;
  };

  const scrollRight = (scrollOffset) => {
    scrollRef.current.scrollLeft -= scrollOffset;
  };

  const changeActiveTab = () => {
    setTabValue(tab[0].id);
  };

  const addDocumentTab = (document) => {
    const isDocumentAlreadySelected = tab.find(
      (tab) => tab.documentID === document.document_request_setting_id
    );

    if (isDocumentAlreadySelected !== undefined) {
      return;
    }

    const findIndex = tab.splice(1, 0, {
      id: uuidv4(),
      documentID: document.document_request_setting_id,
      title: truncate(document.document_name, 9),
      content: () => {
        return (
          <PdfViewer
            documentType={document.sample_document_link.split(".").pop()}
            clientID={document.document_name}
            documentID={document.document_request_setting_id}
            documentTitle={document.document_name}
            changeActiveTab={changeActiveTab}
            document_table="document_request_settings"
          />
        );
      },
      canClose: true,
    });

    const filteredTab = tab.filter((value, index) => index !== findIndex);

    setTab(filteredTab);
  };

  const handleChange = (index) => {
    setTabValue(index);
  };

  const closeTab = (e, index) => {
    e.stopPropagation();
    const newTab = tab.filter((tab) => tab.id !== index);
    const allDocumentTabId = newTab[0].id;
    setTab(newTab);
    setTabValue(allDocumentTabId);
  };

  const onAddDocumentRequestSettingsButtonClick = () => {
    setShowAddDocumentRequestSettingsForm(!showAddDocumentRequestSettingsForm);
  };

  return (
    <div className="user-container">
      <div className="navbar">
        <Navbar />
      </div>
      <div className="user-table">
        <div className="user-content">
          {canAddDocumentRequestTemplate && (
            <button
              className="add-document"
              onClick={onAddDocumentRequestSettingsButtonClick}
              style={{
                visibility: firstTabId === tabValue ? "visible" : "hidden",
              }}
            >
              Add Document Request Template
            </button>
          )}
          {!showAddDocumentRequestSettingsForm ? (
            <div
              className={
                tab.length > 7 ? "scrollable-tabs" : "not-scrollable-tabs"
              }
            >
              {tab.length > 7 ? (
                <div className="scroll-icons" onClick={() => scrollRight(150)}>
                  <IoIosArrowDropleftCircle />
                </div>
              ) : null}
              <div className="tabs-header" ref={scrollRef}>
                {tab.map((tab, index) => (
                  <div
                    key={tab.id}
                    className={
                      tabValue === tab.id
                        ? "tab-title active-tab-title"
                        : "tab-title"
                    }
                    onClick={() => handleChange(tab.id)}
                  >
                    <span>{tab.title}</span>
                    {tab.canClose ? (
                      <button
                        className="tab-close-icon"
                        onClick={(e) => closeTab(e, tab.id)}
                      >
                        <AiOutlineCloseCircle />
                      </button>
                    ) : null}
                  </div>
                ))}
              </div>
              {tab.length > 7 ? (
                <div className="scroll-icons" onClick={() => scrollLeft(150)}>
                  <IoIosArrowDroprightCircle />
                </div>
              ) : null}
            </div>
          ) : null}
          <div className="tab-content">
            {tab.map((tab, index) => {
              return tabValue === tab.id ? (
                <div key={index}>
                  {tab.title === "All Document Request"
                    ? tab.content(
                        addDocumentTab,
                        showAddDocumentRequestSettingsForm,
                        onAddDocumentRequestSettingsButtonClick,
                        typeOptions,
                        setTypeOptions
                      )
                    : tab.content()}
                </div>
              ) : null;
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocumentRequestSettings;
