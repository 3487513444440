/**
 * ZipViewer Component
 *
 * Displays and allows downloading files from a ZIP archive.
 *
 * Props:
 * - zipContent: ZIP file content (Blob | ArrayBuffer).
 * - zipSrc: URL to download the entire ZIP file.
 *
 * Features:
 * - View and download individual files or the full ZIP.
 * - Handles loading and errors.
 */

import React, { useEffect, useState } from "react";
import JSZip from "jszip";
import { DownloadIcon } from "@radix-ui/react-icons";

const ZipViewer = ({ zipContent, zipSrc }) => {
  const [fileContents, setFileContents] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const processZipFile = async () => {
      setLoading(true);
      try {
        const zip = new JSZip();
        const zipData = await zip.loadAsync(zipContent);

        // Extract file contents
        const contents = [];
        for (const fileName of Object.keys(zipData.files)) {
          const file = zipData.files[fileName];
          if (!file.dir) {
            const content = await file.async("string");
            contents.push({ name: fileName, content, file });
          } else {
            contents.push({ name: fileName, content: "(Folder)", file });
          }
        }

        setFileContents(contents);
      } catch (error) {
        console.error("Error processing ZIP file:", error);
      } finally {
        setLoading(false);
      }
    };

    processZipFile();
  }, [zipContent]);

  // Function to create a downloadable link for the file
  const createDownloadLink = (file) => {
    return file.async("blob").then((blob) => URL.createObjectURL(blob));
  };

  const handleSingleDownloadClick = async (file) => {
    const downloadUrl = await createDownloadLink(file.file);
    const a = document.createElement("a");
    a.href = downloadUrl;
    const fileName = file.name.split("/").splice(-1);
    a.download = fileName;

    a.click();
  };

  return (
    <div className="p-4">
      {loading ? (
        <p className="text-center text-xl text-gray-500">Loading ZIP file...</p>
      ) : fileContents.length > 0 ? (
        <div className="space-y-4">
          <h3 className="text-2xl font-semibold text-gray-700">
            Files in ZIP:
          </h3>
          <a
            href={zipSrc}
            download
            className="inline-block px-4 py-2 bg-[#44a0cf] hover:bg-[#3792ed] cursor-pointer  text-white rounded-md "
          >
            Download Zip
          </a>
          <ul className="space-y-2">
            {fileContents.map((file, index) => (
              <li
                key={index}
                className="flex justify-between items-center p-2 bg-gray-100 rounded-lg shadow-sm hover:bg-gray-200"
              >
                <span className="font-medium">{file.name}</span>
                <div className="flex gap-2 items-center justify-center">
                  <span className="text-sm text-gray-500">
                    {file.content === "(Folder)" ? "Folder" : "File"}
                  </span>
                  {file.content !== "(Folder)" && (
                    <button
                      onClick={() => handleSingleDownloadClick(file)}
                      className="text-blue-500 hover:text-blue-700"
                    >
                      <DownloadIcon className="size-4" />
                    </button>
                  )}
                </div>
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <p className="text-center text-gray-500">
          No content or loading, please wait...
        </p>
      )}
    </div>
  );
};

export default ZipViewer;
