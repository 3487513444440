import baseUrl, { sugarUrl } from "../config/baseUrl";

const getOptinInfo = async ({ token, id }) => {
  try {
    const response = await fetch(`${baseUrl}/api/check-user-optin?id=${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      // body: JSON.stringify({
      //   id,
      // }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching template description:", error);
    return error;
  }
};

export default getOptinInfo;
