import baseUrl from "../../config/baseUrl";

export const fetchClientList = async (
	token,
	limit = 10,
	offset = 0,
	searchQuery = "",
	sort = ""
) => {
	try {
		const res = await fetch(
			`${baseUrl}/api/get-all-client-data?isAdmin=1&userId=1&limit=${limit}&offset=${offset}&global_search=${searchQuery}&sort=${sort}`,
			{
				headers: {
					Authorization: `Bearer ${token}`,
					"Content-Type": "application/json",
				},
			}
		);
		const data = await res.json();
		return data;
	} catch (error) {
		console.error(error);
	}
};
