/**
 * RolesContext - Manages user roles and permissions.
 *
 * RolesProvider:
 * - Fetches and stores user roles and permissions.
 * - Syncs with localStorage and updates on role change.
 *
 * useRoleContext:
 * - Custom hook to access role and permission data.
 *
 * Props:
 * - children: React components wrapped by the provider.
 *
 * Usage:
 * <RolesProvider>
 *    <YourComponent />
 * </RolesProvider>
 */

import { createContext, useContext, useState, useEffect } from "react";
import roleDetails from "../api/user";
import baseUrl from "../config/baseUrl";
import { AuthContext } from "../context/Auth/AuthContext";

const RolesContext = createContext();

function RolesProvider({ children }) {
  const { is_admin } = useContext(AuthContext);
  const [roles, setRoles] = useState(() => {
    const savedRole = localStorage.getItem("role_id");
    return savedRole ? savedRole : null;
  });
  const [permissions, setPermissions] = useState([]);
  const { token } = useContext(AuthContext);

  const getPermissions = async () => {
    if (is_admin === "0" && roles !== "null") {
      if (!roles) {
        console.error("No role assigned");
        return;
      }
      try {
        const url = `${baseUrl}/api/get-role-details?roleId=${roles}`;
        const response = await roleDetails({ token, api: url });

        if (response.code !== 200) {
          throw new Error(response.message);
        }

        const permission = response.data
          .flatMap((data) => data.permission_name.split(",") || [])
          .map((perm) => perm.trim());

        // console.log(...per);

        setPermissions(permission || []);
      } catch (error) {
        console.error("Error fetching permissions:", error);
      }
    }
  };

  // Trigger permission fetching when roles change
  useEffect(() => {
    if (roles !== "null") {
      getPermissions();
    }
  }, [roles]);

  return (
    <RolesContext.Provider
      value={{ roles, setRoles, getPermissions, permissions }}
    >
      {children}
    </RolesContext.Provider>
  );
}

const useRoleContext = function () {
  const context = useContext(RolesContext);
  if (context === undefined) {
    throw new Error("Context used outside of the provider");
  }
  return context;
};

export { RolesProvider, useRoleContext };
