import React, { useState, useEffect, useContext } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import PrivateRoute from "./components/Routes/PrivateRoute";
import Category from "./pages/Category";
import Dashboard from "./pages/Dashboard";
import Documents from "./pages/Documents";
import Login from "./pages/Login";
import Users from "./pages/Users";
import Roles from "./pages/Roles";
import Recents from "./pages/Recents";
import Starred from "./pages/Starred";
import { AuthContext } from "../src/context/Auth/AuthContext";
import TrashDocuments from "./pages/TrashDocuments";
import TrashUsers from "./pages/TrashUsers";
import TrashCategory from "./pages/TrashCategory";
import AllCaseList from "./pages/AllCaseList";
import EmailCaseList from "./pages/EmailCaseList";
import FaxCaseList from "./pages/FaxCaseList";
import ScanCaseList from "./pages/ScanCaseList";
import Callback from "./pages/Callback";
import AssignedCaseList from "./pages/AssignedCaseList";
import "./styles/App.css";
import UnassignedCaseList from "./pages/UnassignedCaseList";
import Reports from "./pages/Reports";
import ProcessedCaseList from "./pages/ProcessedCaseList";
import MyAssignedCaseList from "./pages/MyAssignedCaseList";
import ClientUnknownCaseList from "./pages/ClientUnknownCaseList";
import ServiceCaseList from "./pages/ServiceCaseList";
import Clients from "./pages/Clients";
import FieldSettings from "./pages/FieldSettings";
import DocumentRequestSettings from "./pages/DocumentRequestSettings";
import ContractTemplates from "./pages/ContractTemplates";
import Contracts from "./pages/Contracts";
import Requests from "./pages/Requests";
import RequestedContracts from "./pages/RequestedContracts";
import SignedContracts from "./pages/SignedContracts";

import { ShortcutProvider } from "./context/Shortcuts/ShortcutContext";
import ClientVerification from "./pages/ClientVerification";
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { useRoleContext } from "./context/RolesContext";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import LoginDev from "./pages/LoginDev";
import NotFoundPage from "./pages/NotFoundPage";

// Create a QueryClient instance
const queryClient = new QueryClient();

function App() {
  const { is_admin, token } = useContext(AuthContext);
  // console.log(is_admin);
  const { permissions } = useRoleContext();

  // Function to check if the user has a specific permission.
  // Admin users ("is_admin" === "1") automatically have access to all permissions.

  const hasPermissions = (permission) => {
    if (is_admin === "1") {
      return true;
    }
    return permissions?.includes(permission);
  };

  // console.log(permissions);
  return (
    <QueryClientProvider client={queryClient}>
      <div className="app">
        <ToastContainer />
        <Router>
          <Routes>
            <Route path="/" element={<Navigate to="/login" />} />
            {/* SSO Login */}
            <Route exact path="/login" element={<Login />} />
            {/* Email/Pass Login */}
            {/* <Route exact path="/login" element={<LoginDev />} /> */}
            <Route exact path="/callback" element={<Callback />} />
            <Route path="*" element={<NotFoundPage />} />

            {/* Dashboard - accessible to all authenticated users */}
            <Route
              path="/dashboard"
              element={
                <PrivateRoute>
                  <ShortcutProvider>
                    <Dashboard />
                    {/* <DashboardProto /> */}
                  </ShortcutProvider>
                </PrivateRoute>
              }
            />

            {/* Admin-only route for reports */}
            {is_admin === "1" ? (
              <Route
                path="/reports"
                element={
                  <PrivateRoute>
                    <Reports />
                  </PrivateRoute>
                }
              />
            ) : null}
            {is_admin === "0" ? (
              <>
                {/* <Route
                  path="/email-case-list"
                  element={
                    <PrivateRoute>
                      <EmailCaseList />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/fax-case-list"
                  element={
                    <PrivateRoute>
                      <FaxCaseList />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/scan-case-list"
                  element={
                    <PrivateRoute>
                      <ScanCaseList />
                    </PrivateRoute>
                  }
                /> */}
              </>
            ) : (
              <>
                <Route
                  path="/documents"
                  element={
                    <PrivateRoute>
                      <Documents />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/recent-documents"
                  element={
                    <PrivateRoute>
                      <Recents />
                    </PrivateRoute>
                  }
                />
                {/* <Route
                  path="/email-case-list"
                  element={
                    <PrivateRoute>
                      <EmailCaseList />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/fax-case-list"
                  element={
                    <PrivateRoute>
                      <FaxCaseList />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/scan-case-list"
                  element={
                    <PrivateRoute>
                      <ScanCaseList />
                    </PrivateRoute>
                  }
                />
                   */}
              </>
            )}
            <Route
              path="/starred-documents"
              element={
                <PrivateRoute>
                  <Starred />
                </PrivateRoute>
              }
            />
            {hasPermissions("view_requested_documents") && (
              <Route
                path="/requests"
                element={
                  <PrivateRoute>
                    <Requests />
                  </PrivateRoute>
                }
              />
            )}
            {hasPermissions("view_clients") && (
              <>
                <Route
                  path="/clients"
                  element={
                    <PrivateRoute>
                      <Clients />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/clients/:cid"
                  element={
                    <PrivateRoute>
                      <Clients />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/clients/:cid/:bid"
                  element={
                    <PrivateRoute>
                      <Clients />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/clients/:cid/uploads"
                  element={
                    <PrivateRoute>
                      <Clients />
                    </PrivateRoute>
                  }
                />
              </>
            )}
            <Route
              path="/verify-clients"
              element={
                <PrivateRoute>
                  <ClientVerification />
                </PrivateRoute>
              }
            />
            {hasPermissions("view_all_documents") && (
              <>
                <Route
                  path="/documents"
                  element={
                    <PrivateRoute>
                      <Documents />
                    </PrivateRoute>
                  }
                />
                {/* The ClientID is used for queryString to fetch documents according to categories(unprocessed,processed,client unknown documents) */}
                <Route
                  path="/unprocessed-documents"
                  element={
                    <PrivateRoute>
                      <Documents clientID="null" />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/processed-documents"
                  element={
                    <PrivateRoute>
                      <Documents clientID="not null" />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/client-unknown-documents"
                  element={
                    <PrivateRoute>
                      <Documents clientID="client unknown" />
                    </PrivateRoute>
                  }
                />
              </>
            )}
            {hasPermissions("view_my_assigned") && (
              <Route
                path="/my-assigned-case-list"
                element={
                  <PrivateRoute>
                    <MyAssignedCaseList />
                  </PrivateRoute>
                }
              />
            )}
            {hasPermissions("view_all_unassigned") && (
              <Route
                path="/unassigned-case-list"
                element={
                  <PrivateRoute>
                    <UnassignedCaseList />
                  </PrivateRoute>
                }
              />
            )}
            {hasPermissions("view_all_assigned") && (
              <Route
                path="/assigned-case-list"
                element={
                  <PrivateRoute>
                    <AssignedCaseList />
                  </PrivateRoute>
                }
              />
            )}
            {hasPermissions("view_incoming_emails") && (
              <Route
                path="/email-case-list"
                element={
                  <PrivateRoute>
                    <EmailCaseList />
                  </PrivateRoute>
                }
              />
            )}
            {hasPermissions("view_incoming_faxes") && (
              <Route
                path="/fax-case-list"
                element={
                  <PrivateRoute>
                    <FaxCaseList />
                  </PrivateRoute>
                }
              />
            )}
            {hasPermissions("view_scanned_files") && (
              <Route
                path="/scan-case-list"
                element={
                  <PrivateRoute>
                    <ScanCaseList />
                  </PrivateRoute>
                }
              />
            )}
            {is_admin === "1" ? (
              <>
                <Route
                  path="/all-assigned-case-list"
                  element={
                    <PrivateRoute>
                      <AllCaseList />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/client-unknown-category"
                  element={
                    <PrivateRoute>
                      <ClientUnknownCaseList />
                    </PrivateRoute>
                  }
                />
              </>
            ) : null}
            <Route
              path="/my-processed-case-list"
              element={
                <PrivateRoute>
                  <ProcessedCaseList />
                </PrivateRoute>
              }
            />
            <Route
              path="/service-case-list"
              element={
                <PrivateRoute>
                  <ServiceCaseList />
                </PrivateRoute>
              }
            />
            {is_admin === "0" ? null : (
              <>
                <Route
                  path="/roles"
                  element={
                    <PrivateRoute>
                      <Roles />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/contract-templates"
                  element={
                    <PrivateRoute>
                      <ContractTemplates />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/contracts"
                  element={
                    <PrivateRoute>
                      <Contracts />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/requested-contracts"
                  element={
                    <PrivateRoute>
                      <RequestedContracts />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/signed-contracts"
                  element={
                    <PrivateRoute>
                      <SignedContracts />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/category"
                  element={
                    <PrivateRoute>
                      <Category />
                    </PrivateRoute>
                  }
                />
              </>
            )}
            {hasPermissions("view_document_requests") && (
              <Route
                path="/document-request-settings"
                element={
                  <PrivateRoute>
                    <DocumentRequestSettings />
                  </PrivateRoute>
                }
              />
            )}
            {hasPermissions("view_field_settings") && (
              <Route
                path="/field-settings"
                element={
                  <PrivateRoute>
                    <FieldSettings />
                  </PrivateRoute>
                }
              />
            )}
            {hasPermissions("view_users") && (
              <Route
                path="/users"
                element={
                  <PrivateRoute>
                    <Users />
                  </PrivateRoute>
                }
              />
            )}
            {hasPermissions("view_trash_users") && (
              <Route
                path="/trash-users"
                element={
                  <PrivateRoute>
                    <TrashUsers />
                  </PrivateRoute>
                }
              />
            )}
            {hasPermissions("view_trash_documents") && (
              <Route
                path="/trash-documents"
                element={
                  <PrivateRoute>
                    <TrashDocuments />
                  </PrivateRoute>
                }
              />
            )}
            {is_admin === "0" ? null : (
              <Route
                path="/trash-category"
                element={
                  <PrivateRoute>
                    <TrashCategory />
                  </PrivateRoute>
                }
              />
            )}
          </Routes>
        </Router>
      </div>
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </QueryClientProvider>
  );
}

export default App;
