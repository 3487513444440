import { useState, useMemo, useContext } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../shadcn-components/ui/table";
import { Button } from "../../shadcn-components/ui/button";
import { Badge } from "../../shadcn-components/ui/badge";
import { Input } from "../../shadcn-components/ui/input";
import { UploadModal } from "../../components/client-verification/UploadModal";
import { Loader2, ArrowUpDown } from "lucide-react";
import TableSkeleton from "../common/TableSkeleton";
import PaginationSection from "../common/PaginationSection";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AuthContext } from "../../context/Auth/AuthContext";
import {
  SendCientVerificationDetails,
  verifyFaces,
} from "../../api/client-verification/verifyFaces";
import { toast } from "react-toastify";

export function VerificationTable({
  data,
  isLoading,
  currentPage,
  setCurrentPage,
  totalPages,
  pageSize,
}) {
  const [selectedClient, setSelectedClient] = useState(null);
  const [isVerifying, setIsVerifying] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortConfig, setSortConfig] = useState(null);

  const clientList = data?.[0]?.clients || [];
  // console.log(data);

  const queryClient = useQueryClient();
  const user = useContext(AuthContext);

  // Sorting function
  const handleSort = (key) => {
    setSortConfig((current) => {
      if (current?.key === key) {
        return { key, direction: current.direction === "asc" ? "desc" : "asc" };
      }
      return { key, direction: "asc" };
    });
  };

  // Filter and sort clients
  const filteredAndSortedClients = useMemo(() => {
    let result = [...clientList];
    // console.log(result);

    // Apply search filter
    if (searchQuery) {
      const query = searchQuery.toLowerCase();
      result = result.filter((client) =>
        client.name.toLowerCase().includes(query)
      );
    }

    // Apply sorting
    if (sortConfig) {
      result.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key])
          return sortConfig.direction === "asc" ? -1 : 1;
        if (a[sortConfig.key] > b[sortConfig.key])
          return sortConfig.direction === "asc" ? 1 : -1;
        return 0;
      });
    }

    return result;
  }, [clientList, searchQuery, sortConfig]);

  const getVerificationStatus = (status) => {
    // console.log(status);
    switch (status) {
      case "success":
        return <div className="text-green-500 text-white">Verified</div>;
      case "failed":
        return <div className="text-red-500 text-white">Failed</div>;
      default:
        return <div className="text-gray-500 text-white">Pending</div>;
    }
  };

  const verifyFacesMutation = useMutation({
    mutationFn: ({ token, selfie, idcard }) =>
      verifyFaces(token, selfie, idcard),
    onMutate: () => setIsVerifying(true),
    onSuccess: (data, variables) => {
      setIsVerifying(false);
      if (data.status === "success" || data.status === "error") {
        sendClientVerificationDetailsMutation.mutate({
          token: user?.token,
          contact_id: selectedClient?.contact_id,
          verification_status: data.status === "success" ? "success" : "failed",
          verified_by: user.id,
          selfie_url: variables.selfie,
          idproof_url: variables.idcard,
        });
      } else {
        toast.error("Face verification failed.", {
          position: "top-center",
          autoClose: 3000,
          theme: "dark",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    },
    onError: () => {
      setIsVerifying(false);
      toast.error("Error verifying faces. Please try again.", {
        position: "top-center",
        autoClose: 3000,
        theme: "dark",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    },
  });

  const sendClientVerificationDetailsMutation = useMutation({
    mutationFn: ({
      token,
      contact_id,
      verification_status,
      verified_by,
      selfie_url,
      idproof_url,
    }) =>
      SendCientVerificationDetails(
        token,
        contact_id,
        verification_status,
        verified_by,
        selfie_url,
        idproof_url
      ),
    onSuccess: () => {
      queryClient.invalidateQueries(["fetchClient"]);
      setSelectedClient(null);
      toast.success("Verification completed successfully", {
        position: "top-center",
        autoClose: 3000,
        theme: "dark",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    },
    onError: () =>
      toast.error("Error verifying, Please try again.", {
        position: "top-center",
        autoClose: 3000,
        theme: "dark",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }),
  });

  const handleVerify = async (selfieFile, idFile) => {
    if (!selectedClient) return;

    verifyFacesMutation.mutate({
      token: user?.token,
      selfie: selfieFile,
      idcard: idFile, // ✅ Fixed key from id_card → idcard
    });
  };

  return (
    <>
      <div className="space-y-4">
        <Input
          placeholder="Search clients..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="max-w-sm"
        />

        <div className="rounded-md border">
          {isLoading ? (
            <TableSkeleton />
          ) : (
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>
                    <Button
                      variant="ghost"
                      onClick={() => handleSort("name")}
                      className="h-8 px-2 hover:bg-transparent"
                    >
                      Client Name <ArrowUpDown className="ml-2 h-4 w-4" />
                    </Button>
                  </TableHead>
                  <TableHead>Status</TableHead>
                  <TableHead>Verfied By</TableHead>
                  <TableHead>Verified On</TableHead>
                  <TableHead>Action</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {filteredAndSortedClients.length > 0 ? (
                  filteredAndSortedClients.map((client) => (
                    <TableRow key={client.contact_id}>
                      <TableCell className="font-medium">
                        {client.name}
                      </TableCell>
                      <TableCell className="text-white">
                        <Badge
                          variant={
                            client.verification_status === "success"
                              ? "default"
                              : client.verification_status === "failed"
                              ? "destructive"
                              : "secondary"
                          }
                        >
                          {getVerificationStatus(client?.verification_status)}
                        </Badge>
                      </TableCell>
                      <TableCell className="font-medium">
                        {client.verified_by}
                      </TableCell>
                      <TableCell className="font-medium">
                        {client.verified_date}
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="outline"
                          onClick={() => setSelectedClient(client)}
                          className="disabled:cursor-not-allowed"
                          disabled={
                            client.verification_status === "success" || false
                          }
                        >
                          Verify
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={3} className="text-center py-4">
                      No clients found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          )}
        </div>
        <PaginationSection
          setCurrentPage={setCurrentPage}
          totalPages={totalPages}
          currentPage={currentPage}
        />
      </div>
      <UploadModal
        open={selectedClient !== null}
        onOpenChange={(open) => !open && setSelectedClient(null)}
        onVerify={handleVerify}
        loading={isVerifying}
        selectedClient={selectedClient}
      />
    </>
  );
}
