import baseUrl from "../config/baseUrl";

const editDocumentRequestSettings = async ({
  token,
  user_id,
  document_request_setting_id,
  document_name,
  document_desc,
  client_type,
  service_sub_type_c,
  request_type,
  // document_stage,
  ocr_status,
  status,
  is_admin,
}) => {
  const formData = new FormData();
  formData.append("user_id", user_id);
  formData.append("document_request_setting_id", document_request_setting_id);
  formData.append("document_name", document_name);
  formData.append("document_desc", document_desc);
  formData.append("client_type", client_type);
  formData.append("service_sub_type_c", service_sub_type_c);
  formData.append("request_type", request_type);
  // formData.append("document_stage", document_stage);
  formData.append("ocr_status", ocr_status);
  formData.append("status", status);
  formData.append("is_admin", is_admin);

  try {
    const response = await fetch(
      `${baseUrl}/api/update-document-request-setting-data?document_request_setting_id=${document_request_setting_id}&request_type=${request_type}&client_type=${client_type}&service_sub_type_c=${service_sub_type_c}&document_desc=${document_desc}&document_name=${document_name}&status=${status}&ocr_status=${ocr_status}&is_admin=${is_admin}`,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    return error;
  }
};

export default editDocumentRequestSettings;
