import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
import { useDebounce } from "use-debounce";
import { v4 as uuidv4 } from "uuid";
import pdfDetails from "../../api/pdfDetails";
import { AuthContext } from "../../context/Auth/AuthContext";
import Loading from "../material-ui/Loading";
import PdfDetails from "./PdfDetails";
import PdfVersion from "./PdfVersion";
import PdfEditor from "./PdfEditor";
import pdfBase64Data from "../../api/pdfBase64Data";
import getDocumentRequestStatus from "../../api/getDocumentRequestStatus";
import { TbEdit } from "react-icons/tb";
import { IoArrowBackCircleSharp, IoCloseSharp } from "react-icons/io5";
import { IoDocumentText } from "react-icons/io5";
import { BiDetail } from "react-icons/bi";
import { TbCircleCheck } from "react-icons/tb";
import { TiDeleteOutline } from "react-icons/ti";
import ApprovePDFDocumentPopup from "../client-table/client-documents/ApprovePDFDocumentPopup";
import RejectPDFDocumentPopup from "../client-table/client-documents/RejectPDFDocumentPopup";
import AddTask from "../document-table/AddTask";
import { useDrop } from "react-dnd";
import ChatPopup from "../common/ocr/OcrPopup";
import { TbVersions } from "react-icons/tb";
import { FaDownload, FaHistory } from "react-icons/fa";
import { RiCloseCircleFill } from "react-icons/ri";
import { Tooltip } from "@mui/material";
import ButtonUI from "../material-ui/ButtonUI";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import InputUI from "../material-ui/InputUI";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import SelectBox from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import getClientRefNo from "../../api/getClientRefNo";
import getClientName from "../../api/getClientName";
import { toast } from "react-toastify";
import editDocument from "../../api/editDocument";
import baseUrl, { sugarUrl } from "../../config/baseUrl";
import clientsData from "../../api/clients";
import editClient from "../../api/editClient";
import getAllFieldTypeList from "../../api/getAllFieldTypeList";
import { BiLinkAlt } from "react-icons/bi";
import LinkToRequest from "../document-table/LinkToRequest";
import useDebounceSearch from "../../hooks/useDebounceSearch";
import OcrFields from "../common/ocr/OcrFields";
import "../../styles/PdfViewer.css";
import elasticToken from "../../config/elasticToken";
import SendSMS from "./SendSMS";
import { Card, CardContent } from "../../shadcn-components/ui/card";
import JSZip from "jszip";
import ZipViewer from "./ZipViewer";
import markClientUnknown from "../../api/markClientUnknown";
import { useRoleContext } from "../../context/RolesContext";
import BusinessCardInfo from "../common/BusinessCardInfo";
import ConfirmToast from "../common/ConfirmToast";

const updateDocumentDetailsFormSchema = yup.object().shape({
  name: yup.string().required("Document Name is required field"),
  subcategory: yup
    .string()
    .nullable()
    .required("Sub Category is required field"),
});

const PdfViewer = ({
  documentType,
  clientID,
  documentID,
  documentTitle,
  documentURL,
  changeActiveTab,
  document_table,
  document_version,
  documentRequestID,
  documentCategory,
  documentDesc,
  currentTabId,
  closeTabById,
  setDocumentDetails,
  fromTable,
  setUpdatedFromPDF,
  linkedDocReqName,
  documentSubCategory,
  clientPhone,
  showPdfOnly = false,
  benID,
}) => {
  console.log(documentType);
  const [isPdfDetailsContainerOpen, setIsPdfDetailsContainerOpen] =
    useState(false);
  const [pdfData, setPdfData] = useState({});
  const [editor, setEditor] = useState(false);
  const [pdfBase64, setPdfBase64] = useState("");
  const [documentVersionId, setDocumentVersionId] = useState(null);
  const [pdfSrc, setPdfSrc] = useState("");
  const [documentRequestStatus, setDocumentRequestStatus] = useState("");
  const [approveConfirmOpen, setApproveConfirmOpen] = useState(false);
  const [disapproveConfirmOpen, setDisapproveConfirmOpen] = useState(false);
  const [email, setEmail] = useState(""); // state for the email field
  const [showPopup, setShowPopup] = useState(true);
  const [clientNames, setClientNames] = useState([]);
  const [description, setDescription] = useState(documentDesc);
  const [subCategoryOption, setSubCategoryOption] = useState([]);
  const [contactId, setContactId] = useState(clientID);
  const [benefId, setBenefId] = useState(
    benID !== null && benID !== "" ? benID : clientID
  );
  const [linkRequest, setLinkRequest] = useState(false);
  const [linkRequestOpen, setLinkRequestOpen] = useState(false);
  const [requestOptions, setRequestOptions] = useState([]);
  const [requestId, setRequestId] = useState(documentRequestID);
  const [showLinkButton, setShowLinkButton] = useState(false);
  const [assignClient, setAssignClient] = useState(false);
  const [clientNameLabel, setClientNameLabel] = useState("");
  const [defaultClientNameLabel, setDefaultClientNameLabel] = useState("");
  const [selectedRequestLabel, setSelectedRequestLabel] = useState("");
  const [searchedTerm, setSearchedTerm] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [total, setTotal] = useState(0);
  const [showMoreLoading, setShowMoreLoading] = useState(false);
  const [showFields, setShowFields] = useState(false);
  const [searchedClientId, setSearchedClientId] = useState([]);
  const [additionalDataFetched, setAdditionalDataFetched] = useState(false);
  const [additionalDataLoading, setAdditionalDataLoading] = useState(false);
  const [isClientListFetched, setIsClientListFetched] = useState(false); //to check if client list fetched

  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(updateDocumentDetailsFormSchema),
  });

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    ignoreCase: true, // Ignore case sensitivity
    stringify: (option) => option.fields_name,
  });

  const [pdfTab, setPdfTab] = useState([
    {
      id: uuidv4(),
      title: <IoDocumentText />,
      content: (pdfData) => (
        <PdfDetails
          pdfData={pdfData}
          documentID={documentID}
          documentTitle={documentTitle}
          documentType={documentType}
          pdfDetailsVersionData={pdfDetailsVersionData}
          documentVersionId={documentVersionId}
          setDocumentVersionId={setDocumentVersionId}
          document_version={document_version}
        />
      ),
    },
    {
      id: uuidv4(),
      title: <TbVersions />,
      content: (documentID) => <PdfVersion documentID={documentID} />,
    },
  ]);
  const [tabValue, setTabValue] = useState(pdfTab[0].id);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [pdfDataLoading, setPdfDataLoading] = useState(true);
  const [searchClientNotFound, setSearchClientNotFound] = useState(false);
  const [performOcr, setPerformOcr] = useState(false);
  const [loadingOcr, setLoadingOcr] = useState(false);
  const [existingOcrData, setExistingOcr] = useState(false);
  const inputRef = useRef(null);
  const [zipContent, setZipContent] = useState([]); //To set zipContent
  const [isClientUnknown, setIsClientUnknown] = useState(false); //to set Client unknown
  const iframeRef = useRef(null);

  const [businessCardInfoClientID, setBusinessCardInfoClientID] = useState("");

  const { token, user_id, is_admin } = useContext(AuthContext);

  const isMounted = useRef(true);

  // Marks the component as unmounted once it is unmounted.
  // This prevents the issue where a tab reopens if the form is submitted and the tab is closed immediately,
  // caused by a delayed function call that updates the state and triggers the tab to open again.
  useEffect(() => {
    return () => {
      isMounted.current = false; // Mark's unmounted when tab closes
    };
  }, []);

  //Role Based Permissions

  // Function to check if the user has a specific permission.
  // Admin users ("is_admin" === "1") automatically have access to all permissions.
  const { permissions } = useRoleContext();
  const hasPermission = (permission) => {
    if (is_admin === "1") {
      return true;
    }

    return permissions.includes(permission);
  };

  // Permission flags for various user actions, granting access based on user roles or admin status.
  // Admin users have all permissions by default.
  const canEditPdf = hasPermission("edit_pdf_documents");
  const canLinkDocument = hasPermission("link_documents");
  const canApproveDocument = hasPermission("document_approval");
  const canEditClient = hasPermission("client_edit");
  const canMarkClientUnknown = hasPermission("mark_client_unknown");
  const canPerformOCR = hasPermission("perform_ocr");
  const canAddTask = hasPermission("add_task");
  const canSendSMS = hasPermission("send_sms");

  // Handles marking a client as unknown by calling the appropriate API.
  // Displays a success toast if the operation is successful, otherwise shows an error message.
  // Errors during the process are logged to the console.

  const handleClientUnknown = async () => {
    const toastId = toast(
      ({ closeToast }) => (
        <ConfirmToast
          message="Are you sure you want to mark the client as unknown?"
          onConfirm={async () => {
            toast.dismiss(toastId); // close the confirm toast

            try {
              const res = await markClientUnknown({ documentID, token });

              if (res.code === 200) {
                setIsClientUnknown(true);
                toast.success("Client Marked as Unknown", {
                  position: "top-center",
                  autoClose: 3000,
                  theme: "dark",
                });
              } else {
                toast.error("Error while setting client as unknown", {
                  position: "top-center",
                  autoClose: 5000,
                  theme: "dark",
                });
              }
            } catch (error) {
              console.error(error);
              toast.error("An unexpected error occurred", {
                position: "top-center",
                autoClose: 5000,
                theme: "dark",
              });
            }
          }}
          onCancel={() => {
            toast.dismiss(toastId); // Just close the confirm toast
          }}
        />
      ),
      {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        className: "!border-[2px] !border-[#007bffbf] !rounded-sm",
      }
    );
  };

  // useEffect(() => {
  //   console.log("Iframe useEffect");
  //   const iframe = iframeRef.current;

  //   if (iframe) {
  //     const handleLoad = () => {
  //       setLoading(false);
  //       console.log("Iframe loaded");
  //     };

  //     const handleError = () => {
  //       console.log("Error loading iframe");
  //       setLoading(false);
  //       setError("Failed to load PDF");
  //     };

  //     // Add event listeners
  //     iframe.addEventListener("load", handleLoad);
  //     iframe.addEventListener("error", handleError);

  //     // Check if iframe is already loaded
  //     if (iframe.contentWindow) {
  //       try {
  //         const readyState = iframe.contentDocument?.readyState;
  //         if (readyState === "complete") {
  //           handleLoad();
  //         }
  //       } catch (e) {
  //         console.log("Unable to check iframe readyState", e);
  //       }
  //     }

  //     console.log(iframe.contentDocument);

  //     return () => {
  //       iframe.removeEventListener("load", handleLoad);
  //       iframe.removeEventListener("error", handleError);
  //     };
  //   }
  // }, [pdfSrc]);

  const getDocumentRequestStatusFunc = async () => {
    const response = await getDocumentRequestStatus(token, requestId);
    const data = await response.data;
    setDocumentRequestStatus(data[0]?.document_status);
  };

  const pdfBlob = async () => {
    setLoading(true);
    setPdfDataLoading(true);
    try {
      const response = await pdfBase64Data(token, documentID, document_table);
      const data = response.data;
      setPdfBase64(data);
    } catch (error) {
      console.error("Error fetching PDF Blob:", error);
    } finally {
      setLoading(false);
      setPdfDataLoading(false);
    }
  };

  const pdfDetailsData = async () => {
    setLoading(true);
    setPdfDataLoading(true);
    try {
      const response = await pdfDetails(token, documentID, document_table);
      const data = response?.data;

      if (response.code === 200) {
        setPdfData(data);
        setPdfSrc(data[0]?.pdflink);
      } else {
        toast.error("Error while getting document", {
          position: "top-center",
          autoClose: 5000,
          theme: "dark",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.error("Error fetching PDF details:", error);
    } finally {
      setLoading(false);
      setPdfDataLoading(false);
    }
  };

  const pdfDetailsVersionData = async () => {
    setLoading(true);
    try {
      const response = await pdfDetails(
        token,
        documentID,
        document_table,
        documentVersionId
      );
      const data = response.data;
      setPdfData(data);
      setPdfSrc(data[0]?.pdflink);
    } catch (error) {
      console.error("Error fetching PDF version details:", error);
    } finally {
      setLoading(false);
    }
  };

  const getDocumentDetails = async () => {
    if (!setDocumentDetails) return;

    const url = `${baseUrl}/api/get-all-doc-data?document_id=${documentID}&isAdmin=${is_admin}&userId=${user_id}`;

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) throw new Error("Failed to fetch document details");

      const data = await response.json();

      if (isMounted.current) {
        setDocumentDetails(data?.data[0]?.document[0]);
        if (setUpdatedFromPDF) {
          setUpdatedFromPDF(true);
        }
      }
    } catch (error) {
      console.error("Error fetching document details:", error);
    }
  };

  useEffect(() => {
    if (requestId) {
      getDocumentRequestStatusFunc();
    }
  }, [requestId]);

  useEffect(() => {
    if (linkRequest) {
      getDocumentDetails();
    }
  }, [linkRequest]);

  useEffect(() => {
    setValue("addClientName", clientID);
    setValue("name", documentTitle);
    setValue("subcategory", documentSubCategory);
    fetchSubCategory();
    if (clientID && clientID !== "null") {
      getClientNameDetails();
      getClientRefNumber();
      fetchRequestList(benefId);
    }
  }, []);

  useEffect(() => {
    if (!clientNameLabel) {
      setValue("addClientName", null);
      setValue("caseId", "");
    }
    if (clientNameLabel === defaultClientNameLabel) {
      setValue("addClientName", clientID);
      if (clientID && clientID !== "null") {
        getClientRefNumber();
      }
    }
  }, [clientNameLabel]);

  // useEffect(() => {
  //   if (clientID && clientID !== "null") {
  //     getClientNameDetails();
  //   }
  // }, [editor]);

  useEffect(() => {
    if (assignClient === true) {
      fetchRequestList(benefId);
    }
  }, [assignClient]);

  useEffect(() => {
    pdfDetailsData();
    pdfBlob();
  }, []);

  useEffect(() => {
    setIsClientListFetched(false);
    if (clientNames.length > 0) {
      setSearchedClientId(
        clientNames.map((client) => {
          if (
            client._module === "Contacts" ||
            client._module === "cpm_CaseParties"
          ) {
            return client.case_id; // Use case_id for Contacts
          }
          return client.id; // Use id for other modules
        })
      );
      setIsClientListFetched(true);
    }
  }, [clientNames]);

  useEffect(() => {
    (clientID || contactId) &&
    !requestId &&
    !linkRequest &&
    // requestOptions?.length > 0 &&
    !documentRequestStatus
      ? setShowLinkButton(true)
      : setShowLinkButton(false);
  }, [requestOptions, documentRequestStatus]);

  const handleChange = (index) => {
    setTabValue(index);
  };

  const handleApproveDocument = (e) => {
    setApproveConfirmOpen(true);
  };

  const handleDisapproveDocument = (e) => {
    setDisapproveConfirmOpen(true);
  };

  const changePdfDetailsContainerVisibility = () => {
    setIsPdfDetailsContainerOpen(!isPdfDetailsContainerOpen);
  };

  const getClientRefNumber = async () => {
    const clientRefNoResponse = await getClientRefNo({
      contact_id: clientID,
      token: token,
    });
    setValue("caseId", clientRefNoResponse?.data[0]?.client_reference_number);
  };

  const getClientNameDetails = async () => {
    const contactId = benID !== null && benID !== "" ? benID : clientID;
    const clientNameResponse = await getClientName({
      contact_id: contactId,
      token: token,
    });
    setClientNameLabel(clientNameResponse?.data?.name);
    setDefaultClientNameLabel(clientNameResponse?.data?.name);
  };

  const fetchSubCategory = async () => {
    try {
      const response = await getAllFieldTypeList({
        token,
        type: "document_subtype",
      });
      setSubCategoryOption(response.data);
    } catch (error) {
      return error;
    }
  };

  const handleClientNameChange = async (contactId) => {
    const clientRefNoResponse = await getClientRefNo({
      contact_id: contactId,
      token: token,
    });

    if (clientRefNoResponse.code === 200) {
      // setClientNameLabel("Client Name");
      setValue("caseId", clientRefNoResponse?.data[0]?.client_reference_number);
      setContactId(contactId);
    } else {
      toast.error(
        "Error while getting client details in DMS. Please contact admin!",
        {
          position: "top-center",
          autoClose: 5000,
          theme: "dark",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      setValue("caseId", "");
      setContactId("");
    }
  };
  const fetchRequestList = async (selectedClient) => {
    if (selectedClient) {
      setLinkRequest(false);
      //const url = `${baseUrl}/api/get-doc-request-data?isAdmin=${is_admin}&userId=${user_id}&contact_id=${selectedClient}&unlinked=True`;
      const url = `${baseUrl}/api/get-client-document-requests-for-linking?userId=1&isAdmin=1&contact_id=${selectedClient}`;
      try {
        const response = await fetch(url, {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        const data = await response.json();
        if (data?.code === 200) {
          // Create the additional item
          const additionalItem = {
            document_request_id: "Additional Document",
            document_req_name: "Additional Document",
          };

          // Add it as the first item to the data array
          const updatedData = [
            additionalItem,
            ...data?.data?.document_requests_for_linking,
          ];

          // Set the updated data to the state
          setRequestOptions(updatedData);
        } else {
          // Set only the additional document in the state
          setRequestOptions([
            {
              document_request_id: "Additional Document",
              document_req_name: "Additional Document",
            },
          ]);
        }
      } catch (error) {
        return error;
      }
    }
  };

  const handleLinkRequest = (e) => {
    setLinkRequestOpen(true);
  };

  const onEditDocumentFormSubmit = async (editDocumentFormData) => {
    setSubmitLoading(true);

    const ocrData = {};
    const otherFormData = {};

    Object.keys(editDocumentFormData).forEach((key) => {
      if (key.startsWith("ocr_")) {
        if (editDocumentFormData[key]) {
          const originalKey = key.replace("ocr_", "");
          ocrData[originalKey] = editDocumentFormData[key];
        }
      } else {
        otherFormData[key] = editDocumentFormData[key];
      }
    });

    if (Object.keys(ocrData).length > 0) {
      const ocrDataString = JSON.stringify(ocrData);
      try {
        const ocrEndpoint = existingOcrData
          ? `${baseUrl}/api/update-ocr-data
`
          : `${baseUrl}/api/add-ocr-data`;
        const bodyData = {
          document_id: documentID,
          ocr_data: ocrDataString,
        };

        const res = await fetch(ocrEndpoint, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(bodyData),
        });
        res.status === 200 && setExistingOcr(true);
      } catch (error) {
        console.error("Error posting OCR data:", error);
      }
    }

    const editDocumentData = {
      token: token,
      user_id: user_id,
      document_id: documentID,
      document_desc: description,
      document_name: otherFormData.name,
      document_sub_category: otherFormData.subcategory,
      contact_id: otherFormData.addClientName,
    };

    // If a client name label is provided, set it as the default label
    // and mark the client as known by updating the "client_unknown" flag to "0".
    if (clientNameLabel) {
      setDefaultClientNameLabel(clientNameLabel);
      editDocumentData.client_unknown = "0";
    }

    const editDocumentFormResponse = await editDocument(editDocumentData);

    if (editDocumentFormResponse.code === 200) {
      getDocumentDetails();
      setAssignClient(true);
      setSubmitLoading(false);
      toast.success("Document is updated successfully", {
        position: "top-center",
        autoClose: 3000,
        theme: "dark",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setSubmitLoading(false);
      toast.error("Error while updating document", {
        position: "top-center",
        autoClose: 5000,
        theme: "dark",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const clientIdsString = searchedClientId.join(",");

  // Fetches and updates client data (case status and legal team) for the provided client IDs.
  const fetchAdditionalClientData = async () => {
    setAdditionalDataLoading(true);
    if (!isClientListFetched) {
      console.error("No client IDs found to fetch data.");
      setAdditionalDataLoading(false);
      return;
    }

    try {
      const fetchedData = [];

      const response = await fetch(
        `${baseUrl}/api/getCaseStatus?caseId=${clientIdsString}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = await response.json();
      if (data?.caseDetails) {
        data.caseDetails.forEach((caseDetail) => {
          const { status, Lead_Attorney, Legal_Assistant, Paralegal } =
            caseDetail;

          fetchedData.push({
            status,
            Lead_Attorney,
            Legal_Assistant,
            Paralegal,
          });
        });
      }

      setClientNames((prev) =>
        prev.map((client, index) => ({
          ...client,
          ...(fetchedData[index] || {}),
        }))
      );

      setAdditionalDataLoading(false);
      setAdditionalDataFetched(true);
    } catch (error) {
      setAdditionalDataLoading(false);
      console.error("Error fetching additional client data:", error);
    }
  };

  // Fetches and updates the client list based on the search term and offset.
  const getClientList = useCallback(
    async (searchTerm, offsetValue, contactId) => {
      if (offsetValue === 0) {
        setClientNames([]);
      }
      if (searchTerm) {
        setClientNameLabel("Client Name");
        setSearchLoading(true);
        try {
          const url = `${baseUrl}/api/customGlobalSearch?q=${searchTerm}&max_num=20&offset=${offsetValue}&highlights=true&module_list=Cases,cpm_CaseParties`;

          const response = await fetch(url, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          const data = await response.json();

          if (data?.total > 0) {
            setClientNames((prev) => [...prev, ...data.records]);
            setTotal(data.total);
            setSearchLoading(false);
            setShowMoreLoading(false);
          } else {
            setSearchClientNotFound(true);
            setSearchLoading(false);
          }
        } catch (error) {
          setSearchLoading(false);
          console.error("Error:", error);
        }
      } else {
        if (!contactId) {
          setClientNameLabel("");
        }
      }
    },
    []
  );

  const [searchTerm, handleClientNameSearch] = useDebounceSearch(
    "",
    documentID,
    offset,
    clientID,
    2000,
    getClientList
  );

  useEffect(() => {
    setOffset(0);
    setTotal(0);
  }, [searchedTerm]);

  // Fetches additional client data when the client list is fetched or client IDs change.
  useEffect(() => {
    if (isClientListFetched || clientIdsString) {
      fetchAdditionalClientData();
    }
  }, [isClientListFetched, clientIdsString]);

  const handlePerformOcr = () => {
    toast.warning(
      "Disclaimer for OCR: For accurate processing. Please ensure that the text in the document is legible while uploading.",
      {
        position: "top-center",
        autoClose: 7000,
        theme: "dark",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        className: "custom-toast",
      }
    );
    setPerformOcr(true);
    setShowFields(true);
  };

  // Checks if a given source URL is an image based on its file extension.
  const isImage = (src) => {
    const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "svg"];
    const extension = src.split(".").pop().toLowerCase();
    return imageExtensions.includes(extension);
  };

  // Downloads the document by creating a temporary link element.
  const handleDocumentDownload = function () {
    const link = document.createElement("a");
    link.href = pdfSrc;
    link.download = documentTitle || "download";
    link.click();
  };

  // Converts a base64 string to a Blob object with the specified MIME type.
  const base64ToBlob = (base64, mimeType = "application/zip") => {
    const binaryString = atob(base64); // Decode the base64 string
    const length = binaryString.length;
    const bytes = new Uint8Array(length);
    for (let i = 0; i < length; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return new Blob([bytes], { type: mimeType });
  };

  // Converts the PDF's Base64 data to a Blob and updates the zip content when pdfBase64 changes.
  useEffect(() => {
    if (pdfBase64 && pdfBase64[0]?.data) {
      const blob = base64ToBlob(pdfBase64[0]?.data);
      setZipContent(blob);
    }
  }, [pdfBase64]);

  //Setting the clientId for the Info Card
  const clientInfoID = function () {
    const addClientNameValue = getValues("addClientName");
    if (
      addClientNameValue !== null &&
      addClientNameValue !== undefined &&
      addClientNameValue !== ""
    ) {
      setBusinessCardInfoClientID(addClientNameValue);
    } else if (clientNameLabel === defaultClientNameLabel) {
      setBusinessCardInfoClientID(clientID);
    } else {
      setBusinessCardInfoClientID(clientID);
    }
  };

  useEffect(() => {
    clientInfoID();
  }, [clientID, getValues("addClientName"), clientNameLabel]);

  return (
    <>
      {approveConfirmOpen && (
        <ApprovePDFDocumentPopup
          documentRequestId={requestId}
          setReqStatus={setDocumentRequestStatus}
          setApproveConfirmOpen={setApproveConfirmOpen}
          {...(fromTable !== "Documents" && {
            currentTabId: currentTabId,
            closeTabById: closeTabById,
          })}
        />
      )}

      {disapproveConfirmOpen && (
        <RejectPDFDocumentPopup
          documentRequestId={requestId}
          setReqStatus={setDocumentRequestStatus}
          setDisapproveConfirmOpen={setDisapproveConfirmOpen}
          currentTabId={currentTabId}
          closeTabById={closeTabById}
        />
      )}

      {linkRequestOpen && (
        <LinkToRequest
          documentId={documentID}
          requestOptions={requestOptions}
          setLinkRequest={setLinkRequest}
          setReqStatus={setDocumentRequestStatus}
          setRequestId={setRequestId}
          setLinkRequestOpen={setLinkRequestOpen}
          setSelectedRequestLabel={setSelectedRequestLabel}
          // currentTabId={currentTabId}
          // closeTabById={closeTabById}
        />
      )}

      {
        <div className="pdf-viewer-container relative pb-">
          {!editor ? (
            <div className="pdf-viewer">
              {/* /////////////////////////// Show only if `showPdfOnly` is false /////////////////////////// */}
              {showPdfOnly ? null : (
                <div className="pdf-viewer-header gap-2">
                  <div className="pdf-document-title capitalize flex-1 w-1/2">
                    {documentTitle}
                    {linkedDocReqName
                      ? " - " + linkedDocReqName + " (Linked Request)"
                      : null}
                  </div>
                  <div className="view-pdf-doc-icon">
                    {(clientID || assignClient) && (
                      <BusinessCardInfo clientID={businessCardInfoClientID} />
                    )}
                    {canMarkClientUnknown && (
                      <ButtonUI
                        formButton={false}
                        variant="outlined"
                        buttonText="Client Unknown"
                        style={{
                          backgroundColor: "#3792ed",
                          color: "#fff",
                          opacity:
                            pdfData[0]?.client_unknown === "1" ||
                            isClientUnknown ||
                            defaultClientNameLabel ||
                            loading
                              ? 0.5
                              : 1,
                          cursor:
                            pdfData[0]?.client_unknown === "1" ||
                            isClientUnknown ||
                            defaultClientNameLabel ||
                            loading
                              ? "none"
                              : "pointer",
                        }}
                        disabled={
                          pdfData[0]?.client_unknown === "1" ||
                          isClientUnknown ||
                          defaultClientNameLabel ||
                          loading
                            ? true
                            : false
                        }
                        onClickFunc={handleClientUnknown}
                      />
                    )}
                    {canPerformOCR && (
                      <button
                        className={`bg-[#3792ed] text-white p-2 rounded-sm disabled:bg-opacity-70 ${
                          loadingOcr ? "bg-opacity-70 cursor-not-allowed" : ""
                        }`}
                        onClick={handlePerformOcr}
                        disabled={loadingOcr || !documentSubCategory}
                      >
                        {existingOcrData ? "Re-Ocr" : "Perform Ocr"}
                      </button>
                    )}
                    {clientID && canAddTask && (
                      <AddTask contactId={contactId} />
                    )}
                    {clientID && clientPhone && canSendSMS ? (
                      <SendSMS clientPhone={clientPhone} clientID={clientID} />
                    ) : null}
                    {document_table != "contract_requests" &&
                      (document_table != "document_request_settings" ||
                        !document_table) && (
                        <>
                          {(clientID || contactId) &&
                            requestId &&
                            documentRequestStatus?.toLowerCase() ===
                              "approved" && (
                              <div
                                className="all-document-pdf-viewer-icon !cursor-default"
                                style={{ color: "green", fontSize: 26 }}
                                title="Approved"
                              >
                                <TbCircleCheck />
                              </div>
                            )}
                          {(clientID || contactId) &&
                            requestId &&
                            documentRequestStatus?.toLowerCase() ===
                              "rejected" && (
                              <div
                                className="all-document-pdf-viewer-icon !cursor-default"
                                style={{ color: "red", fontSize: 29 }}
                                title="Rejected"
                              >
                                <TiDeleteOutline />
                              </div>
                            )}

                          {canApproveDocument && (
                            <ButtonUI
                              formButton={false}
                              variant="outlined"
                              buttonText={<TbCircleCheck />}
                              style={{
                                backgroundColor:
                                  documentRequestStatus?.toLowerCase() ===
                                  "uploaded"
                                    ? "#28a745"
                                    : "#d8d8e4df",

                                color:
                                  documentRequestStatus?.toLowerCase() ===
                                  "uploaded"
                                    ? "white"
                                    : "rgb(18 215 24)",
                                marginRight: "5px",
                                marginBottom: "5px",
                                padding: "8px",
                                minWidth: "fit-content",
                                border: "none",
                              }}
                              disabled={
                                documentRequestStatus?.toLowerCase() ===
                                "uploaded"
                                  ? false
                                  : true
                              }
                              onClickFunc={handleApproveDocument}
                            />
                          )}

                          {canApproveDocument && (
                            <ButtonUI
                              formButton={false}
                              variant="outlined"
                              buttonText={<TiDeleteOutline />}
                              style={{
                                backgroundColor:
                                  documentRequestStatus?.toLowerCase() ===
                                  "uploaded"
                                    ? "#dc3545"
                                    : "#d8d8e4df",

                                color:
                                  documentRequestStatus?.toLowerCase() ===
                                  "uploaded"
                                    ? "white"
                                    : "rgb(215 18 18)",
                                marginRight: "5px",
                                marginBottom: "5px",
                                padding: "8px",
                                minWidth: "fit-content",
                                border: "none",
                              }}
                              disabled={
                                documentRequestStatus?.toLowerCase() ===
                                "uploaded"
                                  ? false
                                  : true
                              }
                              onClickFunc={handleDisapproveDocument}
                            />
                          )}
                          {canLinkDocument && (
                            <ButtonUI
                              formButton={false}
                              variant="outlined"
                              buttonText={<BiLinkAlt />}
                              style={{
                                backgroundColor: showLinkButton
                                  ? "#17a2b8"
                                  : "#d8d8e4df",
                                color: showLinkButton
                                  ? "white"
                                  : "rgb(18 215 24)",
                                marginRight: "5px",
                                marginBottom: "5px",
                                padding: "8px",
                                minWidth: "fit-content",
                                border: "none",
                              }}
                              disabled={showLinkButton ? false : true}
                              onClickFunc={handleLinkRequest}
                            />
                          )}
                        </>
                      )}

                    {documentType === "pdf" &&
                    document_table != "contract_requests" &&
                    (document_table != "document_request_settings" ||
                      !document_table)
                      ? canEditPdf && (
                          <div
                            onClick={
                              !pdfDataLoading
                                ? () => setEditor(!editor)
                                : undefined
                            }
                            className={`edit-doc-btn ${
                              pdfDataLoading
                                ? "cursor-not-allowed opacity-50 pointer-events-none"
                                : "cursor-pointer"
                            }`}
                            title={
                              pdfDataLoading
                                ? "Loading PDF, please wait..."
                                : "Edit PDF"
                            }
                          >
                            <TbEdit />
                          </div>
                        )
                      : null}
                    <div
                      className="pdf-viewer-icon"
                      onClick={changePdfDetailsContainerVisibility}
                      title="PDF Details"
                    >
                      {isPdfDetailsContainerOpen ? (
                        <IoCloseSharp />
                      ) : (
                        <BiDetail />
                      )}
                    </div>
                  </div>
                </div>
              )}
              <div
                className={
                  document_table != "contract_requests" &&
                  document_table != "document_request_settings"
                    ? showPdfOnly
                      ? "w-full"
                      : "document-viewer-with-from"
                    : null
                }
              >
                <div className="iframe-wrapper  relative w-full">
                  {loading && (
                    <div className="absolute inset-0 flex justify-center items-center bg-white bg-opacity-80 z-10">
                      <Loading />
                    </div>
                  )}
                  {!loading && pdfData && pdfSrc ? (
                    documentType.toLowerCase() === "pdf" ||
                    documentType.toLowerCase() === "txt" ? (
                      <iframe
                        ref={iframeRef}
                        title={documentTitle}
                        src={pdfSrc}
                        width="100%"
                        height="590"
                        key={pdfSrc}
                      ></iframe>
                    ) : ["doc", "docx", "xls", "xlsx", "pptx", "ppt"].includes(
                        documentType.toLowerCase()
                      ) ? (
                      <div className="relative w-full h-full">
                        {/* <iframe
                          title={documentTitle}
                          src={`https://docs.google.com/viewer?url=${pdfSrc}&embedded=true`}
                          className="w-full h-full border-none"
                        ></iframe> */}
                        <iframe
                          title={documentTitle}
                          src={`https://view.officeapps.live.com/op/embed.aspx?src=${pdfSrc}`}
                          className="w-full h-full border-none"
                        ></iframe>

                        {/* <button
                          onClick={handleDocumentDownload}
                          className="absolute top-3 right-4 bg-[#44a0cf] hover:bg-[#3c8cb4] py-2 px-4 rounded-lg flex gap-1 justify-center items-center self-center transition-all duration-300 text-white"
                        >
                          <FaDownload />
                          Download
                        </button> */}
                      </div>
                    ) : documentType.toLowerCase() === "mp4" ? (
                      <div className="flex justify-center items-center w-full max-w-[800px]">
                        <video
                          controls
                          className="w-full h-auto max-h-[90vh] object-contain rounded-lg"
                        >
                          <source src={pdfData[0]?.pdflink} type="video/mp4" />
                        </video>
                      </div>
                    ) : documentType.toLowerCase() === "zip" && pdfSrc ? (
                      <ZipViewer
                        zipContent={zipContent}
                        zipSrc={pdfSrc}
                        base64ToBlob={base64ToBlob}
                      />
                    ) : isImage(pdfSrc) ? (
                      <img
                        src={pdfData[0]?.pdflink}
                        width="100%"
                        height="590"
                        alt={documentTitle}
                      />
                    ) : (
                      <div className="bg-[#ddeef6] border-l-4 border-[#44a0cf] text-white p-6 rounded-lg shadow-md max-w-md mx-auto flex flex-col justify-center items-center">
                        <span className="text-gray-800 text-base font-semibold mb-3 text-center">
                          This file is not supported for viewing here. You can
                          download it using the button below
                        </span>

                        <button
                          onClick={handleDocumentDownload}
                          className="bg-[#2b93c8d0] hover:bg-[#44a0cf] py-2 px-4 rounded-lg flex gap-1 justify-center items-center self-center transition-all duration-300"
                        >
                          <FaDownload />
                          Download
                        </button>
                      </div>
                    )
                  ) : null}
                </div>
                {document_table != "contract_requests" &&
                  document_table != "document_request_settings" &&
                  ///////////////////////////// Show only if `showPdfOnly` is false. /////////////////////////////
                  (showPdfOnly ? null : (
                    <div className="document-viewer-form">
                      <div className="field_wrapper">
                        <div className="field">
                          <Controller
                            control={control}
                            name="addClientName"
                            render={({
                              field: { onChange, value, ...field },
                            }) => (
                              <Autocomplete
                                className="clientNameAuto"
                                name="addClientName"
                                noOptionsText={
                                  !searchClientNotFound
                                    ? "Please enter the client name / alien number / case id / phone no / email"
                                    : "No clients found"
                                }
                                options={[
                                  ...clientNames,
                                  ...(total > offset + 20 || showMoreLoading
                                    ? [{ name: "Show More", id: "show_more" }]
                                    : []),
                                ]}
                                loading={searchLoading}
                                // getOptionLabel={(option) => option.name}
                                getOptionLabel={(option) => {
                                  // Check if option is an object and has a valid 'name' property
                                  if (
                                    typeof option === "object" &&
                                    option !== null &&
                                    typeof option.name === "string"
                                  ) {
                                    if (option.id === "show_more")
                                      return option.name;
                                    return option.name || "";
                                  }

                                  // Return a fallback value (could be an empty string or some placeholder text)
                                  return "";
                                }}
                                clearOnBlur={false}
                                disabled={
                                  (clientID &&
                                  clientID !== "null" &&
                                  !showLinkButton
                                    ? true
                                    : false) || !canEditClient
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label={
                                      !clientNameLabel
                                        ? "Client Name / Alien Number / Case Id / Phone No / Email"
                                        : clientNameLabel
                                    }
                                    InputProps={{
                                      ...params.InputProps,
                                    }}
                                    onChange={(event) => {
                                      if (!event.target.value) {
                                        setClientNameLabel(
                                          defaultClientNameLabel
                                            ? defaultClientNameLabel
                                            : "Client Name / Alien Number / Case Id / Phone No / Email"
                                        );
                                      }
                                      const searchTerm = event.target.value;
                                      handleClientNameSearch(searchTerm);
                                      // Store selectedClientName in component state or prop
                                      setSearchedTerm(searchTerm);
                                    }}
                                    onBlur={() => {
                                      if (!contactId) {
                                        setClientNameLabel(
                                          defaultClientNameLabel
                                            ? defaultClientNameLabel
                                            : "Client Name / Alien Number / Case Id / Phone No / Email"
                                        );
                                      }
                                    }}
                                    disabled={
                                      (clientID &&
                                        clientID !== "null" &&
                                        !showLinkButton) ||
                                      !canEditClient
                                    }
                                  />
                                )}
                                filterOptions={(options, state) => options}
                                renderOption={(props, option) => {
                                  if (option.id === "show_more") {
                                    return (
                                      <li
                                        {...props}
                                        onClick={async () => {
                                          setShowMoreLoading(true); // Set loading to true
                                          setOffset((prev) => prev + 20);
                                        }}
                                        style={{
                                          cursor: "pointer",
                                          color: showMoreLoading
                                            ? "grey"
                                            : "#007bff",
                                        }}
                                      >
                                        {showMoreLoading
                                          ? "Loading..."
                                          : option.name}
                                      </li>
                                    );
                                  }
                                  return (
                                    <li
                                      {...props}
                                      key={option.id}
                                      className={` p-3 border-b border-b-gray-300 hover:bg-gray-200 ${
                                        additionalDataLoading
                                          ? "pointer-events-none opacity-25"
                                          : ""
                                      }`}
                                    >
                                      <p>{option.name}</p>
                                      <div className="grid grid-cols-2 gap-1">
                                        <span className="font-semibold">
                                          Alien Number
                                        </span>
                                        <span className="break-words">
                                          : {option.im_alien_number_c}
                                        </span>

                                        {/* Additional Data Section */}
                                        {additionalDataFetched &&
                                          !searchLoading &&
                                          !additionalDataLoading && (
                                            <>
                                              <span className="font-semibold">
                                                Case Id
                                              </span>
                                              <span className="break-words ">
                                                : {option?.caseid_c}
                                              </span>
                                              <span className="font-semibold">
                                                Case Status
                                              </span>
                                              <span className="break-words ">
                                                : {option?.status}
                                              </span>

                                              <span className="font-semibold">
                                                Lead Attorney
                                              </span>
                                              <span className="break-words ">
                                                : {option?.Lead_Attorney}
                                              </span>

                                              <span className="font-semibold">
                                                Active Legal Assistant
                                              </span>
                                              <span className="break-words ">
                                                : {option?.Legal_Assistant}
                                              </span>

                                              <span className="font-semibold">
                                                Active Paralegal
                                              </span>
                                              <span className="break-words ">
                                                : {option?.Paralegal}
                                              </span>
                                              <span className="font-semibold">
                                                Phone
                                              </span>
                                              <span className="break-words ">
                                                : {option?.phone_mobile}
                                              </span>

                                              <span className="font-semibold">
                                                Email
                                              </span>
                                              <span className="break-words">
                                                :{" "}
                                                {option?.email?.[0]
                                                  ?.email_address || ""}
                                              </span>
                                            </>
                                          )}
                                      </div>

                                      {(searchLoading ||
                                        additionalDataLoading) && (
                                        <div className="absolute inset-0 bg-gray-50 opacity-10 flex justify-center items-center">
                                          <span className="text-gray-500 font-semibold">
                                            <Loading />
                                          </span>
                                        </div>
                                      )}
                                    </li>
                                  );
                                }}
                                {...field}
                                value={
                                  clientNames
                                    ? clientNames.find((client) =>
                                        client._module === "Contacts"
                                          ? client.case_id === field.value
                                          : client.id === field.value
                                      ) || null
                                    : null
                                }
                                onChange={(event, newValue) => {
                                  if (
                                    newValue === null ||
                                    newValue === undefined
                                  ) {
                                    onChange(null);
                                    setValue("caseId", "");
                                    setContactId("");
                                  } else if (newValue.id !== "show_more") {
                                    const selectedClient = clientNames?.find(
                                      (clientName) =>
                                        clientName.id === newValue?.id
                                    );
                                    setClientNameLabel(selectedClient.name);

                                    if (selectedClient) {
                                      let selectedId;

                                      // Check if the module is "Contacts" or "cpm_CaseParties"
                                      if (
                                        selectedClient._module === "Contacts" ||
                                        selectedClient._module ===
                                          "cpm_CaseParties"
                                      ) {
                                        selectedId =
                                          selectedClient._module === "Contacts"
                                            ? selectedClient.case_id
                                            : selectedClient.id;
                                        handleClientNameChange(
                                          selectedClient.case_id
                                        );
                                      } else {
                                        // For other modules (like "Cases"), use selectedClient.id
                                        selectedId = selectedClient.id;
                                        handleClientNameChange(
                                          selectedClient.id
                                        );
                                      }
                                      setBenefId(selectedClient.id);
                                      onChange(selectedId);
                                    }
                                  }
                                }}
                                classes={{ paper: "autocomplete-paper" }}
                              />
                            )}
                          />
                          <span className="error-message">
                            {errors["addClientName"]?.message}
                          </span>
                        </div>
                      </div>

                      <div className="field_wrapper">
                        <div className="field">
                          <Controller
                            name="caseId"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                              <InputUI
                                name="caseId"
                                label="Client Reference Number"
                                disabled
                                type="text"
                                value={value ? value : ""}
                                setValue={onChange}
                                variant="outlined"
                                register={register}
                                cssStyles={{
                                  width: "100%",
                                }}
                              />
                            )}
                          />
                        </div>
                      </div>
                      <div className="field_wrapper">
                        <div className="field">
                          <Controller
                            name="name"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                              <InputUI
                                name="name"
                                label="Document Name"
                                type="text"
                                value={value ? value : ""}
                                setValue={onChange}
                                variant="outlined"
                                register={register}
                                disabled={true}
                                cssStyles={{
                                  margin: "20px 0px",
                                  width: "100%",
                                }}
                              />
                            )}
                          />
                          <span className="error-message">
                            {errors["name"]?.message}
                          </span>
                        </div>
                      </div>
                      <div className="field_wrapper">
                        <div className="field">
                          <Controller
                            name="category"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                              <InputUI
                                name="category"
                                label="Category"
                                disabled
                                type="text"
                                value={documentCategory ? documentCategory : ""}
                                variant="outlined"
                                register={register}
                                cssStyles={{
                                  width: "100%",
                                }}
                              />
                            )}
                          />
                        </div>
                      </div>
                      <div
                        className="field_wrapper"
                        style={{ marginTop: "15px", marginBottom: "5px" }}
                      >
                        <FormControl className="" fullWidth>
                          <Controller
                            control={control}
                            name="subcategory"
                            render={({ field: { onChange, value } }) => (
                              <Autocomplete
                                options={subCategoryOption}
                                filterOptions={filterOptions}
                                getOptionLabel={(option) =>
                                  option.fields_name || ""
                                }
                                onChange={(e, value) => {
                                  onChange(value ? value.field_setting_id : "");
                                }}
                                value={
                                  subCategoryOption?.find(
                                    (option) =>
                                      option.field_setting_id === value
                                  ) || null
                                }
                                sx={{ margin: "10px 0px" }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Sub Category"
                                    variant="outlined"
                                  />
                                )}
                              />
                            )}
                          />
                        </FormControl>
                        {/* <FormControl fullWidth>
                          <InputLabel id="select-label">
                            Sub Category
                          </InputLabel>
                          <Controller
                            control={control}
                            name="subcategory"
                            // defaultValue={category}
                            render={({ field: { onChange, value } }) => (
                              <SelectBox
                                name="subcategory"
                                labelId="select-label"
                                variant="outlined"
                                id="simple-select"
                                value={value || ""}
                                onChange={onChange}
                                label="Sub Category"
                                {...register("subcategory")}
                              >
                                {subCategoryOption?.map((category) => (
                                  <MenuItem
                                    key={category.field_setting_id}
                                    value={category.field_setting_id}
                                  >
                                    {category.fields_name}
                                  </MenuItem>
                                ))}
                              </SelectBox>
                            )}
                          />
                        </FormControl> */}
                        <span className="error-message">
                          {errors["subcategory"]?.message}
                        </span>
                      </div>
                      <div className="field_wrapper">
                        <div className="field">
                          <TextField
                            name="description"
                            label="Description"
                            multiline
                            minRows={3}
                            maxRows={3}
                            value={
                              description && description !== null
                                ? description
                                : ""
                            }
                            onChange={(e) => setDescription(e.target.value)}
                            variant="outlined"
                            style={{ marginTop: "15px", marginBottom: "15px" }}
                            fullWidth
                          />
                        </div>
                      </div>
                      <OcrFields
                        control={control}
                        errors={errors}
                        setValue={setValue}
                        subCategory={documentSubCategory}
                        documentID={documentID}
                        showFields={showFields}
                        setShowFields={setShowFields}
                      />

                      <ButtonUI
                        loading={submitLoading}
                        variant="contained"
                        buttonText="Submit"
                        handleSubmit={handleSubmit}
                        onFormSubmit={onEditDocumentFormSubmit}
                      />
                      {showPopup && (
                        <ChatPopup
                          document_url={pdfBase64?.[0]?.pdflink}
                          onClose={() => setShowPopup(false)}
                          subCategory={documentSubCategory}
                          documentID={documentID}
                          ocrStatus={performOcr}
                          setPerformOcr={setPerformOcr}
                          setLoadingOcr={setLoadingOcr}
                          setExistingOcr={setExistingOcr}
                          setValue={setValue}
                          setShowFields={setShowFields}
                        />
                      )}
                    </div>
                  ))}
              </div>
              <div
                className={
                  isPdfDetailsContainerOpen
                    ? "pdf-details-container"
                    : "hide-pdf-details-container"
                }
              >
                <div className="pdf-details-header">
                  <div className="pdf-details-header-icon">
                    <IoDocumentText />
                  </div>
                  <div className="pdf-details-header-title">
                    Document Details
                  </div>
                  <div
                    className="close-pdf-details-container-icon"
                    onClick={changePdfDetailsContainerVisibility}
                  >
                    <RiCloseCircleFill />
                  </div>
                </div>
                <div className="pdf-details-content">
                  <PdfDetails
                    pdfData={pdfData}
                    documentID={documentID}
                    documentTitle={documentTitle}
                    documentType={documentType}
                    pdfDetailsVersionData={pdfDetailsVersionData}
                    documentVersionId={documentVersionId}
                    setDocumentVersionId={setDocumentVersionId}
                    // document_version={document_version}
                  />
                </div>
              </div>
            </div>
          ) : !pdfDataLoading ? (
            <div className="show-pdf-editor">
              <div
                onClick={() => setEditor(!editor)}
                className="go-back-to-pdf-viewer"
                title="Back"
              >
                <IoArrowBackCircleSharp />
              </div>
              <div>
                {editor && (
                  <PdfEditor
                    pdfBase64={pdfBase64}
                    documentID={documentID}
                    documentTitle={documentTitle}
                    documentURL={documentURL}
                    setEditor={setEditor}
                    pdfDetailsData={pdfDetailsData}
                    pdfBlob={pdfBlob}
                  />
                )}
              </div>
            </div>
          ) : (
            <Loading />
          )}
        </div>
      }
    </>
  );
};

export default PdfViewer;
