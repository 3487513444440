import baseUrl from "../config/baseUrl";

const addRole = async ({
  token,
  name,
  permissions,
  status,
}) => {
  const formData = new FormData();
  formData.append("name", name);
  formData.append("permissions[]", permissions);
  formData.append("status", status);

  try {
    const response = await fetch(`${baseUrl}/api/add-roles`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });
    const data = await response.json();
    return data;
  } catch (error) {
    return error;
  }
};

export default addRole;
