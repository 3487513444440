import baseUrl, { sugarUrl } from "../config/baseUrl";

const getTemplateDescription = async ({
  token,
  cm_name,
  template_id,
  client_id,
}) => {
  try {
    const response = await fetch(
      `${baseUrl}/api/get-message-templates-descriptions?cm_name=${cm_name}&sms_template_id${template_id}&cm_id=${client_id}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        // body: JSON.stringify({
        //   cm_name,
        //   sms_template_id: template_id,
        //   cm_id: client_id,
        // }),
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching template description:", error);
    return error;
  }
};

export default getTemplateDescription;
