import React, { useState, useContext } from "react";
import { toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { MdModeEdit } from "react-icons/md";
import { RiCloseCircleFill } from "react-icons/ri";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import EditRoleForm from "./EditRoleForm";
import ButtonUI from "../material-ui/ButtonUI";
import { AuthContext } from "../../context/Auth/AuthContext";
import editRole from "../../api/editRole";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm":
    {
      minWidth: "1000px",
    },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <RiCloseCircleFill />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const editRoleFormSchema = yup.object().shape({
  name: yup.string().required("First Name is required field"),
  permissions: yup
    .array()
    .of(yup.string().required("Each permission must be selected"))
    .min(1, "Please select at least one permission")
    .required("Please select at least one permission"),
  status: yup.string().required("Status is required field"),
});

const EditRolePopup = ({ setRoleAddedToTrue, tableValues }) => {
  const [open, setOpen] = useState(false);
  const { token, user_id } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(editRoleFormSchema),
    defaultValues: {
      permissions: [], // Initialize permissions as an empty array
    },
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Submits updated role data, updates the UI on success, and handles loading and errors.
  const onEditRoleFormSubmit = async (editRoleFormData) => {
    // console.log(editRoleFormData);
    setLoading(true);
    const editRoleFormResponse = await editRole({
      token: token,
      role_id: tableValues.role_id,
      name: editRoleFormData.name,
      permissions: editRoleFormData.permissions,
      status: editRoleFormData.status,
    });

    if (editRoleFormResponse.code === 200) {
      setRoleAddedToTrue();
      setOpen(false);
      toast.success("Role is updated successfully", {
        position: "top-center",
        autoClose: 3000,
        theme: "dark",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.error("Error while updating role", {
        position: "top-center",
        autoClose: 5000,
        theme: "dark",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    setLoading(false);
  };

  return (
    <div className="dialog-modal">
      <ButtonUI
        formButton={false}
        variant="outlined"
        buttonText={<MdModeEdit />}
        style={{
          backgroundColor: "#17a2b8",
          color: " white",
          marginRight: "5px",
          padding: "8px",
          minWidth: "fit-content",
          border: "none",
        }}
        onClickFunc={handleClickOpen}
      />
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        className="custom-edit-modal"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          className="edit-doc-modal"
          onClose={handleClose}
        >
          <div className="popup-header-svg-icon">
            <MdModeEdit />
          </div>
          <div className="popup-header-title">Edit Role</div>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <EditRoleForm
            register={register}
            control={control}
            setValue={setValue}
            watch={watch}
            errors={errors}
            tableValues={tableValues}
          />
        </DialogContent>
        <DialogActions sx={{ paddingBottom: "15px !important" }}>
          <div className="popup_submit_btn">
            <ButtonUI
              loading={loading}
              variant="contained"
              buttonText="Submit"
              handleSubmit={handleSubmit}
              onFormSubmit={onEditRoleFormSubmit}
            />
          </div>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default EditRolePopup;
