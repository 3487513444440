import { Skeleton } from "../../shadcn-components/ui/skeleton";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../shadcn-components/ui/card";
import { Link } from "react-router-dom";
import { MdOutlineDocumentScanner } from "react-icons/md";
import { BiMessageSquareDetail } from "react-icons/bi";
import { HiOutlineClipboardList, HiOutlineMail } from "react-icons/hi";
import { TbChecklist } from "react-icons/tb";
import { useEffect, useState } from "react";
import dashboardDocumentCount from "../../api/dashboardDocumentCount";
import { useRoleContext } from "../../context/RolesContext";

function DocCountCard({ token, customBoxShadow, is_admin }) {
  const [countLoading, setCountLoading] = useState(true);
  const [documentCount, setDocumentCount] = useState({});
  const { permissions } = useRoleContext();

  const documentCountData = async () => {
    const response = await dashboardDocumentCount({ token });

    if (response.code === 200) {
      setDocumentCount(response);
    }
  };

  // Function to check if the user has a specific permission.
  // Admin users ("is_admin" === "1") automatically have access to all permissions.
  const hasPermission = (permission) => {
    if (is_admin === "1") {
      return true;
    }
    return permissions.includes(permission);
  };
  const docCards = [
    {
      linkTo: "/assigned-case-list",
      cardTitle: "All Assigned",
      icon: <TbChecklist />,
      docCount: documentCount.getAssigned,
      permission: "view_all_assigned",
    },
    {
      linkTo: "/unassigned-case-list",
      cardTitle: "All Unassigned",
      icon: <HiOutlineClipboardList />,
      docCount: documentCount.allUnassigned,
      permission: "view_all_unassigned",
    },
    {
      linkTo: "/my-assigned-case-list",
      cardTitle: "My Assigned",
      icon: <TbChecklist />,
      docCount: documentCount.myAssigned,
      permission: "view_my_assigned",
    },
    {
      linkTo: "/email-case-list",
      cardTitle: "Incoming Emails",
      icon: <HiOutlineMail />,
      docCount: documentCount.email_count,
      permission: "view_incoming_emails",
    },
    {
      linkTo: "/fax-case-list",
      cardTitle: "Incoming Faxes",
      icon: <MdOutlineDocumentScanner />,
      docCount: documentCount.fax_count,
      permission: "view_incoming_faxes",
    },
    {
      linkTo: "/scan-case-list",
      cardTitle: "Scanned Files",
      icon: <BiMessageSquareDetail />,
      docCount: documentCount.scanned_count,
      permission: "view_scanned_files",
    },
  ];

  // Permission flags for various user actions, granting access based on user roles or admin status.
  // Admin users have all permissions by default.
  const updatedDocCards = docCards.map((card) => ({
    ...card,
    linkTo: hasPermission(card.permission) ? card.linkTo : null,
  }));

  useEffect(() => {
    async function fetchData() {
      await documentCountData();
      setCountLoading(false);
    }
    fetchData();
  }, []);
  return (
    <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3 w-full">
      {updatedDocCards.map(({ linkTo, cardTitle, icon, docCount }) =>
        linkTo ? (
          <Link to={linkTo} key={cardTitle} className="w-full">
            <Card className="w-full hover:bg-[#e2efff]" style={customBoxShadow}>
              <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                <CardTitle className="text-sm font-medium letterSpacing">
                  {cardTitle}
                </CardTitle>
                {icon}
              </CardHeader>
              <CardContent>
                <div className="text-2xl font-semibold">
                  {countLoading ? <Skeleton className="h-8 w-1/2" /> : docCount}
                </div>
              </CardContent>
            </Card>
          </Link>
        ) : (
          <Card
            className="w-full bg-gray-100 cursor-not-allowed"
            style={customBoxShadow}
          >
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
              <CardTitle className="text-sm font-medium letterSpacing">
                {cardTitle}
              </CardTitle>
              {icon}
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-semibold">
                {countLoading ? <Skeleton className="h-8 w-1/2" /> : docCount}
              </div>
            </CardContent>
          </Card>
        )
      )}
    </div>
  );
}

export default DocCountCard;
