import React, { useEffect, useState, useContext } from "react";
import { IoInformationCircleSharp } from "react-icons/io5";
import { AuthContext } from "../../context/Auth/AuthContext";
import baseUrl from "../../config/baseUrl";
import Loading from "../material-ui/Loading";

function BusinessCardInfo({ clientID }) {
  const [hovered, setHovered] = useState(false);
  const [clientData, setClientData] = useState(null); // <-- for storing fetched data
  const { token } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const fetchCurrentClientData = async (clientId) => {
    try {
      setLoading(true);
      let fetchedData = {};

      const response = await fetch(
        `${baseUrl}/api/getCaseStatus?caseId=${clientId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();

      if (!response.ok) {
        setClientData(null);
      }

      if (data?.caseDetails?.[0]?.caseid_c) {
        const { status, Lead_Attorney, Legal_Assistant, Paralegal } =
          data.caseDetails[0];

        fetchedData = {
          status,
          Lead_Attorney,
          Legal_Assistant,
          Paralegal,
        };

        try {
          const url = `${baseUrl}/api/customGlobalSearch?q=${data.caseDetails[0].caseid_c}&max_num=20&offset=0&highlights=true&module_list=Cases,cpm_CaseParties`;

          const response = await fetch(url, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          const dataES = await response.json();

          if (dataES?.records && Array.isArray(dataES.records)) {
            dataES.records.forEach((record) => {
              Object.entries(record).forEach(([key, value]) => {
                if (!(key in fetchedData)) {
                  fetchedData[key] = value;
                } else {
                  let i = 1;
                  let newKey = `${key}_${i}`;
                  while (newKey in fetchedData) {
                    i++;
                    newKey = `${key}_${i}`;
                  }
                  fetchedData[newKey] = value;
                }
              });
            });
          }
        } catch (error) {
          console.error("Error during second API fetch:", error);
        }

        // ✅ Update state with final merged data
        setClientData(fetchedData);
      }
    } catch (error) {
      console.error("Error fetching client data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (clientID && clientID !== "null") {
      fetchCurrentClientData(clientID);
    }
  }, [clientID]);

  useEffect(() => {
    if (!clientID) {
      setClientData(null);
    }
  }, [clientID]);

  return (
    <div
      style={{
        position: "relative",
        display: `${!clientID ? "none" : "inline-block"} `,
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <IoInformationCircleSharp
        style={{
          backgroundColor: "#3792ed",
          color: "white",
          minWidth: "fit-content",
          border: "none",
          height: "25px",
          width: "25px",
          borderRadius: "50%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "20px",
          position: "relative",
          top: "10px",
          cursor: "pointer",
        }}
        className="transition-transform duration-200 ease-in-out hover:scale-110"
      />

      {hovered && (
        <div
          style={{
            position: "absolute",
            top: "50px",
            left: "50%",
            transform: "translateX(-50%)",
            backgroundColor: "#fff",
            padding: "15px 25px",
            borderRadius: "10px",
            boxShadow: "0 10px 20px rgba(0, 0, 0, 0.15)",
            border: "2px solid #3792ed",
            width: "400px",
            fontSize: "14px",
            color: "#333",
            textAlign: "left",
            zIndex: "999",
          }}
          className="w-[90%] md:w-[400px] text-sm shadow-lg transition-all duration-200 ease-in-out"
        >
          {loading ? (
            <div className="flex justify-center items-center h-[250px]">
              <Loading />
            </div>
          ) : (
            <div className="grid grid-cols-[auto,1fr] gap-y-2 gap-x-3">
              <span className="font-semibold text-gray-700">Alien Number</span>
              <span className="text-gray-900">
                : {clientData?.im_alien_number_c || ""}
              </span>

              <span className="font-semibold text-gray-700">Case Id</span>
              <span className="text-gray-900">
                : {clientData?.caseid_c || ""}
              </span>

              <span className="font-semibold text-gray-700">Case Status</span>
              <span className="text-gray-900">
                : {clientData?.status || ""}
              </span>

              <span className="font-semibold text-gray-700">Lead Attorney</span>
              <span className="text-gray-900">
                : {clientData?.Lead_Attorney || ""}
              </span>

              <span className="font-semibold text-gray-700">
                Active Legal Assistant
              </span>
              <span className="text-gray-900">
                : {clientData?.Legal_Assistant || ""}
              </span>

              <span className="font-semibold text-gray-700">
                Active Paralegal
              </span>
              <span className="text-gray-900">
                : {clientData?.Paralegal || ""}
              </span>

              <span className="font-semibold text-gray-700">Phone</span>
              <span className="text-gray-900">
                : {clientData?.phone_mobile || ""}
              </span>

              <span className="font-semibold text-gray-700">Email</span>
              <span className="text-gray-900">
                : {clientData?.email?.[0]?.email_address || ""}
              </span>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default BusinessCardInfo;
