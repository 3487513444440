import { useState, useEffect } from "react";
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
} from "../../shadcn-components/ui/dialog";
import { Button } from "../../shadcn-components/ui/button";
import { Label } from "../../shadcn-components/ui/label";
import { Input } from "../../shadcn-components/ui/input";
import { ArrowRight, ArrowLeft, LoaderCircle } from "lucide-react";
import { z } from "zod";

const imageSchema = z
	.instanceof(File)
	.refine((file) => file.type.startsWith("image/"), {
		message: "Only image files are allowed",
	});

export function UploadModal({
	open,
	onOpenChange,
	onVerify,
	loading,
	selectedClient,
}) {
	const [step, setStep] = useState(1);
	const [selfieFile, setSelfieFile] = useState(null);
	const [idFile, setIdFile] = useState(null);
	const [selfiePreview, setSelfiePreview] = useState(null);
	const [idPreview, setIdPreview] = useState(null);
	const [errors, setErrors] = useState({ selfie: "", id: "" });

	// Cleanup function when modal is closed
	useEffect(() => {
		if (!open) {
			// Cleanup object URLs on close
			if (selfiePreview) URL.revokeObjectURL(selfiePreview);
			if (idPreview) URL.revokeObjectURL(idPreview);
			setSelfiePreview(null);
			setIdPreview(null);
			setSelfieFile(null);
			setIdFile(null);
			setStep(1);
			setErrors({ selfie: "", id: "" });
		}
	}, [open]);

	const handleFileChange = (event, setFile, setPreview, field) => {
		const file = event.target.files?.[0];
		if (!file) return;

		const validationResult = imageSchema.safeParse(file);
		if (!validationResult.success) {
			setErrors((prev) => ({
				...prev,
				[field]: validationResult.error.errors[0].message,
			}));
			return;
		}

		setErrors((prev) => ({ ...prev, [field]: "" }));

		// **Revoke old URL before setting a new one**
		if (field === "selfie" && selfiePreview) {
			URL.revokeObjectURL(selfiePreview);
		}
		if (field === "id" && idPreview) {
			URL.revokeObjectURL(idPreview);
		}

		const previewUrl = URL.createObjectURL(file);
		setFile(file);
		setPreview(previewUrl);
	};

	const handleNext = () => setStep((prev) => prev + 1);
	const handleBack = () => setStep((prev) => prev - 1);

	const handleVerify = () => {
		if (!selfieFile || !idFile) return;
		onVerify(selfieFile, idFile);
	};

	return (
		<Dialog open={open} onOpenChange={onOpenChange}>
			<DialogContent className="sm:max-w-[50%]">
				<DialogHeader>
					<DialogTitle>
						{step === 1 && (
							<span className="flex items-center gap-1 text-muted-foreground">
								<span className="h-8 w-8 bg-slate-600 text-white rounded-full flex justify-center items-center">
									{step}
								</span>
								Upload Selfie
							</span>
						)}
						{step === 2 && (
							<span className="flex items-center gap-1 text-muted-foreground">
								<span className="h-8 w-8 bg-slate-600 text-white rounded-full flex justify-center items-center">
									{step}
								</span>
								Upload ID
							</span>
						)}
						{step === 3 && (
							<span className="flex items-center gap-1 text-muted-foreground">
								<span className="h-8 w-8 bg-slate-600 text-white rounded-full flex justify-center items-center">
									{step}
								</span>
								Review & Submit
							</span>
						)}
					</DialogTitle>
				</DialogHeader>

				<div className="space-y-6 py-4">
					{step === 1 && (
						<div>
							<Label htmlFor="selfie">Upload a Selfie</Label>
							<Input
								id="selfie"
								type="file"
								accept="image/*"
								onChange={(e) =>
									handleFileChange(e, setSelfieFile, setSelfiePreview, "selfie")
								}
							/>
							{errors.selfie && <p className="text-red-500">{errors.selfie}</p>}
							{selfiePreview && (
								<img
									src={selfiePreview}
									alt="Selfie preview"
									className="w-auto h-24 rounded-md border border-gray-300 object-cover"
								/>
							)}
						</div>
					)}

					{step === 2 && (
						<div>
							<Label htmlFor="id">Upload an ID Photo</Label>
							<Input
								id="id"
								type="file"
								accept="image/*"
								onChange={(e) =>
									handleFileChange(e, setIdFile, setIdPreview, "id")
								}
							/>
							{errors.id && <p className="text-red-500">{errors.id}</p>}
							{idPreview && (
								<img
									src={idPreview}
									alt="ID preview"
									className="w-auto h-24 rounded-md border border-gray-300 object-cover"
								/>
							)}
						</div>
					)}

					{step === 3 && (
						<div>
							<h3 className="text-lg font-semibold">Review Your Documents</h3>

							<div className="flex gap-4 mt-4">
								{/* Selfie Preview */}
								<div>
									<Label className="block">Selfie:</Label>
									{selfiePreview ? (
										<img
											src={selfiePreview}
											alt="Selfie preview"
											className="w-auto h-32 rounded-md border border-gray-300 object-cover"
										/>
									) : (
										<p className="text-sm text-gray-500">No selfie uploaded.</p>
									)}
								</div>

								{/* ID Preview */}
								<div>
									<Label className="block">ID:</Label>
									{idPreview ? (
										<img
											src={idPreview}
											alt="ID preview"
											className="w-auto h-32 rounded-md border border-gray-300 object-cover"
										/>
									) : (
										<p className="text-sm text-gray-500">No ID uploaded.</p>
									)}
								</div>
							</div>
						</div>
					)}

					<div className="flex justify-between">
						{step > 1 && (
							<Button onClick={handleBack} className="bg-slate-600 text-white">
								<ArrowLeft className="mr-2" /> Back
							</Button>
						)}
						{step < 3 ? (
							<Button
								onClick={handleNext}
								disabled={step === 1 ? !selfieFile : !idFile}
							>
								Next <ArrowRight className="ml-2" />
							</Button>
						) : (
							<Button
								onClick={handleVerify}
								disabled={!selfieFile || !idFile || loading}
							>
								{loading ? (
									<span className="flex items-center justify-center gap-2">
										Verifying <LoaderCircle className="animate-spin" />
									</span>
								) : (
									<span>Verify</span>
								)}
							</Button>
						)}
					</div>
				</div>
			</DialogContent>
		</Dialog>
	);
}
