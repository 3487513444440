import baseUrl, { sugarUrl } from "../config/baseUrl";

const getAllTaskTypeList = async ({ token }) => {
  try {
    const response = await fetch(
      `${baseUrl}/api/get-task-type-list?category=Immigration`,
      {
        method: "GET",
        headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        // body: JSON.stringify({ category: "Immigration" }),
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    return error;
  }
};

export default getAllTaskTypeList;
