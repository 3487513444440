import ButtonUI from "../material-ui/ButtonUI";

const ConfirmToast = ({ message, onConfirm, onCancel }) => {
  return (
    <div className="flex flex-col gap-4">
      <p className="text-[#007bff] font-medium">{message}</p>
      <div className="flex justify-end gap-3">
        <ButtonUI
          formButton={false}
          variant="outlined"
          buttonText="Yes"
          style={{
            backgroundColor: "#fff",
            color: "#007bff",
            border: "1px solid #007bff",
          }}
          autoFocus={true}
          onClickFunc={onConfirm}
        />
        <ButtonUI
          formButton={false}
          variant="outlined"
          buttonText="No"
          style={{
            backgroundColor: "#fff",
            color: "#007bff",
            border: "1px solid #007bff",
          }}
          autoFocus={true}
          onClickFunc={onCancel}
        />
      </div>
    </div>
  );
};

export default ConfirmToast;
