import { ChevronRightIcon, ChevronLeftIcon } from "@radix-ui/react-icons";
import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "../../shadcn-components/ui/pagination";
import { Button } from "../../shadcn-components/ui/button";

const PaginationSection = ({ setCurrentPage, totalPages, currentPage }) => {
  const renderPageNumbers = () => {
    const pageNumbers = [];
    const totalNumbers = 5;
    const totalBlocks = totalNumbers + 2;

    if (totalPages > totalBlocks) {
      const startPage = Math.max(2, currentPage - 2);
      const endPage = Math.min(totalPages - 1, currentPage + 2);
      let pages = range(startPage, endPage);

      if (currentPage > 3) {
        pages = ["...", ...pages];
      }
      if (currentPage + 2 < totalPages - 1) {
        pages = [...pages, "..."];
      }

      pageNumbers.push(1, ...pages, totalPages);
    } else {
      pageNumbers.push(...range(1, totalPages));
    }

    return pageNumbers;
  };

  const range = (start, end) => {
    return Array(end - start + 1)
      .fill()
      .map((_, idx) => start + idx);
  };

  return (
    <Pagination>
      <PaginationContent>
        <PaginationItem>
          <Button
            variant="outline"
            size="icon"
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === 1 || totalPages <= 1}
            className="disabled:cursor-not-allowed"
          >
            <ChevronLeftIcon className="h-4 w-4" />
          </Button>
        </PaginationItem>
        {renderPageNumbers().map((page, index) => (
          <PaginationItem key={index} className="px-1">
            {page === "..." ? (
              <PaginationEllipsis>...</PaginationEllipsis>
            ) : (
              <PaginationLink
                href="#"
                isActive={page === currentPage}
                onClick={() => setCurrentPage(page)}
              >
                {page}
              </PaginationLink>
            )}
          </PaginationItem>
        ))}
        <PaginationItem>
          <Button
            variant="outline"
            size="icon"
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={currentPage === totalPages || totalPages <= 1}
            className="disabled:cursor-not-allowed"
          >
            <ChevronRightIcon className="h-4 w-4" />
          </Button>
        </PaginationItem>
      </PaginationContent>
    </Pagination>
  );
};

export default PaginationSection;
