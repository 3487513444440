//(components/all-case-list/case-table/AllDocumentsList.jsx)

import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
import { useForm, Controller, clearErrors } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import {
  CaretSortIcon,
  ChevronDownIcon,
  MixerHorizontalIcon,
} from "@radix-ui/react-icons";
import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../shadcn-components/ui/select";

import { Button } from "../../../shadcn-components/ui/button";
import { Checkbox } from "../../../shadcn-components/ui/checkbox";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../../shadcn-components/ui/dropdown-menu";
import { Input } from "../../../shadcn-components/ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../shadcn-components/ui/table";
import TableSkeleton from "../../common/TableSkeleton";
import PaginationSection from "../../common/PaginationSection";
import DataToolbar from "../../common/DataToolbar";

import { AuthContext } from "../../../context/Auth/AuthContext";
import baseUrl, { sugarUrl } from "../../../config/baseUrl";
import "../../../styles/Clients.css";
import "../../../styles/DocumentTable.css";
import { fileIcon } from "../../common/FileIcons";
import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "../../../shadcn-components/ui/pagination";

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../../shadcn-components/ui/tooltip";

import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import SelectBox from "@mui/material/Select";
import { RiCloseCircleFill } from "react-icons/ri";
import { TbEdit } from "react-icons/tb";
import {
  IoArrowBackCircleSharp,
  IoCloseSharp,
  IoDocumentText,
} from "react-icons/io5";
import requestedDocumentList from "../../../api/requestedDocumentList";
import editDocument from "../../../api/editDocument";
import Loading from "../../material-ui/Loading";
import PdfEditor from "../../pdf-viewer/PdfEditor";
import ButtonUI from "../../material-ui/ButtonUI";
import "../../../styles/CaseDocuments.css";
import pdfBase64Data from "../../../api/pdfBase64Data";
import getAllFieldTypeList from "../../../api/getAllFieldTypeList";
import CopyLinkPopup from "../../all-case-list/case-table/CopyLinkPopup";
import DocumentViewer from "../../all-case-list/case-table/DocumentViewer";
import DeleteDocumentPopup from "../../all-case-list/case-table/DeleteDocumentPopup";
import EditDocumentPopup from "../../all-case-list/case-table/EditDocumentPopup";
import viewDocumentLink from "../../../api/viewDocumentLink";
import { toast } from "react-toastify";
import getAllAssignUsers from "../../../api/getAllAssignUsers";
import AssignUserPopup from "../../email-case-list/case-list-table/AssignUserPopup";
import BulkDeletePopup from "../../document-table/BulkDeletePopup";
import LinkToRequest from "../../document-table/LinkToRequest";
import AddTask from "../../document-table/AddTask";
import ApprovePDFDocumentPopup from "../../client-table/client-documents/ApprovePDFDocumentPopup";
import RejectPDFDocumentPopup from "../../client-table/client-documents/RejectPDFDocumentPopup";
import { format } from "date-fns";
import editClient from "../../../api/editClient";
import clientsData from "../../../api/clients";
import getClientRefNo from "../../../api/getClientRefNo";
import { TbCircleCheck } from "react-icons/tb";
import { TiDeleteOutline } from "react-icons/ti";
import { BiDetail, BiLinkAlt } from "react-icons/bi";
import InputUI from "../../material-ui/InputUI";
import useDebounceSearch from "../../../hooks/useDebounceSearch";
import getClientName from "../../../api/getClientName";
import getClientPhone from "../../../api/getClientPhone";
import { useDebounce } from "use-debounce";
import elasticToken from "../../../config/elasticToken";
import SendSMS from "../../pdf-viewer/SendSMS";
import { FaDownload } from "react-icons/fa";
import pdfDetails from "../../../api/pdfDetails";
import PdfDetails from "../../pdf-viewer/PdfDetails";
import ZipViewer from "../../pdf-viewer/ZipViewer";
import markClientUnknown from "../../../api/markClientUnknown";
import { useRoleContext } from "../../../context/RolesContext";
import { convertToUTC, formatDate } from "../../../utils/formatDate";
import BusinessCardInfo from "../../common/BusinessCardInfo";
import ConfirmToast from "../../common/ConfirmToast";

const updateDocumentDetailsFormSchema = yup.object().shape({
  name: yup.string().required("Document Name is required field"),
  subcategory: yup
    .string()
    .nullable()
    .required("Sub Category is required field"),
});

/*** Component for listing all the mailroom documents inside the datewise folder for all mailroom sub sections ***/
const AllDocumentsList = ({
  caseList,
  sourceDetails,
  createdAt,
  categoryName,
  folderName,
  assigned,
  assigned_user_id,
  mainCaseList,
}) => {
  const [sorting, setSorting] = useState([]);
  const [columnFilters, setColumnFilters] = useState([]);
  const [columnVisibility, setColumnVisibility] = useState({});
  const [rowSelection, setRowSelection] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [totalPages, setTotalPages] = useState(1);
  const [globalFilter, setGlobalFilter] = useState("");
  const [debouncedGlobalFilter] = useDebounce(globalFilter, 1000);
  const [loading, setLoading] = useState(true);
  const [docsCount, setDocsCount] = useState(0);
  const [reqStatus, setReqStatus] = useState("");
  const [requestId, setRequestId] = useState("");
  const [userList, setUserList] = useState("");
  const [documentUpdated, setDocumentUpdated] = useState(0);
  const [documentDeleted, setDocumentDeleted] = useState(0);
  const [documentViewer, setDocumentViewer] = useState(false);
  const [documentId, setDocumentId] = useState("");
  const [pdfLink, setPdfLink] = useState("");
  const [pdfBase64, setPdfBase64] = useState("");
  const [pdfEditor, setPdfEditor] = useState(false);
  const [docFileType, setDocFileType] = useState("");
  const [pdfViewerTitle, setPdfViewerTitle] = useState("");

  const [contactId, setContactId] = useState("");
  const [defaultContactId, setDefaultContactId] = useState("");
  const [benefId, setBenefId] = useState("");
  const [clientRefNo, setClientRefNo] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [description, setDescription] = useState("");

  const [bulkAssign, setBulkAssign] = useState(false);
  const [bulkAssignIds, setBulkAssignIds] = useState(null);
  const [bulkDeleteDocument, setBulkDeleteDocument] = useState(false);
  const [documentResponse, setDocumentResponse] = useState([]);
  const [bulkDeleteDocumentIds, setBulkDeleteDocumentIds] = useState();
  const [subCategoryOption, setSubCategoryOption] = useState([]);
  const [showFilterButton, setShowFilterButton] = useState(false);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [clientNames, setClientNames] = useState([]);

  const [requestOptions, setRequestOptions] = useState([]);
  const [linkRequest, setLinkRequest] = useState(false);
  const [linkRequestOpen, setLinkRequestOpen] = useState(false);
  const [approveConfirmOpen, setApproveConfirmOpen] = useState(false);
  const [disapproveConfirmOpen, setDisapproveConfirmOpen] = useState(false);
  const [showLinkButton, setShowLinkButton] = useState(false);
  const [assignClient, setAssignClient] = useState(false);
  const [existedContactId, setExistedContactId] = useState("");
  const [clientNameLabel, setClientNameLabel] = useState("");
  const [defaultClientNameLabel, setDefaultClientNameLabel] = useState("");
  const [selectedRequestLabel, setSelectedRequestLabel] = useState("");
  const [searchClientNotFound, setSearchClientNotFound] = useState(false);
  const [searchedTerm, setSearchedTerm] = useState("");
  const [clientPhone, setClientPhone] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [total, setTotal] = useState(0);
  const [showMoreLoading, setShowMoreLoading] = useState(false);
  const [searchedClientId, setSearchedClientId] = useState([]);
  const [additionalDataFetched, setAdditionalDataFetched] = useState(false);
  const [additionalDataLoading, setAdditionalDataLoading] = useState(false);
  const [isClientListFetched, setIsClientListFetched] = useState(false);
  const [data, setData] = useState([]);
  const [docData, setDocData] = useState({});
  const [pdfData, setPdfData] = useState([]);
  const [isPdfDetailsContainerOpen, setIsPdfDetailsContainerOpen] =
    useState(false);
  const [pdfDataLoading, setPdfDataLoading] = useState(true);
  const [documentVersionId, setDocumentVersionId] = useState(null);
  const [zipContent, setZipContent] = useState([]);
  const { token, is_admin, user_id } = useContext(AuthContext);
  const [businessCardInfoClientID, setBusinessCardInfoClientID] = useState("");
  // For calendars
  const [created_At, setCreated_At] = useState(null);
  const [updatedAt, setUpdatedAt] = useState(null);

  const prevFilterParamsRef = useRef({});
  const prevStateRef = useRef({
    updatedAt,
    debouncedGlobalFilter,
    columnFilters,
  });

  const [docResLoading, setDocResLoading] = useState(true);

  // Role based permissions
  // Function to check if the user has a specific permission.
  // Admin users ("is_admin" === "1") automatically have access to all permissions.
  const { permissions } = useRoleContext();
  const hasPermission = (permission) => {
    if (is_admin === "1") {
      return true;
    }

    return permissions.includes(permission);
  };

  // Permission flags for various user actions, granting access based on user roles or admin status.
  // Admin users have all permissions by default.
  const canCopy = hasPermission("copy_all_documents");
  const canEdit = hasPermission("edit_all_documents");
  const canView = hasPermission("view_all_documents");
  const canDelete = hasPermission("delete_all_documents");
  const canEditPdf = hasPermission("edit_pdf_documents");
  const canLinkDocument = hasPermission("link_documents");
  const canApproveDocument = hasPermission("document_approval");
  const canEditClient = hasPermission("client_edit");
  const canMarkClientUnknown = hasPermission("mark_client_unknown");
  const canPerformOCR = hasPermission("perform_ocr");
  const canAddTask = hasPermission("add_task");
  const canSendSMS = hasPermission("send_sms");

  const shouldShowActionColumn = canCopy || canEdit || canView || canDelete;

  // Handles marking a client as unknown by calling the appropriate API.
  // Displays a success toast if the operation is successful, otherwise shows an error message.
  // Errors during the process are logged to the console.
  const handleClientUnknown = async function () {
    const toastId = toast(
      ({ closeToast }) => (
        <ConfirmToast
          message="Are you sure you want mark the client as unknown ?"
          onConfirm={async () => {
            toast.dismiss(toastId);

            try {
              const res = await markClientUnknown({
                documentID: documentId,
                token,
              });
              if (res.code === 200) {
                setDocData((data) => ({
                  ...data,
                  client_unknown: "1",
                }));

                toast.success("Client Marked as Unknown", {
                  position: "top-center",
                  autoClose: 3000,
                  theme: "dark",
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              } else {
                toast.error("Error while setting client as unknown", {
                  position: "top-center",
                  autoClose: 5000,
                  theme: "dark",
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }
            } catch (error) {
              console.error(error);
              toast.error("An unexpected error occurred", {
                position: "top-center",
                autoClose: 5000,
                theme: "dark",
              });
            }
          }}
          onCancel={() => {
            toast.dismiss(toastId);
          }}
        />
      ),
      {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        className: "!border-[2px] !border-[#007bffbf] !rounded-sm",
      }
    );
  };

  const caseListUrls = {
    email: "/api/get-email-filter-name",
    scanned: "/api/get-scan-filter-name",
    fax: "/api/get-fax-filter-name",
  };

  useEffect(() => {
    setIsClientListFetched(false);
    if (clientNames.length > 0) {
      setSearchedClientId(
        clientNames.map((client) => {
          if (
            client._module === "Contacts" ||
            client._module === "cpm_CaseParties"
          ) {
            return client.case_id; // Use case_id for Contacts
          }
          return client.id; // Use id for other modules
        })
      );
      setIsClientListFetched(true);
    }
  }, [clientNames]);

  const clientIdsString = searchedClientId.join(",");

  // Fetches and updates client data (case status and legal team) for the provided client IDs.
  const fetchAdditionalClientData = async () => {
    setAdditionalDataLoading(true);

    if (!isClientListFetched) {
      console.error("No client IDs found to fetch data.");
      setAdditionalDataLoading(false);
      return;
    }

    try {
      const fetchedData = [];

      const response = await fetch(
        `${baseUrl}/api/getCaseStatus?caseId=${clientIdsString}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = await response.json();

      if (data?.caseDetails) {
        data.caseDetails.forEach((caseDetail) => {
          const { status, Lead_Attorney, Legal_Assistant, Paralegal } =
            caseDetail;

          fetchedData.push({
            status,
            Lead_Attorney,
            Legal_Assistant,
            Paralegal,
          });
        });
      }

      setClientNames((prev) =>
        prev.map((client, index) => ({
          ...client,
          ...(fetchedData[index] || {}),
        }))
      );

      setAdditionalDataLoading(false);
      setAdditionalDataFetched(true);
    } catch (error) {
      setAdditionalDataLoading(false);
      console.error("Error fetching additional client data:", error);
    }
  };

  // Fetches and updates the client list based on the search term and offset.
  const getClientList = useCallback(
    async (searchTerm, offsetValue, contactId) => {
      if (offsetValue === 0 && searchedTerm === "") {
        setClientNames([]);
      }
      if (searchTerm) {
        setClientNameLabel("Client Name");
        setSearchLoading(true);
        try {
          const url = `${baseUrl}/api/customGlobalSearch?q=${searchTerm}&max_num=20&offset=${offsetValue}&highlights=true&module_list=Cases,cpm_CaseParties`;
          const response = await fetch(url, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          const data = await response.json();

          if (data?.total > 0) {
            setClientNames((prev) => [...prev, ...data.records]);
            setTotal(data.total);
            setShowMoreLoading(false);
          } else {
            setSearchClientNotFound(true);
          }
        } catch (error) {
          console.error("Error:", error);
        } finally {
          // Ensure loading states are reset regardless of success or failure
          setSearchLoading(false);
        }
        // const url = `${baseUrl}/api/get-all-client-data?isAdmin=${is_admin}&userId=${user_id}&client_name=${searchTerm}`;
        // const res = await clientsData({ token, api: url });
        // console.log(res);
        // if (res.code === 200) {
        //   setClientNames(res?.data[0]?.clients);
        // } else {
        //   setSearchClientNotFound(true);
        // }
      } else {
        if (!contactId) {
          setClientNameLabel("");
        }
      }
    },
    []
  );
  const [searchTerm, handleClientNameSearch] = useDebounceSearch(
    "",
    documentId,
    offset,
    contactId,
    2000,
    getClientList
  );

  // Fetches additional client data when the client list is fetched or client IDs change.
  useEffect(() => {
    if (isClientListFetched || clientIdsString) {
      fetchAdditionalClientData();
    }
  }, [isClientListFetched, clientIdsString]);

  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    getValues,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(updateDocumentDetailsFormSchema),
  });

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    ignoreCase: true, // Ignore case sensitivity
    stringify: (option) => option.fields_name,
  });

  let sourceDetailsQuery =
    sourceDetails !== "" && sourceDetails !== null
      ? `&document_source_details=${sourceDetails}`
      : "";

  let createdAtQuery =
    createdAt && createdAt !== "" && createdAt !== null
      ? `&created_at=${format(new Date(createdAt), "yyyy-MM-dd")}`
      : "";

  // console.log(formatDate(createdAt));
  let assignedQuery = "";
  if (
    assigned &&
    assigned_user_id &&
    assigned_user_id !== "" &&
    assigned_user_id !== null
  ) {
    assignedQuery = `&assign_user_id=${assigned_user_id}`;
  }

  const setDocumentUpdatedToTrue = () => {
    setDocumentUpdated((count) => count + 1);
  };

  const setDocumentDeletedToTrue = () => {
    setDocumentDeleted((count) => count + 1);
  };

  const changeActiveTab = () => {
    setDocumentViewer(false);
    setPdfEditor(false);
  };

  const getClientNameDetails = async (clientId) => {
    const clientNameResponse = await getClientName({
      contact_id: clientId,
      token: token,
    });
    setClientNameLabel(clientNameResponse?.data?.name);
    setDefaultClientNameLabel(clientNameResponse?.data?.name);
  };

  const getRequestNameDetails = async (reqId) => {
    const url = `${baseUrl}/api/get-request-name?document_request_id=${reqId}`;
    const res = await clientsData({ token, api: url });
    setSelectedRequestLabel(res.data[0].document_name);
  };

  const fetchUsers = async () => {
    try {
      const response = await getAllAssignUsers({
        token,
      });

      setUserList(response?.data);
    } catch (error) {
      return error;
    }
  };

  const changeDocumentViewerState = async (
    caseID,
    contactId,
    name,
    category,
    subCategory,
    desc,
    id,
    type,
    phone_number,
    benId
  ) => {
    setTotal(0);
    setClientPhone(phone_number);
    setAssignClient(false);
    setApproveConfirmOpen(false);
    setDisapproveConfirmOpen(false);
    setLoading(true);
    const response = await viewDocumentLink({
      token: token,
      docId: id,
    });

    const data = await response?.data;
    setData(data);

    if (response?.code === 400) {
      toast.error(response?.message, {
        position: "top-center",
        autoClose: 5000,
        theme: "dark",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setLoading(false);
      throw new Error(response?.message);
    }

    const link = data[0]?.pdflink;
    setRequestId(data[0]?.document_request_id);
    setReqStatus(data[0]?.document_status);

    if (contactId) {
      setContactId(contactId);
      setDefaultContactId(contactId);
      setExistedContactId(contactId);
    } else {
      setContactId("");
      setExistedContactId("");
      setClientNameLabel("");
    }
    setValue("addClientName", contactId);

    if (benId) {
      setBenefId(benId);
      fetchRequestList(benId); // Call with benId if it exists
    } else {
      setBenefId(contactId);
      fetchRequestList(contactId); // Call with contact_id if benId is not available
    }
    // if (caseID) {
    // 	setClientRefNo(caseID);
    // } else {
    // 	setClientRefNo("");
    // }
    setSelectedRequestLabel("");
    if (data[0]?.document_request_id) {
      getRequestNameDetails(data[0]?.document_request_id);
    }

    setValue("caseId", caseID);
    setValue("name", name);
    setSubCategory(subCategory);
    setValue("subcategory", subCategory);
    setValue("category", category);
    setDescription(desc);
    setDocumentId(id);
    setPdfLink(link);
    setDocFileType(type);
    // if (type == "pdf") {
    const responseBase64Data = await pdfBase64Data(token, id, "documents");
    const base64Data = await responseBase64Data?.data;
    setPdfBase64(base64Data);
    // }
    setPdfViewerTitle(name);
    setDocumentViewer(true);
    if (benId) {
      getClientNameDetails(benId); // Call with benId if it exists
    } else {
      getClientNameDetails(contactId); // Call with contact_id if benId is not available
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!clientNameLabel) {
      setValue("addClientName", null);
      setValue("caseId", "");
    }
    if (clientNameLabel === defaultClientNameLabel) {
      setValue("addClientName", defaultContactId);
      if (defaultContactId && defaultContactId !== null) {
        handleClientNameChange(defaultContactId);
      }
    }
  }, [clientNameLabel]);

  const documentResponseFunc = async () => {
    try {
      setLoading(true);
      setDocResLoading(true);
      const offset = currentPage - 1;
      const filterParams = {};

      columnFilters.forEach((filterItem) => {
        filterParams[filterItem.id] = filterItem.value;
      });

      // Resets to page 1 if filters change and updates the previous filter reference.
      /////////////////////////////////////////
      const filtersChanged =
        JSON.stringify(prevFilterParamsRef.current) !==
        JSON.stringify(filterParams);

      if (filtersChanged) {
        setCurrentPage(1);
      }

      prevFilterParamsRef.current = filterParams;
      /////////////////////////////////////////

      const sortParam = sorting
        .map((sortItem) => {
          return `${sortItem.id}=${sortItem.desc ? "desc" : "asc"}`;
        })
        .join(",");

      const params = {
        isAdmin: is_admin,
        userId: user_id,
        document_source_type: caseList,
        limit: pageSize,
        offset: offset,
        ...filterParams,
        global_search: debouncedGlobalFilter,
        sort: sortParam,
        ...(updatedAt ? { updated_at: convertToUTC(updatedAt) } : {}),
      }; // Adds updated_at to params if they exist, converting them to UTC.

      const queryString = new URLSearchParams(params).toString();
      const url = `${baseUrl}/api/get-all-document-related-mailroom?${queryString}${sourceDetailsQuery}${createdAtQuery}${assignedQuery}`;

      const response = await requestedDocumentList({ token, api: url });

      // If the response is successful, update data and calculate total pages.
      if (response.code == 200) {
        setDocumentResponse(response?.data);
        setTotalPages(Math.ceil(response?.document_count / pageSize));
      } else {
        // If the response fails, reset data and total pages.
        setDocumentResponse([]);
        setTotalPages(0);
      }
    } catch (err) {
      console.error(`An error occured while fetching the data, ${err}`);
    } finally {
      setLoading(false);
      setDocResLoading(false);
    }
  };

  const fetchSubCategory = async () => {
    try {
      const response = await getAllFieldTypeList({
        token,
        type: "document_subtype",
      });
      setSubCategoryOption(response.data);
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    fetchSubCategory();
  }, []);

  const handleLinkRequest = (e) => {
    fetchRequestList(benefId);
    setLinkRequestOpen(true);
  };

  const handleApproveDocument = (e) => {
    setApproveConfirmOpen(true);
  };

  const handleDisapproveDocument = (e) => {
    setDisapproveConfirmOpen(true);
  };

  const handleBulkAction = async (actionType) => {
    const selectedRowsData = table
      .getFilteredSelectedRowModel()
      .rows.map((row) => row.original);

    const ids = selectedRowsData.map((row) => row.documentID).join(",");

    if (actionType === "delete") {
      setBulkDeleteDocument(true);
      setBulkDeleteDocumentIds(ids);
    } else {
      setBulkAssign(true);
      setBulkAssignIds(ids);
    }
  };

  // console.log(getValues("caseId"));
  const handleClientNameChange = async (contactId) => {
    const clientRefNoResponse = await getClientRefNo({
      contact_id: contactId,
      token: token,
    });
    //setClientRefNo(clientRefNoResponse?.data[0]?.client_reference_number);

    if (clientRefNoResponse.code === 200) {
      // setClientNameLabel("Client Name");
      setValue("caseId", clientRefNoResponse?.data[0]?.client_reference_number);

      setContactId(contactId);
    } else {
      toast.error(
        "Error while getting client details in DMS. Please contact admin!",
        {
          position: "top-center",
          autoClose: 5000,
          theme: "dark",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      setValue("caseId", "");
      setContactId("");
    }
  };

  const fetchRequestList = async (selectedClient) => {
    if (selectedClient) {
      setLinkRequest(false);
      //const url = `${baseUrl}/api/get-doc-request-data?userId=1&isAdmin=1&contact_id=${selectedClient}&unlinked=True`;
      const url = `${baseUrl}/api/get-client-document-requests-for-linking?userId=1&isAdmin=1&contact_id=${selectedClient}`;

      try {
        const response = await fetch(url, {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        const data = await response.json();

        if (data?.code === 200) {
          // Create the additional item
          const additionalItem = {
            document_request_id: "Additional Document",
            document_req_name: "Additional Document",
          };

          // Add it as the first item to the data array
          const updatedData = [
            additionalItem,
            ...data?.data?.document_requests_for_linking,
          ];

          // Set the updated data to the state
          setRequestOptions(updatedData);
        } else {
          // Set only the additional document in the state
          setRequestOptions([
            {
              document_request_id: "Additional Document",
              document_req_name: "Additional Document",
            },
          ]);
        }
      } catch (error) {
        return error;
      }
    } else {
      setLinkRequest(true);
    }
  };

  const fetchClientPhoneNumber = async (client_id) => {
    try {
      const res = await getClientPhone({ token, client_id });
      setClientPhone(res?.data[0]?.phone_number);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const currentState = {
      updatedAt,
      debouncedGlobalFilter,
      columnFilters,
    };

    // If filters or sorting change, reset page to 1
    if (JSON.stringify(prevStateRef.current) !== JSON.stringify(currentState)) {
      if (currentPage !== 1) {
        setCurrentPage(1);
        return; // Avoid double API call
      }
    }

    prevStateRef.current = currentState;
    documentResponseFunc();

    clearErrors();
    if (searchedTerm === "") {
      setClientNames([]);
    }
  }, [
    bulkDeleteDocument,
    documentDeleted,
    documentUpdated,
    debouncedGlobalFilter,
    currentPage,
    columnFilters,
    pageSize,
    sorting,
    documentViewer,
    updatedAt,
  ]);

  useEffect(() => {
    fetchSubCategory();
    fetchUsers();
  }, []);

  useEffect(() => {
    if (assignClient) {
      fetchRequestList(benefId);
    }
  }, [assignClient]);

  useEffect(() => {
    const caseIdValue = getValues("caseId");
    const selectedSubCategoryId = getValues("subcategory");

    const selectedSubCategory = subCategoryOption?.find(
      (option) => option.field_setting_id === selectedSubCategoryId
    );
    const subcategoryLabel = selectedSubCategory
      ? selectedSubCategory.fields_name
      : "";

    setDocData({
      ...docData,
      contact_id: contactId,
      phone_number: clientPhone,
      ben_id: benefId,
      document_desc: description,
      client_reference_number: caseIdValue || "",
      document_subtype: subcategoryLabel,
      document_sub_category: selectedSubCategoryId,
    });
    contactId && !linkRequest && !reqStatus
      ? setShowLinkButton(true)
      : setShowLinkButton(false);
  }, [requestOptions, reqStatus]);

  const onEditDocumentFormSubmit = async (editDocumentFormData) => {
    const editDocumentData = {
      token: token,
      user_id: user_id,
      document_id: documentId,
      document_desc: description,
      document_name: editDocumentFormData.name,
      document_sub_category: editDocumentFormData.subcategory,
      contact_id: editDocumentFormData.addClientName,
    };

    // If a client name label is provided, set it as the default label
    // and mark the client as known by updating the "client_unknown" flag to "0".
    if (clientNameLabel) {
      setDefaultClientNameLabel(clientNameLabel);
      editDocumentData.client_unknown = "0";
    }

    const editDocumentFormResponse = await editDocument(editDocumentData);

    if (editDocumentFormResponse.code === 200) {
      // const urlAddDocumentRequests = `${baseUrl}/api/add-document-requests?contact_id=${editDocumentFormData.addClientName}`;
      // try {
      //   const responseAddDocumentRequests = await fetch(
      //     urlAddDocumentRequests,
      //     {
      //       method: "POST",
      //       headers: {
      //         Accept: "application/json",
      //         Authorization: `Bearer ${token}`,
      //       },
      //     }
      //   );

      //   await responseAddDocumentRequests.json();
      // } catch (error) {
      //   return error;
      // }

      fetchClientPhoneNumber(editDocumentFormData.addClientName);
      setPdfViewerTitle(editDocumentFormData.name);
      setAssignClient(true);
      setExistedContactId(editDocumentFormData.addClientName);
      toast.success("Document is updated successfully", {
        position: "top-center",
        autoClose: 3000,
        theme: "dark",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.error("Error while updating document", {
        position: "top-center",
        autoClose: 5000,
        theme: "dark",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  useEffect(() => {
    setOffset(0);
    setTotal(0);
  }, [searchedTerm]);

  let columns;
  if (assigned == "TRUE") {
    columns = [
      {
        id: "select",
        header: ({ table }) => (
          <div className="flex items-center">
            <Checkbox
              checked={
                table.getIsAllPageRowsSelected() ||
                (table.getIsSomePageRowsSelected() && "indeterminate")
              }
              onCheckedChange={(value) =>
                table.toggleAllPageRowsSelected(!!value)
              }
              aria-label="Select all"
            />
            {(table.getIsSomePageRowsSelected() ||
              table.getIsAllPageRowsSelected()) && (
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button
                    variant="outline"
                    className="p-0 !border-none !shadow-none bg-transparent"
                  >
                    <ChevronDownIcon className="" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end">
                  <DropdownMenuItem
                    onSelect={() => handleBulkAction("delete")}
                    disabled={!canDelete}
                  >
                    Delete
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    onSelect={() => handleBulkAction("assign")}
                    disabled={is_admin !== "1"}
                  >
                    Assign
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            )}
          </div>
        ),
        cell: ({ row }) => (
          <Checkbox
            checked={row.getIsSelected()}
            onCheckedChange={(value) => {
              setSelectAllChecked(true);

              row.toggleSelected(!!value);
            }}
            aria-label="Select row"
          />
        ),
        enableSorting: false,
        enableHiding: false,
      },
      {
        accessorKey: "client_name",
        displayName: "Client Name",
        filterable: true,
        header: ({ column }) => (
          <Button
            variant="ghost"
            size="sm"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
            className="p-0"
          >
            Client Name
            <CaretSortIcon className="ml-2 h-4 w-4" />
          </Button>
        ),
        cell: ({ row }) => {
          const clientName = row.getValue("client_name");
          const clientUnknown = row.original.client_unknown === "1";
          const clientKnown = row.original.client_unknown === "0";

          return (
            <div className="flex items-center gap-2">
              <span className="wrap-text capitalize">
                {clientUnknown ? "Unknown" : clientName}
              </span>
              <div className="ml-auto">
                {clientUnknown ? (
                  <span>
                    <TbCircleCheck color="#eab308" className="h-6 w-6" />
                  </span>
                ) : clientName || clientKnown ? (
                  <span>
                    <TbCircleCheck color="#3b82f6" className="h-6 w-6" />
                  </span>
                ) : null}
              </div>
            </div>
          );
        },
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id));
        },
      },
      {
        accessorKey: "phone_number",
        displayName: "Phone No",
        filterable: false,
        header: ({ column }) => (
          <Button
            variant="ghost"
            size="sm"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
            className="p-0"
          >
            Phone No
            <CaretSortIcon className="ml-2 h-4 w-4" />
          </Button>
        ),
        cell: ({ row }) => {
          const phone_no = row.getValue("phone_number");

          return <span className="">{phone_no}</span>;
        },
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id));
        },
        enableHiding: false,
      },
      {
        accessorKey: "document_source_details",
        displayName: `${caseList} Id`,
        filterable: true,

        header: ({ column }) => (
          <Button
            className="capitalize p-0"
            variant="ghost"
            size="sm"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            {caseList} Id
            <CaretSortIcon className="ml-2 h-4 w-4" />
          </Button>
        ),
        cell: ({ row }) => {
          const sourceDetails = row.getValue("document_source_details");
          return (
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <span className="text-gray-800 font-medium">
                    {sourceDetails && sourceDetails.length > 15
                      ? `${sourceDetails.substring(0, 15)}...`
                      : sourceDetails || ""}
                  </span>
                </TooltipTrigger>
                {sourceDetails && sourceDetails.length > 15 && (
                  <TooltipContent className="bg-gray-600">
                    <p>{sourceDetails}</p>
                  </TooltipContent>
                )}
              </Tooltip>
            </TooltipProvider>
          );
        },
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id));
        },
      },

      {
        accessorKey: "document_type",
        displayName: "Type",
        filterable: true,
        header: ({ column }) => {
          return (
            <Button
              variant="ghost"
              size="sm"
              onClick={() =>
                column.toggleSorting(column.getIsSorted() === "asc")
              }
              className="p-0"
            >
              Type
              <CaretSortIcon className="ml-2 h-6" />
            </Button>
          );
        },
        cell: ({ row }) => (
          <div className="w-full flex items-center justify-center">
            {fileIcon[row.getValue("document_type").toLowerCase()]}
          </div>
        ),
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id));
        },
      },
      {
        accessorKey: "document_name",
        displayName: "File Name",
        filterable: true,
        header: ({ column }) => (
          <Button
            variant="ghost"
            size="sm"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
            className="p-0"
          >
            File Name
            <CaretSortIcon className="ml-2 h-4 w-4" />
          </Button>
        ),
        cell: ({ row }) => {
          const document_name = row.getValue("document_name");

          return (
            <div
              className={`capitalize text-left ${
                canView
                  ? "cursor-pointer text-blue-500 underline"
                  : "cursor-not-allowed text-gray-500"
              }`}
              onClick={() => {
                if (!canView) return;
                setDocData(row?.original);
                changeDocumentViewerState(
                  row.original.client_reference_number,
                  row.original.contact_id,
                  row.original.document_name,
                  row.original.category,
                  row.original.document_sub_category,
                  row.original.document_desc,
                  row.original.documentID,
                  row.original.document_type,
                  row.original.phone_number,
                  row.original.ben_id
                );
              }}
            >
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger>
                    <span className="text-gray-800 font-medium">
                      {document_name && document_name.length > 15
                        ? `${document_name.substring(0, 15)}...`
                        : document_name || ""}
                    </span>
                  </TooltipTrigger>
                  {document_name && document_name.length > 15 && (
                    <TooltipContent className="bg-gray-600">
                      <p>{document_name}</p>
                    </TooltipContent>
                  )}
                </Tooltip>
              </TooltipProvider>
              {/* {document_name} */}
            </div>
          );
        },
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id));
        },
      },
      {
        accessorKey: "assign_user",
        displayName: "Assigned User",
        filterable: true,
        header: ({ column }) => (
          <Button
            variant="ghost"
            size="sm"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
            className="p-0"
          >
            Assigned User
            <CaretSortIcon className="ml-2 h-4 w-4" />
          </Button>
        ),
        cell: ({ row }) => {
          const assign_user = row.getValue("assign_user")
            ? row.getValue("assign_user")
            : "Unassigned";

          return <div className="capitalize">{assign_user}</div>;
        },
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id));
        },
      },
      {
        accessorKey: "created_at",
        displayName: "Uploaded On",
        header: ({ column }) => (
          <Button
            variant="ghost"
            size="sm"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
            className="p-0"
          >
            Uploaded On
            <CaretSortIcon className="ml-2 h-4 w-4" />
          </Button>
        ),
        cell: ({ row }) => {
          const uploaded = row.getValue("created_at");
          return (
            <div className="capitalize text-wrap">{formatDate(uploaded)}</div>
          );
        },
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id));
        },
      },
      {
        accessorKey: "updated_at",
        displayName: "Modified At",
        header: ({ column }) => (
          <Button
            variant="ghost"
            size="sm"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
            className="p-0"
          >
            Modified At
            <CaretSortIcon className="ml-2 h-4 w-4" />
          </Button>
        ),
        cell: ({ row }) => {
          const updated = row.getValue("updated_at");
          return (
            <div className="capitalize text-wrap">{formatDate(updated)}</div>
          );
        },
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id));
        },
      },
      {
        accessorKey: "document_count",
        displayName: "Actions",
        header: ({ column }) => {
          if (!shouldShowActionColumn) {
            return null;
          }
          return (
            <Button variant="ghost" size="sm" className="p-0 !cursor-default">
              Actions
            </Button>
          );
        },
        cell: ({ row }) => {
          return (
            <div className="flex">
              {canEdit && (
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger>
                      <span>
                        <EditDocumentPopup
                          tableValues={row.original}
                          subCategoryOption={subCategoryOption}
                          setDocumentUpdatedToTrue={setDocumentUpdatedToTrue}
                        />
                      </span>
                    </TooltipTrigger>
                    <TooltipContent className="bg-gray-600">
                      <p>Edit</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              )}

              {canCopy && (
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger>
                      <span>
                        <CopyLinkPopup tableValues={row.original} />
                      </span>
                    </TooltipTrigger>
                    <TooltipContent className="bg-gray-600">
                      <p>Copy</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              )}

              {canView && (
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger>
                      <span>
                        <DocumentViewer
                          tableValues={row.original}
                          changeDocumentViewerState={changeDocumentViewerState}
                          setDocData={setDocData}
                        />
                      </span>
                    </TooltipTrigger>
                    <TooltipContent className="bg-gray-600">
                      <p>View</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              )}

              {canDelete && (
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger>
                      <span>
                        <DeleteDocumentPopup
                          tableValues={row.original}
                          setDocumentDeletedToTrue={setDocumentDeletedToTrue}
                          setRowSelection={setRowSelection}
                        />
                      </span>
                    </TooltipTrigger>
                    <TooltipContent className="bg-gray-600">
                      <p>Delete</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              )}
            </div>
          );
        },
        enableSorting: false,
        enableHiding: false,
        enableColumnFilter: false,
      },
    ];
  } else {
    columns = [
      {
        id: "select",
        header: ({ table }) => (
          <div className="flex items-center">
            <Checkbox
              checked={
                table.getIsAllPageRowsSelected() ||
                (table.getIsSomePageRowsSelected() && "indeterminate")
              }
              onCheckedChange={(value) =>
                table.toggleAllPageRowsSelected(!!value)
              }
              aria-label="Select all"
            />
            {(table.getIsSomePageRowsSelected() ||
              table.getIsAllPageRowsSelected()) && (
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button
                    variant="outline"
                    className="p-0 !border-none !shadow-none bg-transparent"
                  >
                    <ChevronDownIcon className="" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end">
                  <DropdownMenuItem onSelect={() => handleBulkAction("delete")}>
                    Delete
                  </DropdownMenuItem>
                  <DropdownMenuItem onSelect={() => handleBulkAction("assign")}>
                    Assign
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            )}
          </div>
        ),
        cell: ({ row }) => (
          <Checkbox
            checked={row.getIsSelected()}
            onCheckedChange={(value) => {
              setSelectAllChecked(true);
              row.toggleSelected(!!value);
            }}
            aria-label="Select row"
          />
        ),
        enableSorting: false,
        enableHiding: false,
      },
      {
        accessorKey: "client_name",
        displayName: "Client Name",
        filterable: true,
        header: ({ column }) => (
          <Button
            variant="ghost"
            size="sm"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
            className="p-0"
          >
            Client Name
            <CaretSortIcon className="ml-2 h-4 w-4" />
          </Button>
        ),
        cell: ({ row }) => {
          const clientName = row.getValue("client_name");
          const clientUnknown = row.original.client_unknown === "1";
          const clientKnown = row.original.client_unknown === "0";

          return (
            <div className="flex items-center gap-2">
              <span className="wrap-text capitalize">
                {clientUnknown ? "Unknown" : clientName}
              </span>
              <div className="ml-auto">
                {clientUnknown ? (
                  <span>
                    <TbCircleCheck color="#eab308" className="h-6 w-6" />
                  </span>
                ) : clientName || clientKnown ? (
                  <span>
                    <TbCircleCheck color="#3b82f6" className="h-6 w-6" />
                  </span>
                ) : null}
              </div>
            </div>
          );
        },
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id));
        },
      },
      {
        accessorKey: "phone_number",
        displayName: "Phone No",
        filterable: false,
        header: ({ column }) => (
          <Button
            variant="ghost"
            size="sm"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
            className="p-0"
          >
            Phone No
            <CaretSortIcon className="ml-2 h-4 w-4" />
          </Button>
        ),
        cell: ({ row }) => {
          const phone_no = row.getValue("phone_number");

          return <span className="">{phone_no}</span>;
        },
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id));
        },
        enableHiding: false,
      },
      {
        accessorKey: "document_source_details",
        displayName: `${caseList} Id`,
        filterable: true,
        header: ({ column }) => (
          <Button
            className="capitalize p-0"
            variant="ghost"
            size="sm"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            {caseList} Id
            <CaretSortIcon className="ml-2 h-4 w-4" />
          </Button>
        ),
        cell: ({ row }) => {
          const sourceDetails = row.getValue("document_source_details");
          return (
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <span className="text-gray-800 font-medium">
                    {sourceDetails && sourceDetails.length > 15
                      ? `${sourceDetails.substring(0, 15)}...`
                      : sourceDetails || ""}
                  </span>
                </TooltipTrigger>
                {sourceDetails && sourceDetails.length > 15 && (
                  <TooltipContent className="bg-gray-600">
                    <p>{sourceDetails}</p>
                  </TooltipContent>
                )}
              </Tooltip>
            </TooltipProvider>
          );
        },
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id));
        },
      },

      {
        accessorKey: "document_type",
        filterable: true,
        displayName: "Type",
        header: ({ column }) => {
          return (
            <Button
              variant="ghost"
              size="sm"
              onClick={() =>
                column.toggleSorting(column.getIsSorted() === "asc")
              }
              className="p-0"
            >
              Type
              <CaretSortIcon className="ml-2 h-6" />
            </Button>
          );
        },
        cell: ({ row }) => (
          <div className="w-full flex items-center ">
            {fileIcon[row.getValue("document_type").toLowerCase()]}
          </div>
        ),
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id));
        },
      },
      {
        accessorKey: "document_name",
        displayName: "File Name",
        filterable: true,
        header: ({ column }) => (
          <Button
            variant="ghost"
            size="sm"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
            className="p-0"
          >
            File Name
            <CaretSortIcon className="ml-2 h-4 w-4" />
          </Button>
        ),
        cell: ({ row }) => {
          const document_name = row.getValue("document_name");

          return (
            <div
              className={`capitalize text-left ${
                canView
                  ? "cursor-pointer text-blue-500 underline"
                  : "cursor-not-allowed text-gray-500"
              }`}
              onClick={() => {
                if (!canView) return;
                setDocData(row.original);
                changeDocumentViewerState(
                  row.original.client_reference_number,
                  row.original.contact_id,
                  row.original.document_name,
                  row.original.category,
                  row.original.document_sub_category,
                  row.original.document_desc,
                  row.original.documentID,
                  row.original.document_type,
                  row.original.phone_number,
                  row.original.ben_id
                );
              }}
            >
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger>
                    <span className="text-gray-800 font-medium">
                      {document_name && document_name.length > 15
                        ? `${document_name.substring(0, 15)}...`
                        : document_name || ""}
                    </span>
                  </TooltipTrigger>
                  {document_name && document_name.length > 15 && (
                    <TooltipContent className="bg-gray-600">
                      <p>{document_name}</p>
                    </TooltipContent>
                  )}
                </Tooltip>
              </TooltipProvider>
              {/* {document_name} */}
            </div>
          );
        },
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id));
        },
      },
      {
        accessorKey: "modified_user",
        displayName: "Modified By",
        header: ({ column }) => (
          <Button
            variant="ghost"
            size="sm"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
            className="p-0"
          >
            Modified By
            <CaretSortIcon className="ml-2 h-4 w-4" />
          </Button>
        ),
        cell: ({ row }) => {
          const modified_by = row.getValue("modified_user");

          return <div className="capitalize">{modified_by}</div>;
        },
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id));
        },
      },
      {
        accessorKey: "created_at",
        displayName: " Uploaded On",

        header: ({ column }) => (
          <Button
            variant="ghost"
            size="sm"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
            className="p-0"
          >
            Uploaded On
            <CaretSortIcon className="ml-2 h-4 w-4" />
          </Button>
        ),
        cell: ({ row }) => {
          const uploaded = row.getValue("created_at");

          return <div className="capitalize">{formatDate(uploaded)}</div>;
        },
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id));
        },
      },
      {
        accessorKey: "updated_at",
        displayName: "Modified At",
        header: ({ column }) => (
          <Button
            variant="ghost"
            size="sm"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
            className="p-0"
          >
            Modified At
            <CaretSortIcon className="ml-2 h-4 w-4" />
          </Button>
        ),
        cell: ({ row }) => {
          const updated = row.getValue("updated_at");
          return (
            <div className="capitalize text-wrap">{formatDate(updated)}</div>
          );
        },
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id));
        },
      },
      {
        accessorKey: "document_count",
        displayName: "Actions",
        header: ({ column }) => {
          if (!shouldShowActionColumn) {
            return null;
          }
          return (
            <Button variant="ghost" size="sm" className="p-0 !cursor-default">
              Actions
            </Button>
          );
        },
        cell: ({ row }) => {
          return (
            <div className="flex">
              {canEdit && (
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger>
                      <span>
                        <EditDocumentPopup
                          tableValues={row.original}
                          subCategoryOption={subCategoryOption}
                          setDocumentUpdatedToTrue={setDocumentUpdatedToTrue}
                        />
                      </span>
                    </TooltipTrigger>
                    <TooltipContent className="bg-gray-600">
                      <p>Edit</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              )}

              {canCopy && (
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger>
                      <span>
                        <CopyLinkPopup tableValues={row.original} />
                      </span>
                    </TooltipTrigger>
                    <TooltipContent className="bg-gray-600">
                      <p>Copy</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              )}
              {canView && (
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger>
                      <span>
                        <DocumentViewer
                          tableValues={row.original}
                          changeDocumentViewerState={changeDocumentViewerState}
                          setDocData={setDocData}
                        />
                      </span>
                    </TooltipTrigger>
                    <TooltipContent className="bg-gray-600">
                      <p>View</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              )}
              {canDelete && (
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger>
                      <span>
                        <DeleteDocumentPopup
                          tableValues={row.original}
                          setDocumentDeletedToTrue={setDocumentDeletedToTrue}
                          setRowSelection={setRowSelection}
                        />
                      </span>
                    </TooltipTrigger>
                    <TooltipContent className="bg-gray-600">
                      <p>Delete</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              )}
            </div>
          );
        },
        enableSorting: false,
        enableHiding: false,
        enableColumnFilter: false,
      },
    ];
  }

  const table = useReactTable({
    data: documentResponse,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    initialState: {
      pageSize: 20,
    },
    manualPagination: true,
    manualSorting: true,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
      pageSize,
    },

    globalFilterFn: (rows, columnFilters) => {
      const [globalFilter] = columnFilters.filter(
        (filter) => filter.id === "global"
      );
      if (!globalFilter || !globalFilter.value) return rows;

      return rows.filter((row) => {
        const name = row.original.name.toLowerCase(); // Assuming 'name' is the column key
        return name.includes(globalFilter.value.toLowerCase());
      });
    },
    onGlobalFilterChange: (filterValue) => {
      setGlobalFilter(filterValue); // Update the global filter state
    },
  });

  // Functions to check if a file is a specific type (PDF, ZIP, document, or image) based on its extension.
  const isFileType = (src, validExtensions) => {
    const extension = src?.split(".").pop().toLowerCase();
    return validExtensions.includes(extension);
  };

  const isPDF = (src) => isFileType(src, ["pdf", "txt"]);
  const isZip = (src) => isFileType(src, ["zip"]);
  const isDoc = (src) =>
    isFileType(src, ["doc", "docx", "xls", "xlsx", "ppt", "pptx"]);
  const isImage = (src) =>
    isFileType(src, ["jpg", "jpeg", "png", "gif", "bmp", "svg"]);
  const isMp4 = (src) => isFileType(src, ["mp4"]);

  // Downloads the document by creating a temporary link element.
  const handleDocumentDownload = function () {
    const link = document.createElement("a");
    link.href = pdfLink;
    link.download = pdfViewerTitle || "download";
    link.click();
  };

  const changePdfDetailsContainerVisibility = () => {
    setIsPdfDetailsContainerOpen(!isPdfDetailsContainerOpen);
  };
  const pdfDetailsData = async () => {
    setLoading(true);
    setPdfDataLoading(true);

    try {
      const response = await pdfDetails(
        token,
        docData?.documentID,
        "documents"
      );
      const data = await response.data;

      setPdfData(data);
      setPdfLink(data?.pdflink);
    } catch (error) {
      console.error("Error fetching PDF details:", error);
    } finally {
      setLoading(false);
      setPdfDataLoading(false);
    }
  };

  const pdfDetailsVersionData = async () => {
    setLoading(true);
    try {
      const response = await pdfDetails(
        token,
        docData?.documentID,
        "documents",
        documentVersionId
      );
      const data = await response.data;

      setPdfData(data);
      setPdfLink(data[0]?.pdflink);
    } catch (error) {
      console.error("Error fetching PDF details:", error);
    } finally {
      setLoading(false);
      setPdfDataLoading(false);
    }
  };

  // useEffect(() => {
  //   if (documentViewer && !loading && docData) {
  //     pdfDetailsData();
  //   }
  // }, [documentViewer, docData, loading]);

  useEffect(() => {
    pdfDetailsData();
  }, []);

  // Converts a base64 string to a Blob object with the specified MIME type.
  const base64ToBlob = (base64, mimeType) => {
    const binaryString = atob(base64); // Decode the base64 string
    const length = binaryString.length;
    const bytes = new Uint8Array(length);
    for (let i = 0; i < length; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return new Blob([bytes], { type: mimeType });
  };

  // Converts the PDF's Base64 data to a Blob and updates the zip content when pdfBase64 changes.
  useEffect(() => {
    if (pdfBase64 && pdfBase64[0]?.data && isZip(pdfLink)) {
      const blob = base64ToBlob(pdfBase64[0]?.data, "application/zip");

      setZipContent(blob);
    }
  }, [pdfBase64]);

  // Helper Component for Unsupported Files
  const UnsupportedFileMessage = ({ onDownload }) => (
    <div className="bg-[#ddeef6] border-l-4 border-[#44a0cf] text-white p-6 rounded-lg shadow-md max-w-md mx-auto flex flex-col justify-center items-center">
      <span className="text-gray-800 text-base font-semibold mb-3 text-center">
        This file is not supported for viewing here. You can download it using
        the button below.
      </span>
      <button
        onClick={onDownload}
        className="bg-[#2b93c8d0] hover:bg-[#44a0cf] py-2 px-4 rounded-lg flex gap-1 justify-center items-center self-center transition-all duration-300"
      >
        <FaDownload /> Download
      </button>
    </div>
  );

  // Returns the appropriate viewer based on the file type.
  const getViewer = (pdfLink) => {
    if (isPDF(pdfLink)) {
      return (
        <iframe
          title={pdfViewerTitle}
          src={pdfLink}
          width="100%"
          height="590"
          key={pdfLink}
        />
      );
    }

    if (isDoc(pdfLink)) {
      return (
        <iframe
          title={pdfViewerTitle}
          src={`https://view.officeapps.live.com/op/embed.aspx?src=${pdfLink}`}
          // src={`https://docs.google.com/gview?url=${pdfLink}&embedded=true`} //googleDocs
          className="w-full h-full border-none"
        />
      );
    }

    if (isMp4(pdfLink)) {
      return (
        <div className="flex justify-center items-center w-full max-w-[800px]">
          <video
            controls
            className="w-full h-auto max-h-[90vh] object-contain rounded-lg"
          >
            <source src={pdfLink} type="video/mp4" />
          </video>
        </div>
      );
    }

    if (isZip(pdfLink)) {
      return <ZipViewer zipContent={zipContent} zipSrc={pdfLink} />;
    }

    if (isImage(pdfLink)) {
      return (
        <img src={pdfLink} width="100%" height="590" alt={pdfViewerTitle} />
      );
    }

    // Default for unsupported files
    return <UnsupportedFileMessage onDownload={handleDocumentDownload} />;
  };

  //Setting the clientId for the Info Card
  const clientInfoID = function () {
    const addClientNameValue = getValues("addClientName");
    if (
      addClientNameValue !== null &&
      addClientNameValue !== undefined &&
      addClientNameValue !== ""
    ) {
      setBusinessCardInfoClientID(addClientNameValue);
    } else if (clientNameLabel === defaultClientNameLabel) {
      setBusinessCardInfoClientID(defaultContactId);
    } else {
      setBusinessCardInfoClientID(defaultContactId);
    }

    if (clientNameLabel === defaultClientNameLabel) {
      setBusinessCardInfoClientID(defaultContactId);
    }
  };

  useEffect(() => {
    clientInfoID();
  }, [contactId, getValues("addClientName"), clientNameLabel]);

  return (
    <>
      {approveConfirmOpen && (
        <ApprovePDFDocumentPopup
          documentRequestId={requestId}
          setReqStatus={setReqStatus}
          setDocumentViewer={setDocumentViewer}
          setDocumentUpdatedToTrue={setDocumentUpdatedToTrue}
          setApproveConfirmOpen={setApproveConfirmOpen}
        />
      )}
      {disapproveConfirmOpen && (
        <RejectPDFDocumentPopup
          documentRequestId={requestId}
          setReqStatus={setReqStatus}
          setDocumentViewer={setDocumentViewer}
          setDocumentUpdatedToTrue={setDocumentUpdatedToTrue}
          setDisapproveConfirmOpen={setDisapproveConfirmOpen}
        />
      )}
      {linkRequestOpen && (
        <LinkToRequest
          documentId={documentId}
          requestOptions={requestOptions}
          setLinkRequest={setLinkRequest}
          setReqStatus={setReqStatus}
          setRequestId={setRequestId}
          setLinkRequestOpen={setLinkRequestOpen}
          setSelectedRequestLabel={setSelectedRequestLabel}
        />
      )}

      {bulkDeleteDocument ? (
        <BulkDeletePopup
          setDocumentDeletedToTrue={setDocumentDeletedToTrue}
          Docbulkid={bulkDeleteDocumentIds}
          bulkDeleteDocument={bulkDeleteDocument}
          setBulkDeleteDocument={setBulkDeleteDocument}
          setRowSelection={setRowSelection}
        />
      ) : null}
      {bulkAssign ? (
        <AssignUserPopup
          selectedRows={table
            ?.getFilteredSelectedRowModel()
            ?.rows.map((row) => row.original)}
          userList={userList}
          setDocumentUpdatedToTrue={setDocumentUpdatedToTrue}
          bulkAssign={bulkAssign}
          setBulkAssign={setBulkAssign}
          setRowSelection={setRowSelection}
          bulkAssignIds={bulkAssignIds}
        />
      ) : null}
      {!documentViewer ? (
        <>
          <div className="document__table">
            <div className="w-full bg-white px-2">
              <div className="flex justify-between items-center py-4 flex-wrap gap-4">
                <Input
                  placeholder="Search..."
                  value={globalFilter}
                  onChange={(event) =>
                    table.setGlobalFilter(event.target.value)
                  }
                  className="max-w-sm"
                />
                <div className="flex gap-2">
                  <Button
                    variant="outline"
                    className=""
                    onClick={() => setShowFilterButton(!showFilterButton)}
                  >
                    <MixerHorizontalIcon className="mr-1" />
                    Filter
                  </Button>

                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button variant="outline" className="ml-auto">
                        Columns <ChevronDownIcon className="ml-2 h-4 w-4" />
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="end">
                      {table
                        .getAllColumns()
                        .filter((column) => column.getCanHide())
                        .map((column) => {
                          return (
                            <DropdownMenuCheckboxItem
                              key={column.id}
                              className="capitalize"
                              checked={column.getIsVisible()}
                              onCheckedChange={(value) =>
                                column.toggleVisibility(!!value)
                              }
                            >
                              {column.columnDef.displayName}
                            </DropdownMenuCheckboxItem>
                          );
                        })}
                    </DropdownMenuContent>
                  </DropdownMenu>
                </div>
              </div>
              {showFilterButton && (
                <DataToolbar
                  table={table}
                  apiUrl={caseListUrls[caseList.toLowerCase()]}
                  updatedAt={updatedAt}
                  setUpdatedAt={setUpdatedAt}
                />
              )}
              <div className="rounded-md border">
                {loading ? (
                  <TableSkeleton />
                ) : (
                  <div className="w-full overflow-x-auto">
                    <Table className="w-full table-auto">
                      <TableHeader className="sticky top-0 bg-white z-10">
                        {table.getHeaderGroups().map((headerGroup) => (
                          <TableRow key={headerGroup.id} className="">
                            {headerGroup.headers.map((header) => (
                              <TableHead key={header.id}>
                                {header.isPlaceholder
                                  ? null
                                  : flexRender(
                                      header.column.columnDef.header,
                                      header.getContext()
                                    )}
                              </TableHead>
                            ))}
                          </TableRow>
                        ))}
                      </TableHeader>
                      <TableBody>
                        {table.getRowModel().rows?.length ? (
                          table.getRowModel().rows.map((row) => (
                            <TableRow
                              key={row.id}
                              data-state={row.getIsSelected() && "selected"}
                              className=""
                            >
                              {row.getVisibleCells().map((cell) => (
                                <TableCell
                                  key={cell.id}
                                  className=" p-2 text-xs truncate"
                                >
                                  {flexRender(
                                    cell.column.columnDef.cell,
                                    cell.getContext()
                                  )}
                                </TableCell>
                              ))}
                            </TableRow>
                          ))
                        ) : (
                          <TableRow className="">
                            <TableCell
                              colSpan={columns.length}
                              className="h-24 text-center "
                            >
                              {loading || docResLoading ? (
                                <TableSkeleton />
                              ) : (
                                "No records found"
                              )}
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </div>
                )}
              </div>
              <div className="flex items-center justify-between space-x-2 py-4 flex-wrap gap-4">
                <div className="flex items-center gap-5">
                  <div className="flex-1 text-sm text-muted-foreground">
                    {table.getFilteredSelectedRowModel().rows.length} of{" "}
                    {table.getFilteredRowModel().rows.length} row(s) selected.
                  </div>
                  <div className="flex items-center space-x-2">
                    <p className="text-sm font-medium">Rows per page</p>
                    <Select
                      value={`${table.getState().pageSize}`}
                      onValueChange={(value) => {
                        setPageSize(Number(value));
                        setCurrentPage(1);
                      }}
                    >
                      <SelectTrigger className="w-auto">
                        <SelectValue placeholder={table.getState().pageSize} />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectGroup>
                          {[10, 20, 30, 40, 50].map((pageSize) => (
                            <SelectItem key={pageSize} value={`${pageSize}`}>
                              {pageSize}
                            </SelectItem>
                          ))}
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                  </div>
                </div>

                <div className="space-x-2">
                  <PaginationSection
                    setCurrentPage={setCurrentPage}
                    totalPages={totalPages}
                    currentPage={currentPage}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          {!pdfEditor ? (
            <div className="all-document-pdf-viewer" key={documentId}>
              <div className="all-document-pdf-viewer-header">
                <div className="all-document-pdf-viewer-title capitalize text-balance flex-1 pb-4 w-1/2">
                  {pdfViewerTitle}
                  {selectedRequestLabel
                    ? " - " + selectedRequestLabel + " (Linked Request)"
                    : null}
                </div>
                <div className="all-document-pdf-viewer-icons flex flex-wrap items-center justify-end gap-2">
                  {(contactId || assignClient) && (
                    <div className="self-baseline">
                      <BusinessCardInfo clientID={businessCardInfoClientID} />
                    </div>
                  )}

                  {canMarkClientUnknown && (
                    <ButtonUI
                      formButton={false}
                      variant="outlined"
                      buttonText="Client Unknown"
                      style={{
                        backgroundColor: "#3792ed",
                        color: "#fff",
                        opacity:
                          docData?.client_unknown === "1" ||
                          defaultClientNameLabel
                            ? 0.5
                            : 1,
                        cursor:
                          docData?.client_unknown === "1" ||
                          defaultClientNameLabel
                            ? "none"
                            : "pointer",
                      }}
                      disabled={
                        docData?.client_unknown === "1" ||
                        defaultClientNameLabel
                          ? true
                          : false
                      }
                      onClickFunc={handleClientUnknown}
                    />
                  )}
                  {existedContactId && canAddTask && (
                    <AddTask contactId={contactId} />
                  )}
                  {existedContactId && clientPhone && canSendSMS ? (
                    <SendSMS clientPhone={clientPhone} clientID={contactId} />
                  ) : null}

                  {canApproveDocument && (
                    <ButtonUI
                      formButton={false}
                      variant="outlined"
                      buttonText={<TbCircleCheck />}
                      style={{
                        backgroundColor:
                          reqStatus?.toLowerCase() === "uploaded"
                            ? "#28a745"
                            : "#d8d8e4df",

                        color:
                          reqStatus?.toLowerCase() === "uploaded"
                            ? "white"
                            : "rgb(18 215 24)",
                        marginRight: "5px",
                        marginBottom: "5px",
                        padding: "8px",
                        minWidth: "fit-content",
                        border: "none",
                      }}
                      disabled={
                        reqStatus?.toLowerCase() === "uploaded" ? false : true
                      }
                      onClickFunc={handleApproveDocument}
                    />
                  )}
                  {canApproveDocument && (
                    <ButtonUI
                      formButton={false}
                      variant="outlined"
                      buttonText={<TiDeleteOutline />}
                      style={{
                        backgroundColor:
                          reqStatus?.toLowerCase() === "uploaded"
                            ? "#dc3545"
                            : "#d8d8e4df",

                        color:
                          reqStatus?.toLowerCase() === "uploaded"
                            ? "white"
                            : "rgb(215 18 18)",
                        marginRight: "5px",
                        marginBottom: "5px",
                        padding: "8px",
                        minWidth: "fit-content",
                        border: "none",
                      }}
                      disabled={
                        reqStatus?.toLowerCase() === "uploaded" ? false : true
                      }
                      onClickFunc={handleDisapproveDocument}
                    />
                  )}
                  {canLinkDocument && (
                    <ButtonUI
                      formButton={false}
                      variant="outlined"
                      buttonText={<BiLinkAlt />}
                      style={{
                        backgroundColor: showLinkButton
                          ? "#17a2b8"
                          : "#d8d8e4df",
                        color: showLinkButton ? "white" : "rgb(18 215 24)",
                        marginRight: "5px",
                        marginBottom: "5px",
                        padding: "8px",
                        minWidth: "fit-content",
                        border: "none",
                      }}
                      disabled={showLinkButton ? false : true}
                      onClickFunc={handleLinkRequest}
                    />
                  )}

                  {docFileType === "pdf" && canEditPdf ? (
                    <div
                      onClick={
                        !loading ? () => setPdfEditor(!pdfEditor) : undefined
                      }
                      className={`edit-doc-btn ${
                        loading
                          ? "cursor-not-allowed opacity-50 pointer-events-none"
                          : "cursor-pointer"
                      }`}
                      title={
                        loading ? "Loading PDF, please wait..." : "Edit PDF"
                      }
                    >
                      <TbEdit />
                    </div>
                  ) : null}
                  <div
                    className="pdf-viewer-icon"
                    onClick={changePdfDetailsContainerVisibility}
                    title="PDF Details"
                  >
                    {isPdfDetailsContainerOpen ? (
                      <IoCloseSharp />
                    ) : (
                      <BiDetail />
                    )}
                  </div>
                  <div
                    className="all-document-pdf-viewer-icon"
                    onClick={() => setDocumentViewer(false)}
                  >
                    <RiCloseCircleFill />
                  </div>
                </div>
              </div>
              <div className="document-viewer-with-from">
                <div className="iframe-wrapper relative w-full">
                  {loading && (
                    <div className="absolute inset-0 flex justify-center items-center bg-white bg-opacity-80 z-10">
                      <Loading />
                    </div>
                  )}
                  {!loading && data ? getViewer(pdfLink) : null}
                </div>
                <div className="document-viewer-form">
                  <div className="field_wrapper">
                    <div className="field">
                      <Controller
                        control={control}
                        name="addClientName"
                        render={({ field: { onChange, value, ...field } }) => (
                          <Autocomplete
                            className="clientNameAuto"
                            name="addClientName"
                            noOptionsText={
                              !searchClientNotFound
                                ? "Please enter the client name / alien number / case id / phone no / email"
                                : "No clients found"
                            }
                            options={[
                              ...clientNames,
                              ...(total > offset + 20 || showMoreLoading
                                ? [{ name: "Show More", id: "show_more" }]
                                : []),
                            ]}
                            loading={searchLoading}
                            //getOptionLabel={(option) => option.name}
                            getOptionLabel={(option) => {
                              // Check if option is an object and has a valid 'name' property
                              if (
                                typeof option === "object" &&
                                option !== null &&
                                typeof option.name === "string"
                              ) {
                                if (option.id === "show_more")
                                  return option.name;
                                return option.name || "";
                              }

                              // Return a fallback value (could be an empty string or some placeholder text)
                              return "";
                            }}
                            clearOnBlur={false}
                            disabled={
                              (existedContactId &&
                                existedContactId !== "null" &&
                                !showLinkButton) ||
                              !canEditClient
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={
                                  !clientNameLabel
                                    ? "Client Name / Alien Number / Case Id / Phone No / Email"
                                    : clientNameLabel
                                }
                                InputProps={{
                                  ...params.InputProps,
                                }}
                                onChange={(event) => {
                                  if (!event.target.value) {
                                    setClientNameLabel(
                                      defaultClientNameLabel
                                        ? defaultClientNameLabel
                                        : ""
                                    );
                                  }
                                  const searchTerm = event.target.value;
                                  handleClientNameSearch(searchTerm);
                                  // Store selectedClientName in component state or prop
                                  setSearchedTerm(searchTerm);
                                }}
                                onBlur={() => {
                                  if (!contactId) {
                                    setClientNameLabel(
                                      defaultClientNameLabel
                                        ? defaultClientNameLabel
                                        : ""
                                    );
                                  }
                                }}
                                disabled={
                                  (existedContactId &&
                                    existedContactId !== "null" &&
                                    !showLinkButton) ||
                                  !canEditClient
                                }
                              />
                            )}
                            filterOptions={(options, state) => options}
                            renderOption={(props, option) => {
                              if (option.id === "show_more") {
                                return (
                                  <li
                                    {...props}
                                    onClick={async () => {
                                      setShowMoreLoading(true); // Set loading to true
                                      setOffset((prev) => prev + 20);
                                    }}
                                    style={{
                                      cursor: "pointer",
                                      color: showMoreLoading
                                        ? "grey"
                                        : "#007bff",
                                    }}
                                  >
                                    {showMoreLoading
                                      ? "Loading..."
                                      : option.name}
                                  </li>
                                );
                              }
                              return (
                                <li
                                  {...props}
                                  key={option.id}
                                  className={` p-3 border-b border-b-gray-300 hover:bg-gray-200 ${
                                    additionalDataLoading
                                      ? "pointer-events-none opacity-25"
                                      : ""
                                  }`}
                                >
                                  <p>{option.name}</p>
                                  <div className="grid grid-cols-2 gap-1">
                                    <span className="font-semibold">
                                      Alien Number
                                    </span>
                                    <span className="break-words">
                                      : {option.im_alien_number_c}
                                    </span>

                                    {/* Additional Data Section */}
                                    {additionalDataFetched &&
                                      !searchLoading &&
                                      !additionalDataLoading && (
                                        <>
                                          <span className="font-semibold">
                                            Case Id
                                          </span>
                                          <span className="break-words ">
                                            : {option?.caseid_c}
                                          </span>
                                          <span className="font-semibold">
                                            Case Status
                                          </span>
                                          <span className="break-words ">
                                            : {option?.status}
                                          </span>

                                          <span className="font-semibold">
                                            Lead Attorney
                                          </span>
                                          <span className="break-words ">
                                            : {option?.Lead_Attorney}
                                          </span>

                                          <span className="font-semibold">
                                            Active Legal Assistant
                                          </span>
                                          <span className="break-words ">
                                            : {option?.Legal_Assistant}
                                          </span>

                                          <span className="font-semibold">
                                            Active Paralegal
                                          </span>
                                          <span className="break-words ">
                                            : {option?.Paralegal}
                                          </span>
                                          <span className="font-semibold">
                                            Phone
                                          </span>
                                          <span className="break-words ">
                                            : {option?.phone_mobile}
                                          </span>

                                          <span className="font-semibold">
                                            Email
                                          </span>
                                          <span className="break-words">
                                            :{" "}
                                            {option?.email?.[0]
                                              ?.email_address || ""}
                                          </span>
                                        </>
                                      )}
                                  </div>

                                  {(searchLoading || additionalDataLoading) && (
                                    <div className="absolute inset-0 bg-gray-50 opacity-10 flex justify-center items-center">
                                      <span className="text-gray-500 font-semibold">
                                        <Loading />
                                      </span>
                                    </div>
                                  )}
                                </li>
                              );
                            }}
                            {...field}
                            value={
                              clientNames
                                ? clientNames.find((client) =>
                                    client._module === "Contacts"
                                      ? client.case_id === field.value
                                      : client.id === field.value
                                  ) || null
                                : null
                            }
                            onChange={(event, newValue) => {
                              if (newValue === null || newValue === undefined) {
                                onChange(null);
                                setValue("caseId", "");
                                setContactId("");
                              } else if (newValue.id !== "show_more") {
                                const selectedClient = clientNames?.find(
                                  (clientName) => clientName.id === newValue?.id
                                );
                                setClientNameLabel(selectedClient.name);

                                if (selectedClient) {
                                  let selectedId;

                                  // Check if the module is "Contacts" or "cpm_CaseParties"
                                  if (
                                    selectedClient._module === "Contacts" ||
                                    selectedClient._module === "cpm_CaseParties"
                                  ) {
                                    selectedId =
                                      selectedClient._module === "Contacts"
                                        ? selectedClient.case_id
                                        : selectedClient.id;
                                    handleClientNameChange(
                                      selectedClient.case_id
                                    );
                                  } else {
                                    // For other modules (like "Cases"), use selectedClient.id
                                    selectedId = selectedClient.id;
                                    handleClientNameChange(selectedClient.id);
                                  }
                                  setBenefId(selectedClient.id);
                                  onChange(selectedId);
                                }
                              }
                            }}
                            classes={{ paper: "autocomplete-paper" }}
                          />
                        )}
                      />
                      <span className="error-message">
                        {errors["addClientName"]?.message}
                      </span>
                    </div>
                  </div>
                  <div className="field_wrapper">
                    <div className="field">
                      <Controller
                        name="caseId"
                        //defaultValue={clientRefNo}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <InputUI
                            name="caseId"
                            label="Client Reference Number"
                            disabled
                            type="text"
                            value={value ? value : ""}
                            setValue={onChange}
                            variant="outlined"
                            register={register}
                            cssStyles={{
                              width: "100%",
                            }}
                          />
                        )}
                      />
                    </div>
                  </div>

                  <div
                    className="field_wrapper"
                    style={{ marginTop: "15px", marginBottom: "15px" }}
                  >
                    <div className="field">
                      <Controller
                        name="name"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <InputUI
                            name="name"
                            label="Document Name"
                            type="text"
                            value={value ? value : ""}
                            setValue={onChange}
                            variant="outlined"
                            register={register}
                            cssStyles={{
                              width: "100%",
                            }}
                            disabled={true}
                          />
                        )}
                      />
                      <span className="error-message">
                        {errors["name"]?.message}
                      </span>
                    </div>
                  </div>
                  <div className="field_wrapper">
                    <div className="field">
                      <Controller
                        name="category"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <InputUI
                            name="category"
                            label="Category"
                            disabled
                            type="text"
                            value={value ? value : ""}
                            variant="outlined"
                            register={register}
                            cssStyles={{
                              width: "100%",
                            }}
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div
                    className="field_wrapper"
                    style={{ marginTop: "15px", marginBottom: "5px" }}
                  >
                    <div className="field">
                      <FormControl className="" fullWidth>
                        <Controller
                          control={control}
                          name="subcategory"
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              options={subCategoryOption}
                              getOptionLabel={(option) =>
                                option.fields_name || ""
                              }
                              filterOptions={filterOptions}
                              onChange={(e, value) => {
                                onChange(value ? value.field_setting_id : "");
                              }}
                              value={
                                subCategoryOption?.find(
                                  (option) => option.field_setting_id === value
                                ) || null
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Sub Category"
                                  variant="outlined"
                                />
                              )}
                            />
                          )}
                        />
                      </FormControl>
                      {/* <FormControl fullWidth>
                          <InputLabel id="select-label">
                            Sub Category
                          </InputLabel>
                          <Controller
                            control={control}
                            name="subcategory"
                            defaultValue={subCategory || ""}
                            render={({ field: { onChange, value } }) => (
                              <SelectBox
                                name="subcategory"
                                labelId="select-label"
                                variant="outlined"
                                id="simple-select"
                                value={value || ""}
                                onChange={onChange}
                                label="Sub Category"
                                {...register("subcategory")}
                              >
                                {subCategoryOption?.map((category) => (
                                  <MenuItem
                                    key={category.field_setting_id}
                                    value={category.field_setting_id}
                                  >
                                    {category.fields_name}
                                  </MenuItem>
                                ))}
                              </SelectBox>
                            )}
                          />
                        </FormControl> */}
                      <span className="error-message">
                        {errors["subcategory"]?.message}
                      </span>
                    </div>
                  </div>
                  <div className="field_wrapper">
                    <div className="field">
                      <TextField
                        name="description"
                        label="Description"
                        multiline
                        minRows={3}
                        maxRows={3}
                        value={description || ""}
                        onChange={(e) => setDescription(e.target.value)}
                        variant="outlined"
                        style={{ marginTop: "15px", marginBottom: "15px" }}
                        fullWidth
                      />
                    </div>
                  </div>
                  <ButtonUI
                    variant="contained"
                    buttonText="Submit"
                    handleSubmit={handleSubmit}
                    onFormSubmit={onEditDocumentFormSubmit}
                  />
                </div>
              </div>
              <div
                className={
                  isPdfDetailsContainerOpen
                    ? "pdf-details-container min-h-[65vh] max-h-[65vh] min-w-[0%] mr-[35px]"
                    : "hide-pdf-details-container"
                }
              >
                <div className="pdf-details-header">
                  <div className="pdf-details-header-icon">
                    <IoDocumentText />
                  </div>
                  <div className="pdf-details-header-title">
                    Document Details
                  </div>
                  <div
                    className="close-pdf-details-container-icon"
                    onClick={changePdfDetailsContainerVisibility}
                  >
                    <RiCloseCircleFill />
                  </div>
                </div>
                <div className="pdf-details-content">
                  <PdfDetails
                    pdfData={pdfData}
                    documentID={docData?.documentID}
                    documentTitle={pdfViewerTitle}
                    documentType={docData?.document_type}
                    pdfDetailsVersionData={pdfDetailsVersionData}
                    documentVersionId={documentVersionId}
                    setDocumentVersionId={setDocumentVersionId}
                    // document_version={document_version}
                  />
                </div>
              </div>
            </div>
          ) : !pdfDataLoading ? (
            <div className="all-document-pdf-editor">
              <div
                onClick={() => setPdfEditor(false)}
                className="all-document-pdf-editor-icon"
                title="Back"
              >
                <IoArrowBackCircleSharp />
              </div>
              <div>
                <PdfEditor
                  pdfBase64={pdfBase64}
                  documentID={documentId}
                  documentTitle={pdfViewerTitle}
                  setEditor={setPdfEditor}
                  docData={docData}
                  changeDocumentViewerState={changeDocumentViewerState}
                />
              </div>
            </div>
          ) : (
            <Loading />
          )}
        </>
      )}
    </>
  );
};

export default AllDocumentsList;
