import React, { useState, useContext, useEffect } from "react";
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from "react-icons/md";
import { AuthContext } from "../../context/Auth/AuthContext";
import pdfVersionDetails from "../../api/pdfVersionDetails";

const PdfDetails = ({
  pdfData,
  documentTitle,
  documentID,
  documentType,
  pdfDetailsVersionData,
  documentVersionId,
  setDocumentVersionId,
  document_version,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const [documentVersionResponse, setDocumentVersionResponse] = useState([]);
  const [documentVersionCount, setDocumentVersionCount] = useState(0);
  const togglePdfDetailsVisibility = () => setIsOpen(!isOpen);
  const { token } = useContext(AuthContext);

  // Fetches and updates document versions and their count.
  const documentVersionResponseFunc = async () => {
    const response = await pdfVersionDetails(token, documentID);
    if (response.code === 200) {
      setDocumentVersionResponse(response?.document_data?.document_versions);
      setDocumentVersionCount(response?.document_count);
    }
  };

  const handleViewPDF = (document_version_id) => {
    const versionId = document_version_id || "original_document";
    setDocumentVersionId(versionId);
  };

  const updatePdfDetailsVersionData = function () {
    pdfDetailsVersionData();
  };

  // Updates PDF details if documentVersionId changes and resets it on unmount.
  useEffect(() => {
    if (documentVersionId) {
      updatePdfDetailsVersionData();
    }
    return () => {
      setDocumentVersionId(null);
    };
  }, [documentVersionId]);

  // Fetches document version details when the component opens (isOpen changes).
  useEffect(() => {
    documentVersionResponseFunc();
  }, [isOpen]);

  return (
    <>
      <div className="document-details-container">
        <div
          className="document-details-header"
          onClick={togglePdfDetailsVisibility}
        >
          <div className="document-details-title">Document Details</div>
          <div className="document-details-visibility-icon">
            {isOpen ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
          </div>
        </div>
        {documentVersionResponse.map((version) => {
          return (
            <>
              {isOpen && documentVersionCount > 0 ? (
                <div className="document-details-inner-container">
                  <div>
                    <div className="document-details-content">
                      <div>
                        <span className="document-details-data">
                          Document Name
                        </span>
                        <span
                          className="document-cursor-pointer document-details-data-value"
                          onClick={() => {
                            handleViewPDF(version.document_version_id);
                          }}
                        >
                          {documentTitle}
                        </span>
                      </div>
                      <div>
                        <span className="document-details-data">
                          Document Type
                        </span>
                        <span className="document-details-data-value">
                          {documentType}
                        </span>
                      </div>
                      <div>
                        <span className="document-details-data">
                          Document Size
                        </span>
                        <span className="document-details-data-value">
                          {version.pdf_size || "N/A"}
                        </span>
                      </div>
                      <div>
                        <span className="document-details-data">
                          Document Version
                        </span>
                        <span className="document-details-data-value">
                          {version.document_version == 0
                            ? "Original Document"
                            : version.document_version}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </>
          );
        })}
      </div>
    </>
  );
};

export default PdfDetails;
