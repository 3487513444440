//(components/all-case-list/case-list-table/DocumentViewer.jsx)

import React, { useState, useContext, useEffect } from "react";
import { FaEye } from "react-icons/fa";
import { toast } from "react-toastify";
import { TiDeleteOutline } from "react-icons/ti";

import ButtonUI from "../../material-ui/ButtonUI";
import { AuthContext } from "../../../context/Auth/AuthContext";
import "../../../styles/LinkPopup.css";
import { MdError } from "react-icons/md";
import pdfDetails from "../../../api/pdfDetails";

/*** Component for viewing the document in pdf viewer in mailroom section***/
const DocumentViewer = ({
  tableValues,
  changeDocumentViewerState,
  setDocData,
}) => {
  const document_type = tableValues?.document_type;
  const documentID = tableValues?.documentID;
  const document_name = tableValues?.document_name;

  const { token } = useContext(AuthContext);

  // const documentData = async function () {
  //   try {
  //     const response = await pdfDetails(token, documentID, "documents");
  //     const data = await response.data;
  //     return data[0]?.pdflink;
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };

  // const handleClick = async function () {
  //   toast(
  //     ({ closeToast }) => (
  //       <div className="w-fit h-[150px] p-2">
  //         <span className="flex gap-3 items-center">
  //           <MdError className="text-[#007bffbf] size-8 mb-1" />
  //           <span className="text-[#007bffbf] text-xl font-semibold">Note</span>
  //         </span>
  //         <div>
  //           <p className="text-base text-gray-600">
  //             This file format is not supported by the Document Viewer. The file
  //             will be downloaded to your Downloads folder, you can open it from
  //             there.
  //           </p>
  //         </div>
  //       </div>
  //     ),
  //     {
  //       autoClose: 8000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //       position: "top-center",
  //       className: "!border !border-2 !border-[#007bffbf] !w-[400px]",
  //       progressStyle: {
  //         background: "#007bff",
  //         height: "3px",
  //       },
  //     }
  //   );
  //   const pdfLink = await documentData();

  //   const link = document.createElement("a");
  //   link.href = pdfLink;
  //   link.download = document_name || "download";
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // };
  return (
    <div className="single-link-popup-container">
      <ButtonUI
        formButton={false}
        variant="outlined"
        buttonText={<FaEye />}
        style={{
          backgroundColor: "#ffc107",
          color: " white",
          marginRight: "5px",
          padding: "8px",
          minWidth: "fit-content",
          border: "none",
        }}
        onClickFunc={() => {
          setDocData(tableValues);
          changeDocumentViewerState(
            tableValues.client_reference_number,
            tableValues?.contact_id,
            tableValues.document_name,
            tableValues.category,
            tableValues.document_sub_category,
            tableValues.document_desc,
            tableValues.documentID,
            tableValues.document_type,
            tableValues.phone_number,
            tableValues.ben_id
          );
        }}
      />
    </div>
  );
};

export default DocumentViewer;
