const baseUrl = "https://dmsapi.consumerlaw.com/public"; //PROD
// const baseUrl = "https://admindms.consumerlaw.com/public"; //DEV

/*////////////////////////////////////////////////////////////////////////

// export const sugarUrl = "https://sugar.consumerlaw.com/rest/v11_20";

// export const sugarUrl = "https://admindms.consumerlaw.com/public/api"; //dev
// export const sugarUrl = "https://uat.consumerlaw.com/rest/v11_20";

// export const sugarUrl = "https://uat.consumerlaw.com/rest/v11_20";

/*/ ///////////////////////////////////////////////////////////////////////
export default baseUrl;
