import React, { useContext, useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { Grid, TextField } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputUI from "../material-ui/InputUI";
//import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import Autocomplete from "@mui/material/Autocomplete";

const AddTaskForm = ({ register, control, errors, typeOptions, userList }) => {
  const priorityOptions = [
    {
      name: "High",
      value: "High",
    },
    {
      name: "Medium",
      value: "Medium",
    },
    {
      name: "Low",
      value: "Low",
    },
  ];

  return (
    <Grid className="edit-document">
      <div className="edit-document-form grid grid-cols-1 gap-3 sm:grid-cols-2">
        <div className="field_wrapper">
          <div className="field">
            <Controller
              name="taskSubject"
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputUI
                  name="taskSubject"
                  label="Task Subject"
                  type="text"
                  value={value && value !== null ? value : ""}
                  setValue={onChange}
                  variant="outlined"
                  cssStyles={{
                    margin: "10px 0px",
                    width: "100%",
                  }}
                  register={register}
                />
              )}
            />
            <span className="error-message">
              {errors["taskSubject"]?.message}
            </span>
          </div>
        </div>

        <div className="field_wrapper">
          <div className="field">
            <Controller
              name="dueDate"
              control={control}
              render={({ field: { onChange, value } }) => (
                // <DatePicker
                // 	label="Due Date"
                // 	value={value ? value : null}
                // 	sx={{ marginTop: "10px" }}
                // 	onChange={onChange}
                // 	renderInput={(params) => (
                // 		<TextField {...params} variant="outlined" fullWidth />
                // 	)}
                // />
                <DateTimePicker
                  label="Due Date and Time"
                  value={value ? value : null}
                  sx={{ marginTop: "10px" }}
                  onChange={(newValue) => {
                    onChange(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" fullWidth />
                  )}
                />
              )}
            />
            <span className="error-message">{errors["dueDate"]?.message}</span>
          </div>
        </div>

        <div className="field_wrapper">
          {/* <FormControl fullWidth>
            <InputLabel id="select-label">Type</InputLabel>
            <Controller
              control={control}
              name="type"
              render={({ field: { onChange, value } }) => (
                <Select
                  name="type"
                  labelId="select-label"
                  variant="outlined"
                  id="simple-select"
                  value={value ? value : ""}
                  onChange={onChange}
                  label="Type"
                  {...register("type")}
                >
                  {typeOptions?.map((type) => (
                    <MenuItem key={type.id} value={type.type}>
                      {type.type}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl> */}

          <FormControl fullWidth>
            <Controller
              control={control}
              name="type"
              render={({ field: { onChange, onBlur, value } }) => (
                <Autocomplete
                  id="autocomplete-type"
                  options={Array.isArray(typeOptions) ? typeOptions : []}
                  getOptionLabel={(option) => option || ""}
                  value={
                    typeOptions?.find((option) => option === value) || null
                  }
                  onChange={(event, newValue) => {
                    // Pass the selected value to onChange to update react-hook-form state
                    onChange(newValue ? newValue : "");
                  }}
                  onBlur={onBlur}
                  filterOptions={(options, state) => {
                    const inputValue = state.inputValue.toLowerCase();
                    const exactMatches = options.filter(
                      (option) => option.toLowerCase() === inputValue
                    );
                    const inclusiveMatches = options.filter((option) =>
                      option.toLowerCase().includes(inputValue)
                    );
                    return [...exactMatches, ...inclusiveMatches];
                  }}
                  isOptionEqualToValue={(option, value) => {
                    if (typeof value === "string") {
                      return option.toLowerCase() === value.toLowerCase();
                    }
                    return false;
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Type" variant="outlined" />
                  )}
                />
              )}
            />
          </FormControl>
          <span className="error-message">{errors["type"]?.message}</span>
        </div>
        <div className="field_wrapper">
          <FormControl fullWidth>
            <Controller
              control={control}
              name="assignedUser"
              render={({ field: { onChange, onBlur, value } }) => (
                <Autocomplete
                  id="autocomplete-assigned-user"
                  options={userList || []}
                  getOptionLabel={(option) => option.name || ""}
                  value={
                    userList?.find((option) => option.id === value) || null
                  }
                  onChange={(event, newValue) => {
                    // Pass the selected value to onChange to update react-hook-form state
                    onChange(newValue ? newValue.id : "");
                  }}
                  onBlur={onBlur}
                  filterOptions={(options, state) => {
                    const inputValue = state.inputValue;
                    let filteredOptions = options;
                    if (inputValue) {
                      const lowerCaseInputValue = inputValue.toLowerCase();
                      const exactMatches = options.filter((option) => {
                        if (option.name) {
                          return (
                            option.name.toLowerCase() === lowerCaseInputValue
                          );
                        }
                        return false;
                      });
                      const inclusiveMatches = options.filter((option) => {
                        if (option.name) {
                          return option.name
                            .toLowerCase()
                            .includes(lowerCaseInputValue);
                        }
                        return false;
                      });
                      filteredOptions = [...exactMatches, ...inclusiveMatches];
                    }
                    return filteredOptions;
                  }}
                  isOptionEqualToValue={(option, value) => {
                    return option.id === value;
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Assigned Name"
                      variant="outlined"
                    />
                  )}
                />
              )}
            />
          </FormControl>
          <span className="error-message">
            {errors["assignedUser"]?.message}
          </span>
        </div>
        <div className="field_wrapper">
          <FormControl fullWidth>
            <InputLabel id="select-label">Priority</InputLabel>
            <Controller
              control={control}
              name="priority"
              render={({ field: { onChange, value } }) => (
                <Select
                  name="priority"
                  labelId="select-label"
                  variant="outlined"
                  id="simple-select"
                  value={value ? value : ""}
                  onChange={onChange}
                  label="Priority"
                  {...register("priority")}
                >
                  {priorityOptions?.map((priority) => (
                    <MenuItem key={priority.name} value={priority.value}>
                      {priority.value}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
          <span className="error-message">{errors["priority"]?.message}</span>
        </div>
      </div>
      <div className="field_wrapper">
        <div className="field">
          <Controller
            name="taskDesc"
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputUI
                name="taskDesc"
                label="Description"
                type="text"
                value={value && value !== null ? value : ""}
                setValue={onChange}
                variant="outlined"
                cssStyles={{
                  margin: "10px 0px",
                  width: "100%",
                }}
                register={register}
              />
            )}
          />
        </div>
      </div>
    </Grid>
  );
};

export default AddTaskForm;
