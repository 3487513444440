export const msalConfig = {
  auth: {
    // clientId: "8eea82c4-f86f-4164-92e7-11b300837827",
    clientId: "eef56756-c161-4d48-904a-05a94ce0e27c",
    // authority:
    // 	"https://login.microsoftonline.com/afdd2115-db40-4711-bd96-fee5c7f7e6ec", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    authority:
      "https://login.microsoftonline.com/352ff83e-d8bb-43f0-bd09-7822dab5c21d", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})

    // // PROD
    redirectUri: "https://dms.consumerlaw.com/callback",

    // // DEV
    // redirectUri: "https://ctaxdms.nablasol.net/callback",

    // // Local
    // redirectUri: "http://localhost:3000/callback",
  },
  cache: {
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ["User.Read"],
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};
