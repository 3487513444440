import baseUrl from "../config/baseUrl";

const addDocumentRequestSettings = async ({
  token,
  userId,
  document_name,
  document_desc,
  client_type,
  service_sub_type_c,
  request_type,
  // document_stage,
  ocr_status,
  status,
  is_admin,
  //sample_document_link,
}) => {
  const formData = new FormData();
  formData.append("userId", userId);
  formData.append("document_name", document_name);
  formData.append("document_desc", document_desc);
  formData.append("client_type", client_type);
  formData.append("service_sub_type_c", service_sub_type_c);
  formData.append("request_type", request_type);
  // formData.append("document_stage", document_stage);
  formData.append("ocr_status", ocr_status);
  formData.append("status", status);
  formData.append("is_admin", is_admin);
  // if (sample_document_link !== undefined) {
  //   formData.append("sample_document_link", sample_document_link);
  // }

  try {
    const response = await fetch(
      `${baseUrl}/api/add-document-request-setting-data`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    return error;
  }
};

export default addDocumentRequestSettings;
