import React, { useContext, useState } from "react";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Grid, Paper } from "@mui/material";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import AvatarUI from "../components/material-ui/AvatarUI";
import InputUI from "../components/material-ui/InputUI";
import CheckboxUI from "../components/material-ui/CheckboxUI";
import ButtonUI from "../components/material-ui/ButtonUI";
import { AuthContext } from "../context/Auth/AuthContext";
import loginUser from "../api/login";
import loginIllustration from "../images/data.png";
import illustrationBg from "../images/illustration_bg.png";
import main_bg from "../images/login_bg.png";
import "../styles/Login.css";
import { useMsal } from "@azure/msal-react";

const loginSchema = yup.object().shape({
  email: yup.string().email().required("Email is required."),
  password: yup.string().min(6).max(16).required("Password is required."),
});

/*** Main component for showing the login form ***/
const Login = () => {
  const { instance } = useMsal();
  const redirect = useNavigate();
  const [loading, setLoading] = useState(false);

  const { dispatch } = useContext(AuthContext);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(loginSchema),
  });

  const onLoginFormSubmit = async (loginFormData) => {
    setLoading(true);
    const loginResponse = await loginUser({
      email: loginFormData.email,
      password: loginFormData.password,
    });

    //console.log(dispatch);
    if (loginResponse?.token) {
      setLoading(false);
      dispatch({
        type: "LOGIN",
        payload: {
          token: loginResponse.token,
          id: loginResponse.user.id,
          user_id: loginResponse.user.user_id,
          firstName: loginResponse.user.first_name,
          lastName: loginResponse.user.last_name,
          phoneNumber: loginResponse.user.phonenumber,
          is_admin: loginResponse.user.is_admin,
        },
      });
      toast.success("Successfully Logged In", {
        position: "top-center",
        autoClose: 3000,
        theme: "dark",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      navigate("/dashboard");
    }
    if (loginResponse?.message) {
      setLoading(false);
      toast.error(loginResponse?.message, {
        position: "top-center",
        autoClose: 5000,
        theme: "dark",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    if (loginResponse?.errors?.email[0] || loginResponse?.errors?.password[0]) {
      setLoading(false);
      toast.error("Every field is required", {
        position: "top-center",
        autoClose: 5000,
        theme: "dark",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  function handleLogin(instance) {
    instance
      .loginPopup()
      .then((data) => {
        redirect("/callback");
      })
      .catch((e) => {
        console.error(e);
      });
  }

  return (
    <Grid className="login" style={{ backgroundImage: `url(${main_bg})` }}>
      <div className="login-container">
        <div
          className="illustration_wrapper"
          style={{ backgroundImage: `url(${illustrationBg})` }}
        >
          <img src={loginIllustration} />
        </div>
        <div className="login_form_wrapper">
          <AvatarUI>
            <AccountCircleRoundedIcon />
          </AvatarUI>
          <h2 className="text-3xl mb-4">Hello Again!</h2>

          <div className="login_btn">
            <ButtonUI
              formButton={false}
              variant="contained"
              buttonText="Login"
              onClickFunc={() => handleLogin(instance)}
            />
          </div>
        </div>
      </div>
    </Grid>
    // <Grid className="login" style={{ backgroundImage: `url(${main_bg})` }}>
    // 	<div className="login-container">
    // 		<div
    // 			className="illustration_wrapper"
    // 			style={{ backgroundImage: `url(${illustrationBg})` }}
    // 		>
    // 			<img src={loginIllustration} />
    // 		</div>
    // 		<div className="login_form_wrapper">
    // 			<AvatarUI>
    // 				<AccountCircleRoundedIcon />
    // 			</AvatarUI>
    // 			<h2>Hello Again!</h2>
    // 			<InputUI
    // 				name="email"
    // 				label="Enter your Email ID"
    // 				type="email"
    // 				variant="outlined"
    // 				fullWidth={true}
    // 				required={true}
    // 				cssStyles={{ margin: "15px 0px 0px" }}
    // 				register={register}
    // 			/>
    // 			<span className="error-message">{errors["email"]?.message}</span>
    // 			<InputUI
    // 				name="password"
    // 				label="Enter your Password"
    // 				type="password"
    // 				variant="outlined"
    // 				fullWidth={true}
    // 				required={true}
    // 				cssStyles={{ margin: "15px 0px 0px" }}
    // 				register={register}
    // 			/>
    // 			<span className="error-message">{errors["password"]?.message}</span>
    // 			{/* <div className="login-remember-me">
    //         <CheckboxUI label="Remember Me" />
    //         <Link to="/forgot-password" className="go-to-password-reset-form">
    //           &nbsp;Forgot Password?
    //         </Link>
    //       </div> */}
    // 			<div className="login_btn">
    // 				<ButtonUI
    // 					loading={loading}
    // 					variant="contained"
    // 					buttonText="Login"
    // 					style={{
    // 						width: "150px",
    // 						height: "35px",
    // 						backgroundColor: "#007bff",
    // 						boxShadow: "none",
    // 						border: "1px solid #007bff",
    // 						fontWeight: "500",
    // 						borderRadius: "4px",
    // 						letterSpacing: "0.07rem",
    // 						marginTop: "20px",
    // 					}}
    // 					handleSubmit={handleSubmit}
    // 					onFormSubmit={onLoginFormSubmit}
    // 				/>
    // 			</div>
    // 			{/* <div className="login-new-user">
    //         New User?{" "}
    //         <Link to="/register" className="go-to-register-form">
    //           &nbsp;Register
    //         </Link>
    //       </div> */}
    // 		</div>
    // 	</div>
    // </Grid>
  );
};

export default Login;
