import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Cross2Icon } from "@radix-ui/react-icons";
import { Button } from "../../shadcn-components/ui/button";
import DataTableFacetedFilter from "../common/DataTableFacetedFilter";
import { useFetchFilters } from "../../hooks/useFetchFilters";
import baseUrl from "../../config/baseUrl";
import { CalendarIcon } from "lucide-react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../shadcn-components/ui/popover";
import { Calendar } from "../../shadcn-components/ui/calendar";
import { format } from "date-fns";

const DataToolbar = ({
  table,
  apiUrl,
  clientID,
  clientTypeOptions,
  createdAt,
  updatedAt,
  setCreatedAt,
  setUpdatedAt,
  createdAtLabel,
  UpdatedAtLabel,
}) => {
  const clientCategories =
    Array.isArray(clientTypeOptions) && clientTypeOptions.length > 0
      ? clientTypeOptions.map((item) => item.label)
      : [];

  const { id, is_admin, token } = useContext(AuthContext);
  const [category, setCategory] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [clientCategoryandSubCategory, setClientCategoryandSubCategory] =
    useState({
      category: [],
      subCategory: [],
    });
  const [isLoading, setIsLoading] = useState(true);
  const roles = ["admin", "non_admin"];
  const clientQuery = clientID ? `&contact_id=${clientID}` : "";

  const [data, setParams, loading] = useFetchFilters(
    `${baseUrl}${apiUrl}?isAdmin=${is_admin}&userId=${id}${clientQuery}`
  );

  const isFiltered = table.getState().columnFilters.length > 0;

  const document_types = [
    "pdf",
    "docx",
    "jpeg",
    "jpg",
    "png",
    "txt",
    "xls",
    "xlsx",
    "mp4",
    "zip",
    "rar",
  ];

  // Client Type
  const fields_name = [
    "Bankruptcy",
    "CriminalDefense",
    "Family",
    "Field Data",
    "Immigration",
    "LaborLaw",
    "PersonalInjury",
    "Scan",
  ];

  const document_status = [
    "Partially Uploaded",
    "Uploaded",
    "Requested",
    "Rejected",
  ];
  const status = ["Active", "Inactive"];
  const request_type = ["Required", "Recommended"];
  const field_type = [
    "client_type",
    "client_subtype",
    "document_type",
    "document_subtype",
  ];
  const clientTypes = ["primary", "beneficiary"];

  const filterColumns = table
    .getAllColumns()
    .filter((column) => column.columnDef?.filterable)
    .map((column) => ({
      id: column.id,
      displayName: column.columnDef.displayName,
    }));

  // Created At & Modified At filtering states

  const handleResetFilter = () => {
    table.resetColumnFilters();
    setParams({});
    if (setCreatedAt) {
      setCreatedAt(null);
    }

    if (setUpdatedAt) {
      setUpdatedAt(null);
    }
  };

  // Sets createdAt to the selected date (end of day) or null.
  const handleCreatedAtSelect = (date) => {
    if (date) {
      const createdAt = new Date(date);
      createdAt.setHours(23, 59, 59);
      setCreatedAt(createdAt);
    } else {
      setCreatedAt(null);
    }
  };

  // Sets updatedAt to the selected date (end of day) or null.
  const handleUpdatedAtSelect = (date) => {
    if (date) {
      const updatedAt = new Date(date);
      updatedAt.setHours(23, 59, 59);
      setUpdatedAt(updatedAt);
    } else {
      setUpdatedAt(null);
    }
  };

  const fetchCategory = async () => {
    try {
      setIsLoading(true);
      const res = await fetch(
        `${baseUrl}/api/get-document-filter-name?${is_admin}=1&userId=${id}`
      );

      const data = await res.json();

      setCategory(data.category);
      setSubCategory(data.subcategory);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchClientCategoryAndSubCategory = async () => {
    try {
      setIsLoading(true);
      const res = await fetch(
        `${baseUrl}/api/get-client-filter-name?${is_admin}=1&userId=${id}`
      );

      const data = await res.json();

      setClientCategoryandSubCategory({
        category: [...data.clientSubType],
        subCategory: [...data.clientType],
      });
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchCategory();
    fetchClientCategoryAndSubCategory();
  }, []);

  return (
    <div className="my-2 flex gap-2 items-center flex-wrap">
      <p className="text-sm text-muted-foreground uppercase">Filter by</p>

      {filterColumns?.map((column) => {
        let columnOptions = data[column?.id] || [];
        if (column?.id === "doc_category") columnOptions = category;
        if (column?.id === "fields_name") columnOptions = fields_name;
        if (column?.id === "document_subtype") columnOptions = subCategory;
        if (column?.id === "is_admin") columnOptions = roles;
        if (column?.id === "document_type") columnOptions = document_types;
        if (column?.id === "case_client_type")
          columnOptions = clientCategoryandSubCategory.category;
        if (column?.id === "case_sub_type")
          columnOptions = clientCategoryandSubCategory.subCategory;
        if (column?.id === "document_status") columnOptions = document_status;
        if (column?.id === "fields_tab_type") columnOptions = field_type;
        if (column?.id === "contact_type") columnOptions = clientTypes;
        if (column?.id === "status") columnOptions = status;
        if (column?.id === "request_type") columnOptions = request_type;
        if (column?.id === "category_name") columnOptions = clientCategories;

        return (
          <DataTableFacetedFilter
            key={column.id}
            column={table.getColumn(column.id)}
            title={column.displayName}
            table={table}
            options={columnOptions}
            setParams={setParams}
            loading={loading}
            isLoading={isLoading}
          />
        );
      })}

      {
        // Date filters for "Created At" and "Modified At" using a calendar popover.

        <div className="flex items-center gap-2">
          {/* Created At Filter */}
          {setCreatedAt && (
            <Popover>
              <PopoverTrigger asChild>
                <Button
                  variant="outline"
                  className="flex items-center gap-2 px-2"
                >
                  <CalendarIcon className="w-4 h-4" />
                  {createdAt ? (
                    <span className="text-xs">
                      {format(createdAt, "yyyy-MM-dd")}
                    </span>
                  ) : (
                    <span className="text-xs">
                      {createdAtLabel ? createdAtLabel : "Created At"}
                    </span>
                  )}
                </Button>
              </PopoverTrigger>
              <PopoverContent align="start">
                <Calendar
                  mode="single"
                  selected={createdAt}
                  onSelect={handleCreatedAtSelect}
                  disabled={(date) => date > new Date()}
                />
              </PopoverContent>
            </Popover>
          )}

          {/* Modified At Filter */}
          {setUpdatedAt && (
            <Popover>
              <PopoverTrigger asChild>
                <Button
                  variant="outline"
                  className="flex items-center gap-2 px-2"
                >
                  <CalendarIcon className="w-4 h-4" />
                  {updatedAt ? (
                    <span className="text-xs">
                      {format(updatedAt, "yyyy-MM-dd")}
                    </span>
                  ) : (
                    <span className="text-xs">
                      {UpdatedAtLabel ? UpdatedAtLabel : "Modified At"}
                    </span>
                  )}
                </Button>
              </PopoverTrigger>
              <PopoverContent align="start">
                <Calendar
                  mode="single"
                  selected={updatedAt}
                  onSelect={handleUpdatedAtSelect}
                  disabled={(date) => date > new Date()}
                />
              </PopoverContent>
            </Popover>
          )}
        </div>
      }

      {(isFiltered || createdAt || updatedAt) && (
        <Button
          variant="ghost"
          onClick={handleResetFilter}
          className="h-8 px-2 lg:px-3"
        >
          Reset
          <Cross2Icon className="ml-2 h-4 w-4" />
        </Button>
      )}
    </div>
  );
};

export default DataToolbar;
