const AuthReducer = (state, action) => {
	switch (action.type) {
		case "LOGIN": {
			return {
				token: action.payload.token,
				id: action.payload.id,
				user_id: action.payload.user_id,
				firstName: action.payload.firstName,
				lastName: action.payload.lastName,
				phoneNumber: action.payload.phoneNumber,
				is_admin: action.payload.is_admin,
			};
		}

		case "LOGOUT": {
			return {
				token: null,
				id: null,
				user_id: null,
				firstName: null,
				lastName: null,
				phoneNumber: null,
				is_admin: null,
			};
		}

		default:
			return state;
	}
};

export default AuthReducer;
