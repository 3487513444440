import React, { useState, useEffect, useContext } from "react";
import RequestIcon from "../../../images/mdi_invoice-new-outline.svg";
import UploadIcon from "../../../images/uil_file-upload-alt.svg";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import requestedDocumentList from "../../../api/requestedDocumentList";
import { AuthContext } from "../../../context/Auth/AuthContext";
import Loading from "../../material-ui/Loading";
import RequestedDocumentsTable from "./RequestedDocumentsTable";
import RequestDocumentsPopup from "./RequestDocumentsPopup";
import AddBeneficiaryPopup from "../client-documents/all-documents/AddBeneficiaryPopup";
import UploadDocumentsPopup from "./all-documents/UploadDocumentsPopup";
import baseUrl from "../../../config/baseUrl";
import getAllFieldTypeList from "../../../api/getAllFieldTypeList";
import { BsFillCloudArrowUpFill, BsCloudArrowUp } from "react-icons/bs";
import DocumentTable from "./all-documents/uploaded/DocumentTable";
import { RiCloseCircleFill } from "react-icons/ri";
import { TbCircleCheck } from "react-icons/tb";
import { TiDeleteOutline } from "react-icons/ti";
import { Button } from "../../../shadcn-components/ui/button";
import BeneficiaryTable from "./all-documents/BeneficiaryTable";
import { FilePenLineIcon, FolderUp, UsersRound } from "lucide-react";
import ButtonUI from "../../material-ui/ButtonUI";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import TextField from "@mui/material/TextField";
import InputUI from "../../material-ui/InputUI";
import SelectBox from "@mui/material/Select";
import getClientRefNo from "../../../api/getClientRefNo";
import { toast } from "react-toastify";
import editDocument from "../../../api/editDocument";
import clientsData from "../../../api/clients";
import getClientName from "../../../api/getClientName";
import { useDebounce } from "use-debounce";
import BeneficiaryTabList from "./all-documents/beneficiary/BeneficiaryTabList";
import PDFViewer from "./PDFViewer";
import { useParams } from "react-router-dom";
import { useRoleContext } from "../../../context/RolesContext";

const updateDocumentDetailsFormSchema = yup.object().shape({
  name: yup.string().required("Document Name is required field"),
  subcategory: yup
    .string()
    .nullable()
    .required("Sub Category is required field"),
});

/*** Component for listing all the document requests in table and for listing all uploaded documents in another table ***/
const RequestedDocumentsList = ({
  clientID,
  clientEmail,
  clientType,
  clientDocumentsType,
  selectedClientDocumentsType,
  changeSelectedClientDocumentsType,
  setShowClientIdSearch,
  showUploads,
  beneficiary,
  typeOptions,
  clientSubType,
  clientPhone,
}) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const [bulkDeleteRequests, setBulkDeleteRequests] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentUploadPage, setCurrentUploadPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [columnFilters, setColumnFilters] = useState([]);
  const [pageSize, setPageSize] = useState(20);

  const [documentViewer, setDocumentViewer] = useState(false);
  const [pdfLink, setPdfLink] = useState("");
  const [documentType, setDocumentType] = useState("");
  const [pdfViewerTitle, setPdfViewerTitle] = useState("");

  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [name, setName] = useState("");
  const [docCaseID, setDocCaseID] = useState("");
  const [docReqID, setDocReqID] = useState("");
  const [reqStatus, setReqStatus] = useState("");
  const [description, setDescription] = useState("");
  const [sorting, setSorting] = useState([]);
  const [emailsToBeSent, setEmailsToBeSent] = useState("");
  const [documentID, setDocumentID] = useState("");

  const [selectedRequestLabel, setSelectedRequestLabel] = useState("");
  const [ocrData, setOcrData] = useState([]);
  let { cid, bid } = useParams();
  let reqBenId = null; //localStorage.getItem("reqBenId");
  // Check if `reqBenId` is the string 'null' or if it's actually null
  if (bid !== "null" && bid !== null && cid === clientID) {
    reqBenId = bid; // Set `reqBenId` to null if it's 'null' or already null
  }

  const [selectedTab, setSelectedTab] = useState(reqBenId);

  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(updateDocumentDetailsFormSchema),
  });

  // Email validation function
  function isValidEmail(email) {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  }

  // useEffect to validate email and update state
  useEffect(() => {
    if (isValidEmail(clientEmail)) {
      setEmailsToBeSent(clientEmail);
    }
  }, [clientEmail]);

  const getRequestNameDetails = async (reqId) => {
    const url = `${baseUrl}/api/get-request-name?document_request_id=${reqId}`;
    const res = await clientsData({ token, api: url });
    setSelectedRequestLabel(res.data[0].document_name);
  };

  const changeDocumentViewerState = (
    id,
    requestId,
    requestStatus,
    link,
    data,
    name,
    category,
    description,
    document_sub_category,
    document_type
  ) => {
    setSelectedRequestLabel("");
    setDocumentType(document_type);
    setPdfLink(link);
    setData(data);
    setName(name);
    setDocCaseID(id);
    setDocReqID(requestId);
    if (requestId) {
      getRequestNameDetails(requestId);
    }
    setReqStatus(requestStatus);
    setDescription(description);
    setCategory(category);
    setSubCategory(document_sub_category);
    setPdfViewerTitle(name);
    setDocumentViewer(!documentViewer);
    setValue("addClientName", clientID);
  };

  const [selectedDocumentCategoryArray, setSelectedDocumentCategoryArray] =
    useState(selectedClientDocumentsType?.toLowerCase());
  const [categoryUpdated, setCategoryUpdated] = useState(0);
  const [documentUpdatedToTrue, setDocumentUpdatedToTrue] = useState(0);
  const [globalFilter, setGlobalFilter] = useState("");
  const [debouncedGlobalFilter] = useDebounce(globalFilter, 1000);

  const [selectedClientId, setSelectedClientId] = useState({
    primary: true,
    id: clientID,
    email: clientEmail,
    phone_number: clientPhone,
  });

  const [documentResponse, setDocumentResponse] = useState([]);
  const [selectedDocumentRequestedType, setSelectedDocumentRequestedType] =
    useState(beneficiary ? "allBeneficiary" : "uploaded");

  const [requestedDocumentType, setRequestedDocumentType] = useState("all");
  const [contactBeneficiary, setContactBeneficiary] = useState(beneficiary);
  const [beneficiaryAdded, setBeneficiaryAdded] = useState(false);
  const [globalSearch, setGlobalSearch] = useState("");

  let searchValue = globalSearch ? `&global_search=${globalSearch}` : "";
  let docStatus = `&document_status=${selectedDocumentRequestedType}`;
  let docCategory = `&document_category=${selectedDocumentCategoryArray}`;
  const [categoryList, setCategoryList] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [selectedDocCategoryType, setSelectedDocCategoryType] = useState("All");

  const [clientNameLabel, setClientNameLabel] = useState("");

  const { token, user_id, is_admin } = useContext(AuthContext);

  //Role Based Permissions

  // Function to check if the user has a specific permission.
  // Admin users ("is_admin" === "1") automatically have access to all permissions.
  const { permissions } = useRoleContext();
  const hasPermission = (permission) => {
    if (is_admin === "1") {
      return true;
    }
    return permissions?.includes(permission);
  };

  // Permission flags for various user actions, granting access based on user roles or admin status.
  // Admin users have all permissions by default.
  const canViewBeneficiaries = hasPermission("view_beneficiaries");
  const canViewRequestedDocuments = hasPermission("view_requested_documents");
  const canRequestDocuments = hasPermission("request_documents");
  const canUploadDocuments = hasPermission("upload_documents");

  const fetchCategory = async () => {
    try {
      const response = await getAllFieldTypeList({
        token,
        type: "document_type",
      });
      if (response.code == 200) {
        setCategoryList(response.data);
      } else {
        setCategoryList([]);
      }
    } catch (error) {
      return error;
    }
  };

  const fetchSubCategory = async () => {
    try {
      const response = await getAllFieldTypeList({
        token,
        type: "document_subtype",
      });
      if (response.code == 200) {
        setSubCategoryList(response.data);
      } else {
        setSubCategoryList("");
      }
    } catch (error) {
      return error;
    }
  };

  const handleRequestStatusButtonClick = (type) => {
    setSelectedDocumentRequestedType(type);
    setContactBeneficiary(false);
  };

  const handleCategoryChange = (e) => {
    setCurrentPage(1);
    setSelectedDocumentCategoryArray(e.target.value.toLowerCase());
    changeSelectedClientDocumentsType(e);
  };

  const handleDocumentCategoryChange = (e) => {
    setCurrentUploadPage(1);
    setSelectedDocCategoryType(e.target.value);
  };

  const setCategoryUpdatedToTrue = () => {
    setCategoryUpdated((count) => count + 1);
  };

  const documentResponseFunc = async () => {
    if (!categoryUpdated) {
      setLoading(true);
    }

    const offset = currentPage - 1;
    const filterParams = {};

    columnFilters.forEach((filterItem) => {
      filterParams[filterItem.id] = filterItem.value;
    });

    const sortParam = sorting
      .map((sortItem) => {
        return `${sortItem.id}=${sortItem.desc ? "desc" : "asc"}`;
      })
      .join(",");

    const params = {
      isAdmin: is_admin,
      userId: user_id,
      contact_id: selectedClientId.id,
      limit: pageSize,
      offset: offset,
      ...filterParams,
      global_search: debouncedGlobalFilter,
      sort: sortParam,
    };
    const queryString = new URLSearchParams(params).toString();

    const url = `${baseUrl}/api/get-doc-request-data?${queryString}${docStatus}${docCategory}`;

    const response = await requestedDocumentList({ token, api: url });
    if (response.code == 200) {
      setTotalPages(
        Math.ceil(response?.data[0]?.alldocumentcasescount / pageSize)
      );
      setDocumentResponse(
        response?.data[0]?.alldocumentcasescount !== 0
          ? response?.data[0]?.alldocumentcases
          : []
      );
    } else {
      setTotalPages(1);
      setDocumentResponse([]);
    }

    if (!categoryUpdated) {
      setLoading(false);
    }
  };

  const handleTabChange = (beneficiaryId, index, email, phoneNumber, name) => {
    setSelectedTab(beneficiaryId);
    setSelectedClientId(() => {
      return {
        primary: index === 0,
        id: beneficiaryId,
        email: email,
        phone_number: phoneNumber || "",
      };
    });
  };

  useEffect(() => {
    if ((cid || bid) && !showUploads) {
      handleRequestStatusButtonClick("allRequested");
    }
  }, []);

  useEffect(() => {
    if (showUploads) {
      handleRequestStatusButtonClick("uploaded");
    }
  }, [showUploads]);

  useEffect(() => {
    if (
      !canViewRequestedDocuments &&
      selectedDocumentRequestedType === "allRequested"
    ) {
      handleRequestStatusButtonClick("uploaded");
    }
  }, [canViewRequestedDocuments]);

  useEffect(() => {
    fetchCategory();
    fetchSubCategory();
    documentResponseFunc();
  }, [
    categoryUpdated,
    documentUpdatedToTrue,
    bulkDeleteRequests,
    selectedDocumentRequestedType,
    selectedDocumentCategoryArray,
    currentPage,
    columnFilters,
    debouncedGlobalFilter,
    sorting,
    pageSize,
    selectedClientId,
  ]);

  return (
    <>
      {!documentViewer ? (
        <div className="ben">
          {canViewBeneficiaries && (
            <BeneficiaryTabList
              clientID={clientID}
              onTabChange={handleTabChange}
              selectedTab={selectedTab}
              emailsToBeSent={emailsToBeSent}
              setEmailsToBeSent={setEmailsToBeSent}
              isValidEmail={isValidEmail}
            />
          )}
          <div
            className="case-doc-table-header flex-wrap
							gap-4 py-3
							lg:flex-nowrap lg:!py-0"
          >
            <div className="doc-buttons min-w-[200px]">
              {contactBeneficiary ||
              selectedDocumentRequestedType.toLowerCase() ===
                "allbeneficiary" ? (
                <AddBeneficiaryPopup
                  clientID={clientID}
                  setBeneficiaryAdded={setBeneficiaryAdded}
                  clientType={clientType}
                  typeOptions={typeOptions}
                />
              ) : selectedDocumentRequestedType?.toLowerCase() ===
                "allrequested" ? (
                canRequestDocuments && (
                  <RequestDocumentsPopup
                    key={selectedClientId.id}
                    clientID={selectedClientId}
                    setCategoryUpdatedToTrue={setCategoryUpdatedToTrue}
                    clientType={clientType}
                    setSelectedClientId={setSelectedClientId}
                    clientPrimaryID={clientID}
                    clientSubType={clientSubType}
                    emailsToBeSent={emailsToBeSent}
                    // serviceSubType={serviceSubType}
                  />
                )
              ) : (
                canUploadDocuments && (
                  <UploadDocumentsPopup
                    clientID={selectedClientId.id}
                    documentUpdatedToTrue={documentUpdatedToTrue}
                    setDocumentUpdatedToTrue={setDocumentUpdatedToTrue}
                    subCategoryList={subCategoryList}
                  />
                )
              )}
            </div>

            <div className="flex gap-2 flex-wrap lg:flex-nowrap">
              <div className="flex border border-black rounded-md">
                {canViewRequestedDocuments && (
                  <Button
                    className={`p-3 capitalize rounded-none rounded-tl-md rounded-bl-md text-[#292929] shadow-none flex gap-2 font-semibold ${
                      selectedDocumentRequestedType === "allRequested"
                        ? "bg-[#2b93c8d0] text-[#292929] hover:bg-[#2b94c8]"
                        : "bg-transparent"
                    }`}
                    onClick={() =>
                      handleRequestStatusButtonClick("allRequested")
                    }
                  >
                    <FilePenLineIcon color="#292929" />
                    Requested
                  </Button>
                )}

                <Button
                  className={`p-3 capitalize rounded-none text-[#292929] shadow-none flex gap-2 rounded-tr-md rounded-br-md font-semibold ${
                    selectedDocumentRequestedType === "uploaded"
                      ? "bg-[#2b93c8d0] text-[#292929] hover:bg-[#2b94c8]"
                      : "bg-transparent"
                  }`}
                  onClick={() => handleRequestStatusButtonClick("uploaded")}
                >
                  <FolderUp color="#292929" />
                  Uploaded
                </Button>
              </div>
            </div>
            <div
              className={`search-area ${
                selectedDocumentRequestedType === "allBeneficiary"
                  ? "invisible"
                  : "visible"
              }`}
            >
              <div className="case-doc-table-header-part">
                <FormControl className="case-doc-select-ui">
                  {selectedDocumentRequestedType?.toLowerCase() ===
                  "allrequested" ? (
                    <>
                      <InputLabel
                        className="case-doc-select-label"
                        id="select-label"
                      >
                        Document Request Type
                      </InputLabel>
                      <Select
                        name="addDocCategories"
                        sx={{ margin: "10px 0px", maxWidth: "20" }}
                        labelId="select-label"
                        variant="outlined"
                        id="simple-select"
                        value={selectedClientDocumentsType}
                        onChange={(e) => handleCategoryChange(e)}
                        label="Document Request Type"
                      >
                        {clientDocumentsType.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </>
                  ) : (
                    <>
                      <InputLabel
                        className="case-doc-select-label"
                        id="select-label"
                      >
                        Category
                      </InputLabel>
                      <Select
                        name="clientDocCategories"
                        sx={{ margin: "10px 0px", maxWidth: "20" }}
                        labelId="select-label"
                        variant="outlined"
                        id="simple-select"
                        value={selectedDocCategoryType}
                        onChange={(e) => handleDocumentCategoryChange(e)}
                        label="Category"
                      >
                        <MenuItem key="All" value="All">
                          All
                        </MenuItem>
                        {categoryList?.map((option) => (
                          <MenuItem
                            key={option.field_setting_id}
                            value={option.field_setting_id}
                          >
                            {option.fields_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </>
                  )}
                </FormControl>
              </div>
            </div>
          </div>

          {selectedDocumentRequestedType === "allRequested" ? (
            <div className="document__table">
              <RequestedDocumentsTable
                clientID={selectedClientId.id}
                setCategoryUpdatedToTrue={setCategoryUpdatedToTrue}
                documentResponse={documentResponse}
                loading={loading}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalPages={totalPages}
                columnFilters={columnFilters}
                setColumnFilters={setColumnFilters}
                pageSize={pageSize}
                setPageSize={setPageSize}
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
                bulkDeleteRequests={bulkDeleteRequests}
                setBulkDeleteRequests={setBulkDeleteRequests}
                sorting={sorting}
                setSorting={setSorting}
                changeDocumentViewerState={changeDocumentViewerState}
              />
            </div>
          ) : selectedDocumentRequestedType === "allBeneficiary" ? (
            <BeneficiaryTable
              clientID={clientID}
              beneficiaryAdded={beneficiaryAdded}
              setBeneficiaryAdded={setBeneficiaryAdded}
              typeOptions={typeOptions}
            />
          ) : (
            <DocumentTable
              clientID={selectedClientId.id}
              selectedDocCategoryType={selectedDocCategoryType}
              changeDocumentViewerState={changeDocumentViewerState}
              documentUpdatedToTrue={documentUpdatedToTrue}
              setDocumentUpdatedToTrue={setDocumentUpdatedToTrue}
              subCategoryList={subCategoryList}
              currentUploadPage={currentUploadPage}
              setCurrentUploadPage={setCurrentUploadPage}
            />
          )}
        </div>
      ) : (
        <PDFViewer
          clientID={clientID}
          documentType={documentType}
          documentViewer={documentViewer}
          pdfLink={pdfLink}
          setPdfLink={setPdfLink}
          data={data}
          setData={setData}
          pdfViewerTitle={pdfViewerTitle}
          setPdfViewerTitle={setPdfViewerTitle}
          setDocumentViewer={setDocumentViewer}
          docReqID={docReqID}
          reqStatus={reqStatus}
          description={description}
          setDescription={setDescription}
          category={category}
          subCategory={subCategory}
          setSubCategory={setSubCategory}
          token={token}
          user_id={user_id}
          is_admin={is_admin}
          docCaseID={docCaseID}
          ocrData={ocrData}
          setOcrData={setOcrData}
          selectedRequestLabel={selectedRequestLabel}
          setSelectedRequestLabel={setSelectedRequestLabel}
          setReqStatus={setReqStatus}
          name={name}
          subCategoryList={subCategoryList}
          setDocumentUpdatedToTrue={setDocumentUpdatedToTrue}
          setDocReqID={setDocReqID}
          clientPhone={clientPhone}
          document_table="documents"
          selectedClientId={selectedClientId}
        />
      )}
    </>
  );
};

export default RequestedDocumentsList;
