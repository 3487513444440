import React, { useEffect, useState, useContext } from "react";
import { useMsal } from "@azure/msal-react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { loginRequest } from "../authConfig";
import Loading from "../components/material-ui/Loading";
import { callMsGraph } from "../graph";
import { AuthContext } from "../context/Auth/AuthContext";
import loginUser from "../api/login";
import { useRoleContext } from "../context/RolesContext";

const Callback = () => {
  const { instance, accounts } = useMsal();
  const [graphData, setGraphData] = useState(null);
  const { dispatch } = useContext(AuthContext);
  const redirect = useNavigate();
  const { setRoles } = useRoleContext();

  const handleLogout = async () => {
    dispatch({ type: "LOGOUT" });
    redirect("/login");
    toast.success("Successfully Logged Out", {
      position: "top-center",
      autoClose: 3000,
      theme: "dark",
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

    // instance
    //   .logoutPopup()
    //   .then(() => {
    //     dispatch({ type: "LOGOUT" });
    //     redirect("/login");
    //     toast.success("Successfully Logged Out", {
    //       position: "top-center",
    //       autoClose: 3000,
    //       theme: "dark",
    //       hideProgressBar: false,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       progress: undefined,
    //     });
    //   })
    //   .catch((e) => {
    //     console.error(e);
    //   });
  };

  const loginUserFn = async (profile) => {
    const loginData = await loginUser(
      profile.mail,
      profile.displayName,
      profile.id,
      profile.mobilePhone
    );

    //console.log(loginData);
    if (loginData?.token) {
      setRoles(loginData.user.role_id);
      localStorage.setItem("role_id", loginData.user.role_id);
      dispatch({
        type: "LOGIN",
        payload: {
          // token: loginData.token,
          // userID: loginData?.user?.id,
          // userName: loginData?.name,
          // isAdmin: loginData?.user?.is_admin,

          token: loginData.token,
          id: loginData.user.id,
          user_id: loginData.user.id,
          firstName: loginData.user.first_name,
          lastName: loginData.user.last_name,
          phoneNumber: loginData.user.phonenumber,
          is_admin: loginData.user.is_admin,
        },
      });
      toast.success("Successfully Logged In", {
        position: "top-center",
        autoClose: 3000,
        theme: "dark",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      redirect("/dashboard");
    }
    if (loginData?.code === 401) {
      handleLogout();
    }
  };

  useEffect(() => {
    const request = {
      ...loginRequest,
      account: accounts[0],
    };

    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    instance
      .acquireTokenSilent(request)
      .then((response) => {
        //console.log(response);
        callMsGraph(response.accessToken).then((response) => {
          setGraphData(response);
          loginUserFn(response);
        });
      })
      .catch((e) => {
        instance.acquireTokenPopup(request).then((response) => {
          callMsGraph(response.accessToken).then((response) => {
            setGraphData(response);
          });
        });
      });
  }, []);

  return (
    <div
      style={{
        display: "flex",
        minHeight: "100vh",
        minWidth: "100vw",
        alignContent: "center",
        justifyContent: "center",
      }}
    >
      <Loading />
    </div>
  );
};

export default Callback;
