import baseUrl, { sugarUrl } from "../config/baseUrl";

const getAllSugarUsers = async ({ token }) => {
  try {
    const response = await fetch(`${baseUrl}/api/getdms-users-list`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    return error;
  }
};

export default getAllSugarUsers;
