import baseUrl, { sugarUrl } from "../config/baseUrl";

const getChatLogs = async ({ token, clientID }) => {
  try {
    const response = await fetch(
      `${baseUrl}/api/get-message-chat-log?id=${clientID}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        // body: JSON.stringify({
        //   id: clientID,
        // }),
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching chat logs:", error);
    return error;
  }
};

export default getChatLogs;
