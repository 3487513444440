// PDFViewer.jsx
import React, { useState, useEffect, useContext } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { TbCircleCheck, TbEdit } from "react-icons/tb";
import { TiDeleteOutline } from "react-icons/ti";
import { RiCloseCircleFill } from "react-icons/ri";
import { toast } from "react-toastify";
import ButtonUI from "../../material-ui/ButtonUI";
import getClientRefNo from "../../../api/getClientRefNo";
import editDocument from "../../../api/editDocument";
import getClientName from "../../../api/getClientName";
import InputUI from "../../material-ui/InputUI";
import FormControl from "@mui/material/FormControl";
import ApprovePDFDocumentPopup from "./ApprovePDFDocumentPopup";
import RejectPDFDocumentPopup from "./RejectPDFDocumentPopup";
import LinkToRequest from "../../document-table/LinkToRequest";
import { BiDetail, BiLinkAlt } from "react-icons/bi";
import AddTask from "../../document-table/AddTask";
import baseUrl from "../../../config/baseUrl";
import SendSMS from "../../pdf-viewer/SendSMS";
import { FaDownload } from "react-icons/fa";
import PdfEditor from "../../pdf-viewer/PdfEditor";
import {
  IoArrowBackCircleSharp,
  IoCloseSharp,
  IoDocumentText,
} from "react-icons/io5";
import pdfBase64Data from "../../../api/pdfBase64Data";
import pdfDetails from "../../../api/pdfDetails";
import PdfDetails from "../../pdf-viewer/PdfDetails";
import ZipViewer from "../../pdf-viewer/ZipViewer";
import Loading from "../../material-ui/Loading";
import { useRoleContext } from "../../../context/RolesContext";
import { AuthContext } from "../../../context/Auth/AuthContext";
import BusinessCardInfo from "../../common/BusinessCardInfo";

const updateDocumentDetailsFormSchema = yup.object().shape({
  name: yup.string().required("Document Name is required"),
  subcategory: yup.string().nullable().required("Sub Category is required"),
});

const PDFViewer = ({
  clientID,
  documentType,
  documentViewer,
  pdfLink,
  setPdfLink,
  data,
  setData,
  pdfViewerTitle,
  setPdfViewerTitle,
  setDocumentViewer,
  docReqID,
  reqStatus,
  setReqStatus,
  setDocReqID,
  description,
  setDescription,
  category,
  subCategory,
  setSubCategory,
  clientNames,
  subCategoryList,
  token,
  user_id,
  is_admin,
  docCaseID,
  ocrData,
  setOcrData,
  selectedRequestLabel,
  setSelectedRequestLabel,
  name,
  setDocumentUpdatedToTrue,
  clientPhone,
  document_table,
  selectedClientId,
}) => {
  const [clientNameLabel, setClientNameLabel] = useState("");
  const [approveConfirmOpen, setApproveConfirmOpen] = useState(false);
  const [disapproveConfirmOpen, setDisapproveConfirmOpen] = useState(false);
  const [linkRequestOpen, setLinkRequestOpen] = useState(false);
  const [requestOptions, setRequestOptions] = useState([]);
  const [linkRequest, setLinkRequest] = useState(false);
  const [editor, setEditor] = useState(false);
  const [pdfBase64, setPdfBase64] = useState("");
  const [loading, setLoading] = useState(true);
  const [pdfDataLoading, setPdfDataLoading] = useState(true);
  const [pdfData, setPdfData] = useState({});
  const [isPdfDetailsContainerOpen, setIsPdfDetailsContainerOpen] =
    useState(false);
  const [documentVersionId, setDocumentVersionId] = useState(null);
  const [zipContent, setZipContent] = useState([]);

  //Role Based Permissions
  // Function to check if the user has a specific permission.
  // Admin users ("is_admin" === "1") automatically have access to all permissions.
  const { permissions } = useRoleContext();
  const hasPermission = (permission) => {
    if (is_admin === "1") {
      return true;
    }

    return permissions.includes(permission);
  };

  // Permission flags for various user actions, granting access based on user roles or admin status.
  // Admin users have all permissions by default.
  const canEditPdf = hasPermission("edit_pdf_documents");
  const canLinkDocument = hasPermission("link_documents");
  const canApproveDocument = hasPermission("document_approval");
  const canEditClient = hasPermission("client_edit");
  const canMarkClientUnknown = hasPermission("mark_client_unknown");
  const canPerformOCR = hasPermission("perform_ocr");
  const canAddTask = hasPermission("add_task");
  const canSendSMS = hasPermission("send_sms");

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(updateDocumentDetailsFormSchema),
  });

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    ignoreCase: true, // Ignore case sensitivity
    stringify: (option) => option.fields_name,
  });

  const handleApproveDocument = (e) => {
    setApproveConfirmOpen(true);
  };

  const handleDisapproveDocument = (e) => {
    setDisapproveConfirmOpen(true);
  };

  const handleLinkRequest = (e) => {
    setLinkRequestOpen(true);
  };

  const fetchRequestList = async (selectedClient) => {
    setLinkRequest(false);
    const url = `${baseUrl}/api/get-doc-request-data?userId=1&isAdmin=1&contact_id=${selectedClient}&unlinked=True`;
    //const url = `${baseUrl}/api/get-document-request-for-linking?userId=1&isAdmin=1&contact_id=${selectedClient}`;
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();

      if (data?.code === 200) {
        // Create the additional item
        const additionalItem = {
          document_request_id: "Additional Document",
          document_req_name: "Additional Document",
        };

        // Add it as the first item to the data array
        const updatedData = [
          additionalItem,
          ...data?.data[0]?.alldocumentcases,
        ];

        // Set the updated data to the state
        setRequestOptions(updatedData);
      } else {
        // Set only the additional document in the state
        setRequestOptions([
          {
            document_request_id: "Additional Document",
            document_req_name: "Additional Document",
          },
        ]);
      }
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    if (clientID) {
      fetchRequestList(clientID);
    }
  }, [docReqID]);

  const onEditDocumentFormSubmit = async (editDocumentFormData) => {
    const editDocumentFormResponse = await editDocument({
      token: token,
      user_id: user_id,
      document_id: docCaseID,
      document_desc: description,
      document_name: editDocumentFormData.name,
      document_sub_category: editDocumentFormData.subcategory,
      contact_id: editDocumentFormData.addClientName,
    });

    if (editDocumentFormResponse.code === 200) {
      // const urlAddDocumentRequests = `${baseUrl}/api/add-document-requests?contact_id=${editDocumentFormData.addClientName}`;
      // try {
      //   const responseAddDocumentRequests = await fetch(
      //     urlAddDocumentRequests,
      //     {
      //       method: "POST",
      //       headers: {
      //         Accept: "application/json",
      //         Authorization: `Bearer ${token}`,
      //       },
      //     }
      //   );

      //   await responseAddDocumentRequests.json();
      // } catch (error) {
      //   return error;
      // }

      setPdfViewerTitle(editDocumentFormData.name);
      setDocumentUpdatedToTrue((count) => count + 1);
      toast.success("Document is updated successfully", {
        position: "top-center",
        autoClose: 3000,
        theme: "dark",
      });
    } else {
      toast.error("Error while updating document", {
        position: "top-center",
        autoClose: 5000,
        theme: "dark",
      });
    }
  };

  const fetchClientRefNo = async () => {
    const clientRefNoResponse = await getClientRefNo({
      contact_id: clientID,
      token: token,
    });

    if (clientRefNoResponse.code === 200) {
      setValue("caseId", clientRefNoResponse?.data[0]?.client_reference_number);
    } else {
      toast.error(
        "Error while getting client details in DMS. Please contact admin!",
        {
          position: "top-center",
          autoClose: 5000,
          theme: "dark",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
  };

  const fetchClientName = async () => {
    const clientNameResponse = await getClientName({
      contact_id: clientID,
      token: token,
    });
    setValue("addClientName", clientNameResponse?.data[0]?.name);
  };

  const getClientNameDetails = async () => {
    const clientNameResponse = await getClientName({
      contact_id: selectedClientId?.id,
      token: token,
    });

    setClientNameLabel(clientNameResponse?.data?.name);
  };

  useEffect(() => {
    setValue("name", name);
    fetchClientRefNo();
    fetchClientName();
    getClientNameDetails();
  }, [clientID, token, setValue]);

  const isImage = (src) => {
    const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "svg"];
    const extension = src.split(".").pop().toLowerCase();
    return imageExtensions.includes(extension);
  };

  const isPDF = (src) => {
    const pdfExtension = "pdf";
    const extension = src.split(".").pop().toLowerCase();
    return extension === pdfExtension;
  };

  const base64ToBlob = (base64, mimeType = "application/zip") => {
    const binaryString = atob(base64); // Decode the base64 string
    const length = binaryString.length;
    const bytes = new Uint8Array(length);
    for (let i = 0; i < length; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return new Blob([bytes], { type: mimeType });
  };

  useEffect(() => {
    if (pdfBase64 && pdfBase64[0]?.data && documentType === "zip") {
      const blob = base64ToBlob(pdfBase64[0]?.data);
      setZipContent(blob);
    }
  }, [pdfBase64]);

  const handleDocumentDownload = function () {
    const link = document.createElement("a");
    link.href = pdfLink;
    link.download = pdfViewerTitle || "download";
    link.click();
  };

  const pdfBlob = async () => {
    setLoading(true);
    setPdfDataLoading(true);
    try {
      const response = await pdfBase64Data(token, docCaseID, document_table);
      const data = response.data;
      setPdfBase64(data);
    } catch (error) {
      console.error("Error fetching PDF Blob:", error);
    } finally {
      setLoading(false);
      setPdfDataLoading(false);
    }
  };

  const pdfDetailsData = async () => {
    setLoading(true);
    setPdfDataLoading(true);

    try {
      const response = await pdfDetails(token, docCaseID, document_table);
      const data = response.data;

      if (response.code === 200) {
        setData(data);
        setPdfLink(data[0]?.pdflink);
      } else {
        toast.error("Error while getting document", {
          position: "top-center",
          autoClose: 5000,
          theme: "dark",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.error("Error fetching PDF details:", error);
    } finally {
      setLoading(false);
      setPdfDataLoading(false);
    }
  };

  const pdfDetailsVersionData = async () => {
    setLoading(true);
    setPdfDataLoading(true);

    try {
      const response = await pdfDetails(
        token,
        docCaseID,
        document_table,
        documentVersionId
      );
      const data = response.data;
      setPdfData(data);
      setPdfLink(data[0]?.pdflink);
    } catch (error) {
      console.error("Error fetching PDF version details:", error);
    } finally {
      setLoading(false);
      setPdfDataLoading(false);
    }
  };

  const changePdfDetailsContainerVisibility = () => {
    setIsPdfDetailsContainerOpen(!isPdfDetailsContainerOpen);
  };

  const fetchData = async () => {
    await pdfBlob();
    await pdfDetailsData();
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {approveConfirmOpen && (
        <ApprovePDFDocumentPopup
          documentRequestId={docReqID}
          setReqStatus={setReqStatus}
          setApproveConfirmOpen={setApproveConfirmOpen}
        />
      )}
      {disapproveConfirmOpen && (
        <RejectPDFDocumentPopup
          documentRequestId={docReqID}
          setReqStatus={setReqStatus}
          setDisapproveConfirmOpen={setDisapproveConfirmOpen}
        />
      )}

      {linkRequestOpen && (
        <LinkToRequest
          documentId={docCaseID}
          requestOptions={requestOptions}
          setLinkRequest={setLinkRequest}
          setReqStatus={setReqStatus}
          setRequestId={setDocReqID}
          setLinkRequestOpen={setLinkRequestOpen}
          setSelectedRequestLabel={setSelectedRequestLabel}
        />
      )}
      {!editor ? (
        <div className="all-document-pdf-viewer">
          <div className="all-document-pdf-viewer-header pb-3">
            <div className="capitalize all-document-pdf-viewer-title w-1/2">
              {pdfViewerTitle}
              {selectedRequestLabel
                ? " - " + selectedRequestLabel + " (Linked Request)"
                : null}
            </div>
            <div className="document-pdf-actions">
              {clientID && <BusinessCardInfo clientID={clientID} />}
              {clientID && canAddTask && <AddTask contactId={clientID} />}
              {clientID && clientPhone && canSendSMS ? (
                <SendSMS clientPhone={clientPhone} clientID={clientID} />
              ) : null}
              {docReqID && reqStatus?.toLowerCase() === "approved" && (
                <div
                  className="all-document-pdf-viewer-icon !cursor-default"
                  style={{ color: "green", fontSize: 26 }}
                  title="Approved"
                >
                  <TbCircleCheck />
                </div>
              )}
              {docReqID && reqStatus?.toLowerCase() === "rejected" && (
                <div
                  className="all-document-pdf-viewer-icon !cursor-default"
                  style={{ color: "red", fontSize: 29 }}
                  title="Rejected"
                >
                  <TiDeleteOutline />
                </div>
              )}

              {canApproveDocument && (
                <ButtonUI
                  formButton={false}
                  variant="outlined"
                  buttonText={<TbCircleCheck />}
                  style={{
                    backgroundColor:
                      reqStatus?.toLowerCase() === "uploaded"
                        ? "#28a745"
                        : "#d8d8e4df",

                    color:
                      reqStatus?.toLowerCase() === "uploaded"
                        ? "white"
                        : "rgb(18 215 24)",
                    marginRight: "5px",
                    marginBottom: "5px",
                    padding: "8px",
                    minWidth: "fit-content",
                    border: "none",
                  }}
                  disabled={
                    reqStatus?.toLowerCase() === "uploaded" ? false : true
                  }
                  onClickFunc={handleApproveDocument}
                />
              )}
              {canApproveDocument && (
                <ButtonUI
                  formButton={false}
                  variant="outlined"
                  buttonText={<TiDeleteOutline />}
                  style={{
                    backgroundColor:
                      reqStatus?.toLowerCase() === "uploaded"
                        ? "#dc3545"
                        : "#d8d8e4df",

                    color:
                      reqStatus?.toLowerCase() === "uploaded"
                        ? "white"
                        : "rgb(215 18 18)",
                    marginRight: "5px",
                    marginBottom: "5px",
                    padding: "8px",
                    minWidth: "fit-content",
                    border: "none",
                  }}
                  disabled={
                    reqStatus?.toLowerCase() === "uploaded" ? false : true
                  }
                  onClickFunc={handleDisapproveDocument}
                />
              )}
              {canLinkDocument && (
                <ButtonUI
                  formButton={false}
                  variant="outlined"
                  buttonText={<BiLinkAlt />}
                  style={{
                    backgroundColor: !docReqID ? "#17a2b8" : "#d8d8e4df",
                    color: !docReqID ? "white" : "rgb(18 215 24)",
                    marginRight: "5px",
                    marginBottom: "5px",
                    padding: "8px",
                    minWidth: "fit-content",
                    border: "none",
                  }}
                  disabled={!docReqID ? false : true}
                  onClickFunc={handleLinkRequest}
                />
              )}
              {documentType === "pdf" &&
              document_table != "contract_requests" &&
              (document_table != "document_request_settings" || !document_table)
                ? canEditPdf && (
                    <div
                      onClick={
                        !pdfDataLoading ? () => setEditor(!editor) : undefined
                      }
                      className={`edit-doc-btn ${
                        pdfDataLoading
                          ? "cursor-not-allowed opacity-50 pointer-events-none"
                          : "cursor-pointer"
                      }`}
                      title={
                        pdfDataLoading
                          ? "Loading PDF, please wait..."
                          : "Edit PDF"
                      }
                    >
                      <TbEdit />
                    </div>
                  )
                : null}
              <div
                className="pdf-viewer-icon"
                onClick={changePdfDetailsContainerVisibility}
                title="PDF Details"
              >
                {isPdfDetailsContainerOpen ? <IoCloseSharp /> : <BiDetail />}
              </div>
              <div
                className="all-document-pdf-viewer-icon"
                title="Close Document"
                onClick={() => setDocumentViewer(false)}
              >
                <RiCloseCircleFill />
              </div>
            </div>
          </div>
          <div className="document-viewer-with-from">
            <div className="iframe-wrapper relative w-full">
              {loading && (
                <div className="absolute inset-0 flex justify-center items-center bg-white bg-opacity-80 z-10">
                  <Loading />
                </div>
              )}
              {!loading && data && pdfLink ? (
                documentType.toLowerCase() === "pdf" ||
                documentType.toLowerCase() === "txt" ? (
                  <iframe
                    title={pdfViewerTitle}
                    src={pdfLink}
                    width="100%"
                    height="590"
                    key={pdfLink}
                  ></iframe>
                ) : ["doc", "docx", "xls", "xlsx", "pptx", "ppt"].includes(
                    documentType.toLowerCase()
                  ) ? (
                  <div className="relative w-full h-full">
                    {/* <iframe
                      title={pdfViewerTitle}
                      src={`https://docs.google.com/gview?url=${pdfLink}&embedded=true`}
                      className="w-full h-full border-none"
                    ></iframe>

                     <button
                      onClick={handleDocumentDownload}
                      className="absolute top-3 right-4 bg-[#44a0cf] hover:bg-[#3c8cb4] py-2 px-4 rounded-lg flex gap-1 justify-center items-center self-center transition-all duration-300 text-white"
                    >
                      <FaDownload />
                      Download
                    </button> */}

                    <iframe
                      title={pdfViewerTitle}
                      src={`https://view.officeapps.live.com/op/embed.aspx?src=${pdfLink}`}
                      className="w-full h-full border-none"
                    ></iframe>
                  </div>
                ) : documentType.toLowerCase() === "mp4" ? (
                  <div className="flex justify-center items-center w-full max-w-[800px]">
                    <video
                      controls
                      className="w-full h-auto max-h-[90vh] object-contain rounded-lg"
                    >
                      <source src={pdfLink} type="video/mp4" />
                    </video>
                  </div>
                ) : documentType.toLowerCase() === "zip" && pdfLink ? (
                  <ZipViewer
                    zipContent={zipContent}
                    zipSrc={pdfLink}
                    base64ToBlob={base64ToBlob}
                  />
                ) : isImage(pdfLink) ? (
                  <img
                    src={pdfLink}
                    width="100%"
                    height="590"
                    alt={pdfViewerTitle}
                  />
                ) : (
                  <div className="bg-[#ddeef6] border-l-4 border-[#44a0cf] text-white p-6 rounded-lg shadow-md max-w-md mx-auto flex flex-col justify-center items-center">
                    <span className="text-gray-800 text-base font-semibold mb-3 text-center">
                      This file is not supported for viewing here. You can
                      download it using the button below
                    </span>

                    <button
                      onClick={handleDocumentDownload}
                      className="bg-[#2b93c8d0] hover:bg-[#44a0cf] py-2 px-4 rounded-lg flex gap-1 justify-center items-center self-center transition-all duration-300"
                    >
                      <FaDownload />
                      Download
                    </button>
                  </div>
                )
              ) : null}
            </div>

            <div className="document-viewer-form">
              <div className="field_wrapper">
                <div className="field">
                  <Controller
                    control={control}
                    name="addClientName"
                    disabled
                    render={({ field }) => (
                      <Autocomplete
                        className="clientNameAuto"
                        name="addClientName"
                        options={clientNames?.map(
                          (clientName) => clientName.name
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={
                              !clientNameLabel ? "Client Name" : clientNameLabel
                            }
                            InputProps={{
                              ...params.InputProps,
                            }}
                            {...params} // spread the params object to display the label
                          />
                        )}
                        // filterOptions={(options, state) => {
                        //   return options.filter((option) => {
                        //     return (
                        //       option
                        //         .toLowerCase()
                        //         .indexOf(state.inputValue.toLowerCase()) > -1
                        //     );
                        //   });
                        // }}
                        {...field}
                        {...register("addClientName")}
                        value={
                          clientNames?.find(
                            (clientName) =>
                              clientName.contact_id === field.value
                          )?.name
                        }
                        //   onChange={(event, newValue) => {
                        //     const selectedClient = clientNames?.find(
                        //       (clientName) => clientName.name === newValue
                        //     );
                        //     if (selectedClient) {
                        //       field.onChange(selectedClient.contact_id);
                        //     }
                        //   }}
                      />
                    )}
                  />
                  <span className="error-message">
                    {errors["addClientName"]?.message}
                  </span>
                </div>
              </div>
              <div className="field_wrapper">
                <div className="field">
                  <Controller
                    name="caseId"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <InputUI
                        disabled
                        name="caseId"
                        label="Client Reference Number"
                        type="text"
                        value={value ? value : ""}
                        setValue={onChange}
                        variant="outlined"
                        register={register}
                        cssStyles={{
                          width: "100%",
                        }}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="field_wrapper">
                <div className="field">
                  <Controller
                    name="name"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <InputUI
                        name="name"
                        label="Document Name"
                        type="text"
                        value={value ? value : ""}
                        setValue={onChange}
                        variant="outlined"
                        register={register}
                        disabled={true}
                        cssStyles={{
                          margin: "20px 0px",
                          width: "100%",
                        }}
                      />
                    )}
                  />
                  <span className="error-message">
                    {errors["name"]?.message}
                  </span>
                </div>
              </div>
              <div className="field_wrapper">
                <div className="field">
                  <Controller
                    name="category"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <InputUI
                        name="category"
                        label="Category"
                        type="text"
                        value={category ? category : ""}
                        disabled
                        variant="outlined"
                        register={register}
                        cssStyles={{
                          marginBottom: "20px",
                          width: "100%",
                        }}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="field_wrapper">
                <FormControl className="" fullWidth>
                  <Controller
                    control={control}
                    name="subcategory"
                    defaultValue={
                      subCategory && subCategory !== "null" ? subCategory : ""
                    }
                    render={({ field: { onChange, value } }) => (
                      <Autocomplete
                        options={subCategoryList}
                        getOptionLabel={(option) => option.fields_name || ""}
                        filterOptions={filterOptions}
                        onChange={(e, value) => {
                          onChange(value ? value.field_setting_id : "");
                          if (value) {
                            setSubCategory(value.field_setting_id);
                          } else {
                            setSubCategory("");
                          }
                        }}
                        value={
                          subCategoryList?.find(
                            (option) => option.field_setting_id === subCategory
                          ) || null
                        }
                        sx={{ margin: "10px 0px" }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Sub Category"
                            variant="outlined"
                          />
                        )}
                      />
                    )}
                  />
                </FormControl>
                {/* {subCategory}
                <FormControl fullWidth>
                  <InputLabel id="select-label">Sub Category</InputLabel>
                  <Controller
                    control={control}
                    name="subcategory"
                    defaultValue={subCategory}
                    render={({ field: { onChange, value } }) => (
                      <SelectBox
                        name="subcategory"
                        labelId="select-label"
                        variant="outlined"
                        id="simple-select"
                        value={subCategory ? subCategory : value}
                        onChange={onChange}
                        label="Sub Category"
                        {...register("subcategory")}
                      >
                        {subCategoryList?.map((category) => (
                          <MenuItem
                            key={category.field_setting_id}
                            value={category.field_setting_id}
                          >
                            {category.fields_name}
                          </MenuItem>
                        ))}
                      </SelectBox>
                    )}
                  />
                </FormControl>*/}
                <span className="error-message">
                  {errors["subcategory"]?.message}
                </span>
              </div>
              <div className="field_wrapper">
                <div className="field">
                  <TextField
                    name="description"
                    label="Description"
                    multiline
                    minRows={3}
                    maxRows={3}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    variant="outlined"
                    style={{ marginTop: "15px", marginBottom: "15px" }}
                    fullWidth
                  />
                </div>
              </div>
              <ButtonUI
                variant="contained"
                buttonText="Submit"
                handleSubmit={handleSubmit}
                onFormSubmit={onEditDocumentFormSubmit}
              />
            </div>
          </div>
          <div
            className={
              isPdfDetailsContainerOpen
                ? "pdf-details-container"
                : "hide-pdf-details-container"
            }
          >
            <div className="pdf-details-header">
              <div className="pdf-details-header-icon">
                <IoDocumentText />
              </div>
              <div className="pdf-details-header-title">Document Details</div>
              <div
                className="close-pdf-details-container-icon"
                onClick={changePdfDetailsContainerVisibility}
              >
                <RiCloseCircleFill />
              </div>
            </div>
            <div className="pdf-details-content">
              <PdfDetails
                pdfData={pdfData}
                documentID={docCaseID}
                documentTitle={pdfViewerTitle}
                documentType={documentType}
                pdfDetailsVersionData={pdfDetailsVersionData}
                documentVersionId={documentVersionId}
                setDocumentVersionId={setDocumentVersionId}
                // document_version={document_version}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="show-pdf-editor">
          <div
            onClick={() => setEditor(!editor)}
            className="go-back-to-pdf-viewer"
            title="Back"
          >
            <IoArrowBackCircleSharp />
          </div>
          <div>
            <PdfEditor
              pdfBase64={pdfBase64}
              documentID={docCaseID}
              documentTitle={pdfViewerTitle}
              setEditor={setEditor}
              pdfDetailsData={pdfDetailsData}
              pdfBlob={pdfBlob}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default PDFViewer;
