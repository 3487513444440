import baseUrl from "../../config/baseUrl";

export const verifyFaces = async (token, selfie, id_card) => {
	try {
		const formData = new FormData();
		formData.append("selfie", selfie);
		formData.append("idcard", id_card);

		const res = await fetch(
			`https://filescour.consumerlaw.com/match-selfie-idcard`,
			{
				method: "POST",
				headers: {
					Authorization: `Bearer 29649f8f-4055-4b7e-b5db-c3e57d875d66`,
				},
				body: formData,
			}
		);

		const data = await res.json();
		return data;
	} catch (error) {
		console.log(error);
	}
};

export const SendCientVerificationDetails = async (
	token,
	contact_id,
	verification_status,
	verified_by,
	selfie_url,
	idproof_url
) => {
	try {
		const formData = new FormData();
		formData.append("contact_id", contact_id);
		formData.append("verification_status", verification_status);
		formData.append("verified_by", verified_by);
		formData.append("selfie_url", selfie_url);
		formData.append("idproof_url", idproof_url);

		const res = await fetch(`${baseUrl}/api/add-client-verification-data`, {
			method: "POST",
			headers: {
				Authorization: `Bearer ${token}`,
			},
			body: formData,
		});
		return res;
	} catch (error) {
		console.log(error);
	}
};
