import React, { useState, useEffect, useContext } from "react";
import { NavLink, useNavigate, useLocation, useParams } from "react-router-dom";
import { MdOutlineSpaceDashboard, MdOutlineLogout } from "react-icons/md";
import { CgFileDocument } from "react-icons/cg";
import { RiSettings5Line, RiListSettingsFill } from "react-icons/ri";
import { FaRegUserCircle } from "react-icons/fa";
import { BiMessageSquareDetail, BiTrash } from "react-icons/bi";
import {
  MdOutlineContactMail,
  MdMailOutline,
  MdOutlineScanner,
} from "react-icons/md";
import { AiOutlineFieldTime } from "react-icons/ai";
import { BsBookmarkStar } from "react-icons/bs";
import {
  TbMailOff,
  TbLayoutSidebarLeftCollapse,
  TbLayoutSidebarRightCollapse,
} from "react-icons/tb";
import { useRoleContext } from "../../context/RolesContext";
import { AuthContext } from "../../context/Auth/AuthContext";
import logoutUser from "../../api/logout";
import logo from "../../images/logo.png";
import logo_s from "../../images/logo_collapsed.png";
import "../../styles/Sidebar.css";
import { IoIosGitPullRequest } from "react-icons/io";

const SideBar = () => {
  const [isSideBarCollapsed, setIsSideBarCollapsed] = useState(
    JSON.parse(localStorage.getItem("isSideBarCollapsed")) || false
  );
  const [isOpen, setIsOpen] = useState(false);
  const [openSubMenuName, setOpenSubMenuOpen] = useState("");
  const { roles, permissions, getPermissions } = useRoleContext();
  const { token, is_admin, dispatch } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { cid } = useParams();

  const clientId = cid;
  const showClients = location.pathname.endsWith("/clients");

  useEffect(() => {
    if (getPermissions) {
      getPermissions();
    } else {
      console.error("getPermissions is undefined");
    }
  }, []);

  // console.log(permissions);

  useEffect(() => {
    localStorage.setItem(
      "isSideBarCollapsed",
      JSON.stringify(isSideBarCollapsed)
    );
    return () => localStorage.removeItem("isSideBarCollapsed");
  }, [isSideBarCollapsed]);

  const toggleSubmenu = (name) => {
    if (openSubMenuName === name && isOpen === true) {
      setIsOpen(false);
      return;
    }
    setOpenSubMenuOpen(name);
    setIsOpen(true);
  };

  const toggleSidebar = () => setIsSideBarCollapsed(!isSideBarCollapsed);

  const handleLogout = async () => {
    await logoutUser({ token });
    dispatch({ type: "LOGOUT" });
    navigate("/login");
  };

  // Function to check if the user has a specific permission.
  // Admin users ("is_admin" === "1") automatically have access to all permissions.
  const hasPermission = (permission) => {
    if (is_admin === "1") {
      return true;
    }
    return permissions?.includes(permission);
  };

  // Helper function to filter routes based on permissions
  const getFilteredRoutes = () => {
    const baseRoutes = [
      {
        path: "/dashboard",
        name: "Dashboard",
        icon: <MdOutlineSpaceDashboard />,
      },
      {
        name: "Documents",
        icon: <CgFileDocument />,
        submenu: [
          {
            path: "/documents",
            name: "All Documents",
            icon: <CgFileDocument />,
            permission: "view_all_documents",
            // submenu: [
            //   {
            //     path: "/unprocessed-documents",
            //     name: "Unprocessed Documents",
            //     icon: <MdOutlinePendingActions />,
            //   },
            //   {
            //     path: "/processed-documents",
            //     name: "Processed Documents",
            //     icon: <AiOutlineCheckCircle />,
            //   },
            //   {
            //     path: "/client-unknown-documents",
            //     name: "Client Unknown Documents",
            //     icon: <AiOutlineQuestionCircle />,
            //   },
            // ],

          },
          {
            path: "/clients",
            name: "Clients",
            icon: <MdOutlineScanner />,
            permission: "view_clients",
          },
          {
            path: "/requests",
            name: "Requests",
            icon: <AiOutlineFieldTime />,
            permission: "view_requested_documents",
          },
          {
            path: "/starred-documents",
            name: "Favourites",
            icon: <BsBookmarkStar />,
            permission: "view_all_documents",
          },
        ],
      },
      {
        name: "Mailroom",
        icon: <MdOutlineContactMail />,
        submenu: [
          {
            path: "/assigned-case-list",
            name: "All Assigned",
            icon: <MdMailOutline />,
            permission: "view_all_assigned",
          },
          {
            path: "/unassigned-case-list",
            name: "All Unassigned",
            icon: <TbMailOff />,
            permission: "view_all_unassigned",
          },
          {
            path: "/my-assigned-case-list",
            name: "My Assigned",
            icon: <BsBookmarkStar />,
            permission: "view_my_assigned",
          },
          {
            path: "/email-case-list",
            name: "Incoming Emails",
            icon: <MdMailOutline />,
            permission: "view_incoming_emails",
          },
          {
            path: "/fax-case-list",
            name: "Incoming Faxes",
            icon: <BiMessageSquareDetail />,
            permission: "view_incoming_faxes",
          },
          {
            path: "/scan-case-list",
            name: "Scanned Files",
            icon: <MdOutlineScanner />,
            permission: "view_scanned_files",
          },
        ],
      },
      {
        name: "Settings",
        icon: <RiSettings5Line />,
        submenu: [
          {
            path: "/users",
            name: "Users",
            icon: <FaRegUserCircle />,
            permission: "view_users",
          },
          {
            path: "/roles",
            name: "Roles",
            icon: <FaRegUserCircle />,
            permission: "view_roles",
          },
          {
            path: "/field-settings",
            name: "Field Settings",
            icon: <RiListSettingsFill />,
            permission: "view_field_settings",
          },
          {
            path: "/document-request-settings",
            name: "Document Requests",
            icon: <IoIosGitPullRequest />,
            permission: "view_document_requests",
          },
        ],
      },
      {
        name: "Delete",
        icon: <BiTrash />,
        submenu: [
          {
            path: "/trash-users",
            name: "Users",
            icon: <FaRegUserCircle />,
            permission: "view_trash_users",
          },
          {
            path: "/trash-documents",
            name: "Documents",
            icon: <CgFileDocument />,
            permission: "view_trash_documents",
          },
        ],
      },
      {
        path: "/login",
        name: "Logout",
        icon: <MdOutlineLogout />,
        action: handleLogout,
      },
    ];

    return baseRoutes
      .map((route) => {
        if (route.path === "/dashboard") {
          return route;
        }

        if (route.permission) {
          // console.log(
          //   // `Checking permission for ${route.name}: ${route.permission}`
          // );
        }

        // Filter route by permission
        if (
          is_admin === "0" &&
          route.permission &&
          !hasPermission(route.permission)
        ) {
          return null;
        }

        // Handle submenu filtering
        if (route.submenu) {
          const filteredSubmenu = route.submenu.filter((sub) => {
            if (is_admin === "1" || hasPermission(sub.permission)) {
              return true;
            }
            return false;
          });

          if (filteredSubmenu.length === 0) {
            return null;
          }

          route.submenu = filteredSubmenu;
        }
        // console.log(route.submenu);
        return route;
      })
      .filter(Boolean);
  };

  const routes = getFilteredRoutes();

  return (
    <div className="sidebar-component">
      <div
        className={
          isSideBarCollapsed
            ? "collapsed-sidebar-container"
            : "sidebar-container"
        }
      >
        <div className="top-section">
          <NavLink to="/dashboard">
            <img src={isSideBarCollapsed ? logo_s : logo} alt="logo" />
          </NavLink>
          <div onClick={toggleSidebar} className="scrollable-bar">
            {isSideBarCollapsed ? (
              <TbLayoutSidebarRightCollapse />
            ) : (
              <TbLayoutSidebarLeftCollapse />
            )}
          </div>
        </div>
        <section className="sidebar-list-section">
          {routes.map((route, index) => (
            <React.Fragment key={index}>
              {!route.submenu ? (
                <NavLink
                  to={route.path}
                  className={({ isActive }) =>
                    isActive ? "active-sidebar-list" : "sidebar-list"
                  }
                  onClick={route.action}
                >
                  <div className="sidebar-list-icon">{route.icon}</div>
                  <div
                    className={
                      !isSideBarCollapsed
                        ? "sidebar-list-title"
                        : "hide-sidebar-list-title"
                    }
                  >
                    {route.name}
                  </div>
                </NavLink>
              ) : (
                route.submenu.length !== 0 && (
                  <>
                    <div
                      onClick={() => toggleSubmenu(route.name)}
                      className="submenu-sidebar-container"
                    >
                      <div className="submenu-header">
                        <div className="sidebar-list-icon">{route.icon}</div>
                        <div
                          className={
                            !isSideBarCollapsed
                              ? "sidebar-list-title"
                              : "hide-sidebar-list-title"
                          }
                        >
                          {route.name}
                        </div>
                      </div>
                    </div>
                    {isOpen &&
                      openSubMenuName === route.name &&
                      !isSideBarCollapsed && (
                        <div className="submenu-list">
                          {route.submenu.map((subroute, subindex) => (
                            <NavLink
                              to={subroute.path}
                              key={subindex}
                              className={({ isActive }) =>
                                isActive
                                  ? "active-submenu-item"
                                  : "submenu-item"
                              }
                            >
                              <div className="sidebar-list-icon">
                                {subroute.icon}
                              </div>
                              <div className="sidebar-list-title">
                                {subroute.name}
                              </div>
                            </NavLink>
                          ))}
                        </div>
                      )}
                  </>
                )
              )}
            </React.Fragment>
          ))}
        </section>
      </div>
    </div>
  );
};

export default SideBar;
