import React, { useState, useContext, useEffect } from "react";
import { toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { AiFillCloseCircle } from "react-icons/ai";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputUI from "../material-ui/InputUI";
import ButtonUI from "../material-ui/ButtonUI";
import { AuthContext } from "../../context/Auth/AuthContext";
import addUser from "../../api/addUser";
import addUserImg from "../../images/add-user.png";
import "../../styles/AddDocument.css";
import baseUrl from "../../config/baseUrl";

const phoneRegExp = /^[2-9]\d{9}$/;

const addUserFormSchema = yup.object().shape({
  firstName: yup.string().required("First Name is required field"),
  lastName: yup.string().required("Last Name is required field"),
  email: yup
    .string()
    .email("Email must be valid")
    .required("Email is required field"),
  role: yup.string().required("User Type is required field"),
});

const AddUser = ({ onAddUserButtonClick, setUserAddedToTrue }) => {
  const [loading, setLoading] = useState(false);
  const { token, user_id, is_admin } = useContext(AuthContext);
  const [userRolesOptions, setUserRolesOptions] = useState([]);

  const userRolesLoad = async () => {
    const url = `${baseUrl}/api/get-user-roles`;
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();
      setUserRolesOptions(data?.data);
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    userRolesLoad();
  }, []);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(addUserFormSchema),
  });

  const roleOptions = [
    {
      name: "Admin",
      value: 1,
    },
    {
      name: "Non Admin",
      value: 0,
    },
  ];

  const onAddUserFormSubmit = async (addUserFormData) => {
    setLoading(true);

    const addUserFormResponse = await addUser({
      token: token,
      userId: user_id,
      first_name: addUserFormData.firstName,
      last_name: addUserFormData.lastName,
      user_email: addUserFormData.email,
      is_admin: addUserFormData.role,
      user_role: addUserFormData.userRole,
    });

    if (addUserFormResponse?.code === 200) {
      setLoading(false);
      onAddUserButtonClick();
      setUserAddedToTrue();
      toast.success("User is added successfully", {
        position: "top-center",
        autoClose: 3000,
        theme: "dark",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setLoading(false);
      toast.error("Error while adding user", {
        position: "top-center",
        autoClose: 5000,
        theme: "dark",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <div className="add-document-page">
      <div className="add-document-container">
        <div className="add-document-header">
          <div className="add-document-title-with-img">
            <img src={addUserImg} height="100px" width="120px" alt="Add User" />
            <div className="add-document-title">Add New User</div>
          </div>
          <ButtonUI
            formButton={false}
            variant="contained"
            buttonText={<AiFillCloseCircle />}
            style={{
              background: "none",
              padding: "0px",
              minWidth: "auto",
            }}
            onClickFunc={onAddUserButtonClick}
          ></ButtonUI>
        </div>
        <div className="add-document-form">
          <div className="add-document-inputs">
            <div className="add-document-inputs-with-error-message">
              <InputUI
                name="firstName"
                label="Enter First Name"
                type="text"
                variant="outlined"
                cssStyles={{
                  margin: "10px 0px",
                }}
                register={register}
              />
              <span className="error-message">
                {errors["firstName"]?.message}
              </span>
            </div>
            <div className="add-document-inputs-with-error-message">
              <InputUI
                name="lastName"
                label="Enter Last Name"
                type="text"
                variant="outlined"
                cssStyles={{ margin: "10px 0px" }}
                register={register}
              />
              <span className="error-message">
                {errors["lastName"]?.message}
              </span>
            </div>
          </div>
          <div className="add-document-inputs">
            <div className="add-document-inputs-with-error-message">
              <InputUI
                name="email"
                label="Enter Email ID"
                type="email"
                variant="outlined"
                cssStyles={{ margin: "10px 0px" }}
                register={register}
              />
              <span className="error-message">{errors["email"]?.message}</span>
            </div>
            <div className="add-document-inputs-with-error-message select-ui">
              <FormControl fullWidth>
                <InputLabel id="select-label">Roles</InputLabel>
                <Controller
                  control={control}
                  name="userRole"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      name="userRole"
                      sx={{ margin: "10px 0px" }}
                      labelId="select-label"
                      variant="outlined"
                      id="simple-select"
                      multiple
                      value={value || []}
                      onChange={(e) => onChange(e.target.value)}
                      label="Roles"
                      {...register("userRole")}
                    >
                      {userRolesOptions.map((option) => (
                        <MenuItem key={option.role_id} value={option.role_id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
            </div>
          </div>
          <div className="add-document-inputs">
            <div className="add-document-inputs-with-error-message select-ui">
              <FormControl fullWidth>
                <InputLabel id="select-label">User Type</InputLabel>
                <Controller
                  control={control}
                  name="role"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      name="role"
                      sx={{ margin: "10px 0px" }}
                      labelId="select-label"
                      variant="outlined"
                      id="simple-select"
                      value={value}
                      onChange={onChange}
                      label="User Type"
                      {...register("role")}
                    >
                      {roleOptions.map((option) => (
                        <MenuItem
                          key={option.value}
                          value={option.value}
                          disabled={is_admin === "0" && option.value === 1} // Disable "Admin" option if is_admin is '0'
                        >
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
              <span className="error-message">{errors["role"]?.message}</span>
            </div>
          </div>
          <div className="add-document-button">
            <ButtonUI
              loading={loading}
              variant="contained"
              buttonText="Add User"
              style={{
                width: "150px",
                height: "35px",
                backgroundColor: "#007bff",
                boxShadow: "none",
                border: "1px solid #007bff",
                fontWeight: "500",
                borderRadius: "4px",
                letterSpacing: "0.07rem",
                marginTop: "20px",
              }}
              handleSubmit={handleSubmit}
              onFormSubmit={onAddUserFormSubmit}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddUser;
