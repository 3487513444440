/**
 * NotFoundPage Component:
 * - Displays a 404 error page for invalid routes.
 *
 * Features:
 * - Styled 404 message.
 * - Link to return to the dashboard.
 */

import { Link } from "react-router-dom";

function NotFoundPage() {
  return (
    <div className="flex items-center justify-center min-h-screen bg-blue-100">
      <div className="text-center p-12 bg-white shadow-lg rounded-xl max-w-md w-full">
        <h1 className="text-6xl font-bold text-blue-400 mb-4 tracking-tight">
          404
        </h1>
        <p className="text-lg text-gray-600 mb-8 opacity-80">
          Oops! The page you're looking for doesn't exist.
        </p>
        <Link
          to="/dashboard"
          className="inline-block px-8 py-3 text-white bg-blue-400 rounded-lg text-lg font-semibold hover:bg-blue-500 hover:text-blue-600 transition duration-300 ease-in-out transform hover:scale-105"
        >
          Return to Dashboard
        </Link>
      </div>
    </div>
  );
}

export default NotFoundPage;
