import React, { useState } from "react";
import { Controller } from "react-hook-form";
import { Grid, TextField } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputUI from "../../material-ui/InputUI";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";

/*** Component for listing all the form fields for editing the mailroom document details ***/
const EditDocumentForm = ({
  register,
  control,
  errors,
  tableValues,
  subCategoryOption,
}) => {
  const filterOptions = createFilterOptions({
    matchFrom: "any",
    ignoreCase: true, // Ignore case sensitivity
    stringify: (option) => option.fields_name,
  });

  return (
    <Grid className="edit-document">
      <div className="edit-document-form grid grid-cols-1 gap-3 sm:grid-cols-2">
        <div className="field_wrapper">
          <div className="field">
            <div className="">
              <Controller
                name="documentTitle"
                defaultValue={tableValues.document_name}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputUI
                    name="documentTitle"
                    label="Document Name"
                    type="text"
                    value={value ? value : ""}
                    setValue={onChange}
                    variant="outlined"
                    register={register}
                    disabled={true}
                  />
                )}
              />
            </div>
            <span className="error-message">
              {errors["documentTitle"]?.message}
            </span>
          </div>
        </div>
        <div className="field_wrapper">
          <div className="field">
            <div className="">
              <Controller
                name="documentDescription"
                defaultValue={tableValues.document_desc || ""}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputUI
                    name="documentDescription"
                    label="Document Description"
                    type="text"
                    value={value && value !== null ? value : ""}
                    setValue={onChange}
                    variant="outlined"
                    register={register}
                  />
                )}
              />
            </div>
            {/* <span className="error-message">
              {errors["documentDescription"]?.message}
            </span> */}
          </div>
        </div>
        <div className="field_wrapper">
          <div className="field">
            <Controller
              name="category"
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputUI
                  name="category"
                  label="Category"
                  disabled
                  type="text"
                  value={tableValues.category ? tableValues.category : ""}
                  variant="outlined"
                  register={register}
                  cssStyles={{
                    width: "100%",
                  }}
                />
              )}
            />
          </div>
        </div>
        <div className="field_wrapper">
          <div className="field">
            <FormControl className="" fullWidth>
              <Controller
                control={control}
                name="subcategory"
                defaultValue={
                  tableValues?.document_sub_category &&
                  tableValues?.document_sub_category !== "null"
                    ? tableValues?.document_sub_category
                    : ""
                }
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    options={subCategoryOption}
                    getOptionLabel={(option) => option.fields_name || ""}
                    filterOptions={filterOptions}
                    onChange={(e, value) => {
                      onChange(value ? value.field_setting_id : "");
                    }}
                    value={
                      subCategoryOption?.find(
                        (option) => option.field_setting_id === value
                      ) || null
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Sub Category"
                        variant="outlined"
                      />
                    )}
                  />
                )}
              />
            </FormControl>
            {/* <FormControl className="" fullWidth>
							<InputLabel className="select-label" id="select-label">
								Sub Category
							</InputLabel>
							<Controller
								control={control}
								name="subcategory"
								defaultValue={tableValues.document_sub_category}
								render={({ field: { onChange, value } }) => (
									<Select
										name="subcategory"
										labelId="select-label"
										variant="outlined"
										id="simple-select"
										value={value}
										onChange={onChange}
										label="Sub Category"
										{...register("subcategory")}
									>
										{subCategoryOption?.map((category) => (
											<MenuItem
												key={category.field_setting_id}
												value={category.field_setting_id}
											>
												{category.fields_name}
											</MenuItem>
										))}
									</Select>
								)}
							/>
						</FormControl> */}
            <span className="error-message">
              {errors["subcategory"]?.message}
            </span>
          </div>
        </div>
      </div>
    </Grid>
  );
};

export default EditDocumentForm;
