import React, { useContext, useEffect, useState } from "react";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../../../../shadcn-components/ui/tabs";

import getBeneficiary from "../../../../../api/getBeneficiary";
import baseUrl from "../../../../../config/baseUrl";
import { AuthContext } from "../../../../../context/Auth/AuthContext";
import {
  ScrollArea,
  ScrollBar,
} from "../../../../../shadcn-components/ui/scroll-area";

const BeneficiaryTabList = ({
  clientID,
  onTabChange,
  selectedTab,
  emailsToBeSent,
  setEmailsToBeSent,
  isValidEmail,
}) => {
  const { token } = useContext(AuthContext);
  const [linkedContacts, setLinkedContacts] = useState([]);
  // const [selectedTab, setSelectedTab] = useState(null);

  const fetchBeneficiaryData = async () => {
    const url = `${baseUrl}/api/get-client-link-data?contact_id=${clientID}`;
    try {
      const response = await getBeneficiary({ api: url, token });
      const contacts = [
        ...response?.clients,
        ...response?.client_link_contacts,
      ];
      setLinkedContacts(contacts);

      let updatedEmails = emailsToBeSent ? emailsToBeSent.split(",") : []; // Convert existing emails to an array

      contacts.forEach((contact) => {
        if (contact.email_id && isValidEmail(contact.email_id)) {
          if (!updatedEmails.includes(contact.email_id)) {
            updatedEmails.push(contact.email_id); // Add email if not already present
          }
        }
      });

      // Update state after the loop
      setEmailsToBeSent(updatedEmails.join(",")); // Join emails with commas

      // if (contacts?.length > 0) {
      // 	setSelectedTab(contacts[0].contact_id); // Set the first tab as selected initially
      // 	onTabChange(contacts[0].contact_id, 0, contacts[0].email_id); // Notify parent component
      // }

      if (
        contacts?.length > 0 &&
        !selectedTab &&
        clientID != contacts[0].contact_id
      ) {
        onTabChange(
          contacts[0].contact_id,
          0,
          contacts[0].email_id,
          contacts[0].name
        );
      } else {
        // Check if selectedTab is present in the contacts array
        const matchedContact = contacts.find(
          (contact) => contact.contact_id === selectedTab
        );

        if (contacts?.length > 0 && matchedContact) {
          // If a matched contact is found, trigger onTabChange with its details
          onTabChange(matchedContact.contact_id, 0, matchedContact.email_id);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchBeneficiaryData();
  }, []);

  const handleTabClick = (contactId, index, email, phoneNumber, name) => {
    //localStorage.removeItem("reqBenId");
    onTabChange(contactId, index, email, phoneNumber, name); // Notify parent component of the tab click
  };

  return (
    <ScrollArea className="h-[60px] w-full rounded-md pt-2 ml-2 ">
      <Tabs value={selectedTab} className="w-auto flex gap-1">
        <TabsList>
          {linkedContacts.map((contact, index) => (
            <TabsTrigger
              value=""
              key={contact.contact_id}
              onClick={() =>
                handleTabClick(
                  contact.contact_id,
                  index,
                  contact.email_id,
                  contact.phone_number,
                  contact.name
                )
              }
              className={`${
                selectedTab === contact?.contact_id ||
                (!selectedTab && index === 0)
                  ? "bg-[#666] text-white"
                  : "bg-inherit text-black"
              }`}
            >
              {index === 0 ? "Primary" : contact?.name}
            </TabsTrigger>
          ))}
        </TabsList>
        <TabsContent value="password">Change your password here.</TabsContent>
      </Tabs>
      <ScrollBar orientation="horizontal" />
    </ScrollArea>
  );
};

export default BeneficiaryTabList;
