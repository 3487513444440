import { BsFillCloudArrowUpFill } from "react-icons/bs";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../shadcn-components/ui/card";
import { Link } from "react-router-dom";
import { Skeleton } from "../../shadcn-components/ui/skeleton";
import { useEffect, useState } from "react";
import dashboardRecentUploads from "../../api/dashboardRecentUploads";
import { formatDate } from "../../utils/formatDate";

function RecentUploadsCard({ customBoxShadow, token }) {
  const [loading, setLoading] = useState(true);
  const [dashboardRecentUploadsResponse, setDashboardRecentUploadsResponse] =
    useState({});

  const dashboardRecentUploadsData = async () => {
    const response = await dashboardRecentUploads({ token });
    if (response.code === 200) {
      setDashboardRecentUploadsResponse(response.data);
    }
  };

  useEffect(() => {
    async function fetchData() {
      await dashboardRecentUploadsData();
      setLoading(false);
    }
    fetchData();
  }, []);

  return (
    <Card style={customBoxShadow}>
      <CardHeader className="pb-3">
        <CardTitle className="flex items-center justify-between  gap-3 notification-title-recent letterSpacing p-4">
          <p className="text-xl font-semibold text-gray-800">Recent Uploads</p>
          <span
            className="request-doc-upload-icon request-doc-icon text-blue-600 transition-transform duration-300 hover:scale-110"
            style={{ position: "relative", marginRight: "10px" }}
          >
            <BsFillCloudArrowUpFill />
          </span>
        </CardTitle>
      </CardHeader>

      <CardContent className="overflow-y-scroll notification-container">
        {!loading ? (
          dashboardRecentUploadsResponse.length > 0 ? (
            dashboardRecentUploadsResponse.map((recentUploads) => {
              return (
                <div
                  className="latest-notification"
                  key={recentUploads.contact_id}
                >
                  <Link
                    to={`/clients/${recentUploads.contact_id}/uploads`}
                    className="go-to-password-reset-form"
                    target="_blank"
                  >
                    {recentUploads.name} at{" "}
                    {formatDate(recentUploads.created_at)}
                  </Link>
                </div>
              );
            })
          ) : (
            <div className="latest-notification">No recent uploads</div>
          )
        ) : (
          <div className="space-y-4">
            {Array(10)
              .fill(0)
              .map((_, index) => (
                <Skeleton key={index} className="h-6 w-full" />
              ))}
          </div>
        )}
      </CardContent>
    </Card>
  );
}

export default RecentUploadsCard;
