import React, { useState, useContext, useEffect } from "react";
import Navbar from "../components/common/Navbar";

import { AuthContext } from "../context/Auth/AuthContext";
import Loading from "../components/material-ui/Loading";
import binData from "../api/bin";
import baseUrl from "../config/baseUrl";
import TableSkeleton from "../components/common/TableSkeleton";
import PaginationSection from "../components/common/PaginationSection";
import DataToolbar from "../components/common/DataToolbar";

import {
  CaretSortIcon,
  ChevronDownIcon,
  MixerHorizontalIcon,
  StarIcon,
  StarFilledIcon,
} from "@radix-ui/react-icons";

import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";

import { Button } from "../shadcn-components/ui/button";
import { Checkbox } from "../shadcn-components/ui/checkbox";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownMenuCheckboxItem,
} from "../shadcn-components/ui/dropdown-menu";
import { Input } from "../shadcn-components/ui/input";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../shadcn-components/ui/select";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../shadcn-components/ui/table";
import RestoreUser from "../components/bin/user-bin/RestoreUser";
import PermanentlyDeleteUser from "../components/bin/user-bin/PermanentlyDeleteUser";
import BulkRestorePopup from "../components/bin/user-bin/BulkRestorePopup";
import BulkPermanentDeletePopup from "../components/bin/user-bin/BulkPermanentDeletePopup";

import "../styles/TrashDocuments.css";

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../shadcn-components/ui/tooltip";

import { useDebounce } from "use-debounce";
import { useRoleContext } from "../context/RolesContext";
import { convertToUTC, formatDate } from "../utils/formatDate";
import { format } from "date-fns";

/*** Main component for listing all the soft deleted users ***/
const TrashUsers = () => {
  const [loading, setLoading] = useState(false);

  const [trashedUsers, setTrashedUsers] = useState([]);
  const { token, user_id, is_admin } = useContext(AuthContext);

  const [userRestored, setUserRestored] = useState(0);
  const [userDeleted, setUserDeleted] = useState(0);

  const [bulkRestoreUser, setBulkRestoreUser] = useState(false);
  const [bulkRestoreUserIds, setBulkRestoreUserIds] = useState();

  const [bulkPermanentDeleteUser, setBulkPermanentDeleteUser] = useState(false);
  const [bulkPermanentDeleteUserIds, setBulkPermanentDeleteUserIds] =
    useState();

  //new states
  const [sorting, setSorting] = useState([]);
  const [columnFilters, setColumnFilters] = useState([]);
  const [columnVisibility, setColumnVisibility] = useState({});
  const [rowSelection, setRowSelection] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [globalFilter, setGlobalFilter] = useState("");
  const [debouncedGlobalFilter] = useDebounce(globalFilter, 1000);
  const [selectAllChecked, setSelectAllChecked] = useState();
  const [showFilterButton, setShowFilterButton] = useState(false);
  const [createdAt, setCreatedAt] = useState(null);
  const [updatedAt, setUpdatedAt] = useState(null);

  // Role based permissions
  // Function to check if the user has a specific permission.
  // Admin users ("is_admin" === "1") automatically have access to all permissions.
  const { permissions } = useRoleContext();
  const hasPermission = (permission) => {
    if (is_admin === "1") {
      return true;
    }
    return permissions.includes(permission);
  };

  // Permission flags for various user actions, granting access based on user roles or admin status.
  // Admin users have all permissions by default.
  const canPermanentlyDeleteUser = hasPermission("delete_users_permanently");
  const canRestoreUser = hasPermission("restore_users");

  //To hide the action column if there is not a single permission
  const shouldShowActionColumn = canPermanentlyDeleteUser || canRestoreUser;

  const trashedUsersData = async () => {
    setLoading(true);

    const offset = currentPage - 1;
    const filterParams = {};

    columnFilters.forEach((filterItem) => {
      filterParams[filterItem.id] = filterItem.value;
    });

    const sortParam = sorting
      .map((sortItem) => {
        return `${sortItem.id}=${sortItem.desc ? "desc" : "asc"}`;
      })
      .join(",");

    const params = {
      isAdmin: is_admin,
      userId: user_id,
      limit: pageSize,
      offset: offset,
      ...filterParams,
      global_search: debouncedGlobalFilter,
      sort: sortParam,
      ...(createdAt ? { created_at: convertToUTC(createdAt) } : {}),
      ...(updatedAt ? { updated_at: convertToUTC(updatedAt) } : {}),
    };
    const queryString = new URLSearchParams(params).toString();
    const url = `${baseUrl}/api/get_deleted_user_data?${queryString}`;

    const response = await binData({ token, api: url });
    if (response.code == 200) {
      setTrashedUsers(response?.soft_deleted_users);
      setTotalPages(Math.ceil(response?.total / pageSize));
    } else {
      setTrashedUsers([]);
    }
    setLoading(false);
  };

  useEffect(() => {
    trashedUsersData();
  }, [
    bulkRestoreUser,
    bulkPermanentDeleteUser,
    debouncedGlobalFilter,
    currentPage,
    columnFilters,
    userRestored,
    userDeleted,
    pageSize,
    sorting,
    createdAt,
    updatedAt,
  ]);

  const setUserRestoredToTrue = (userList) => {
    if (userList) {
      currentPage === 0
        ? setCurrentPage((currentPage) => currentPage + 1)
        : setCurrentPage((currentPage) => currentPage - 1);
    }
    setUserRestored((count) => count + 1);
  };

  const setUserDeletedToTrue = (userList) => {
    setUserDeleted((count) => count + 1);
  };

  const handleBulkAction = async (actionType) => {
    const selectedRowsData = table
      .getFilteredSelectedRowModel()
      .rows.map((row) => row.original);
    const ids = selectedRowsData.map((row) => row.id).join(",");
    if (actionType === "restore") {
      setBulkRestoreUserIds(ids);
      setBulkRestoreUser(true);
    } else {
      setBulkPermanentDeleteUserIds(ids);
      setBulkPermanentDeleteUser(true);
    }
  };

  const columns = [
    {
      id: "select",
      header: ({ table }) => (
        <div className="flex items-center">
          <Checkbox
            checked={
              table.getIsAllPageRowsSelected() ||
              (table.getIsSomePageRowsSelected() && "indeterminate")
            }
            onCheckedChange={(value) =>
              table.toggleAllPageRowsSelected(!!value)
            }
            aria-label="Select all"
          />
          {(table.getIsSomePageRowsSelected() ||
            table.getIsAllPageRowsSelected()) && (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button
                  variant="outline"
                  className="p-0 !border-none !shadow-none bg-transparent"
                >
                  <ChevronDownIcon className="" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuItem
                  onSelect={() => handleBulkAction("restore")}
                  disabled={!canRestoreUser}
                >
                  Restore
                </DropdownMenuItem>
                <DropdownMenuItem
                  onSelect={() => handleBulkAction("delete_permanently")}
                  disabled={!canPermanentlyDeleteUser}
                >
                  Permanent Delete
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          )}
        </div>
      ),
      cell: ({ row }) => (
        <Checkbox
          checked={row.getIsSelected()}
          onCheckedChange={(value) => row.toggleSelected(!!value)}
          aria-label="Select row"
        />
      ),
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: "first_name",
      displayName: "First Name",
      filterable: true,
      header: ({ column }) => (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className="p-0"
        >
          First Name
          <CaretSortIcon className="ml-2 h-4 w-4" />
        </Button>
      ),
      cell: ({ row }) => (
        <div className="capitalize">{row.getValue("first_name")}</div>
      ),
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
    },
    {
      accessorKey: "last_name",
      displayName: "Last Name",
      filterable: true,
      header: ({ column }) => (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className="p-0"
        >
          Last Name
          <CaretSortIcon className="ml-2 h-4 w-4" />
        </Button>
      ),
      cell: ({ row }) => (
        <div className="capitalize">{row.getValue("last_name")}</div>
      ),
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
    },
    {
      accessorKey: "email",
      displayName: "Email",
      filterable: true,
      header: ({ column }) => (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className="p-0"
        >
          Email
          <CaretSortIcon className="ml-2 h-4 w-4" />
        </Button>
      ),
      cell: ({ row }) => (
        <div className="capitalize">{row.getValue("email")}</div>
      ),
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
    },
    {
      accessorKey: "is_admin",
      displayName: "Role",
      filterable: true,
      header: ({ column }) => (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className="p-0"
        >
          Role
          <CaretSortIcon className="ml-2 h-4 w-4" />
        </Button>
      ),
      cell: ({ row }) => (
        <div className="capitalize">
          {row.getValue("is_admin") === "1" ? "Admin" : "Non Admin"}
        </div>
      ),
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
    },
    {
      accessorKey: "status",
      displayName: "Status",
      filterable: true,
      header: ({ column }) => (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className="p-0"
        >
          Status
          <CaretSortIcon className="ml-2 h-4 w-4" />
        </Button>
      ),
      cell: ({ row }) => (
        <div className="capitalize">
          {row.getValue("status") === "active" ? "Active" : "Inactive"}
        </div>
      ),
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
    },
    {
      accessorKey: "created_at",
      displayName: "Created At",
      header: ({ column }) => (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className="p-0"
        >
          Created At
          <CaretSortIcon className="ml-2 h-4 w-4" />
        </Button>
      ),
      cell: ({ row }) => {
        const createdAt = row.getValue("created_at");
        return (
          <div className="capitalize text-wrap">{formatDate(createdAt)}</div>
        );
      },
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
    },
    {
      accessorKey: "updated_at",
      displayName: "Modified At",
      header: ({ column }) => (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className="p-0"
        >
          Modified At
          <CaretSortIcon className="ml-2 h-4 w-4" />
        </Button>
      ),
      cell: ({ row }) => {
        const updated = row.getValue("updated_at");
        return (
          <div className="capitalize text-wrap">{formatDate(updated)}</div>
        );
      },
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
    },
    {
      id: "actions",
      displayName: "Actions",
      enableHiding: false,
      header: ({ column }) => {
        if (!shouldShowActionColumn) {
          return null;
        }
        return (
          <Button variant="ghost" className="p-0 !cursor-default">
            Actions
          </Button>
        );
      },
      cell: ({ row }) => {
        return (
          <div className="flex gap-2">
            {canRestoreUser && (
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger>
                    <span>
                      <RestoreUser
                        setUserRestoredToTrue={setUserRestoredToTrue}
                        id={row.original.id}
                      />
                    </span>
                  </TooltipTrigger>
                  <TooltipContent className="bg-gray-600">
                    <p>Restore</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            )}

            {canPermanentlyDeleteUser && (
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger>
                    <span>
                      <PermanentlyDeleteUser
                        setUserDeletedToTrue={setUserDeletedToTrue}
                        id={row.original.id}
                        setRowSelection={setRowSelection}
                      />
                    </span>
                  </TooltipTrigger>
                  <TooltipContent className="bg-gray-600">
                    <p>Permanently Delete</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            )}
          </div>
        );
      },
    },
  ];

  const table = useReactTable({
    data: trashedUsers,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    initialState: {
      pageSize: 20,
    },
    manualPagination: true,
    manualSorting: true,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
      pageSize,
    },

    globalFilterFn: (rows, columnFilters) => {
      const [globalFilter] = columnFilters.filter(
        (filter) => filter.id === "global"
      );
      if (!globalFilter || !globalFilter.value) return rows;

      return rows.filter((row) => {
        const name = row.original.name.toLowerCase(); // Assuming 'name' is the column key
        return name.includes(globalFilter.value.toLowerCase());
      });
    },
    onGlobalFilterChange: (filterValue) => {
      setGlobalFilter(filterValue); // Update the global filter state
    },
  });

  return (
    <>
      {bulkRestoreUser ? (
        <BulkRestorePopup
          setUserRestoredToTrue={setUserRestoredToTrue}
          Userbulkid={bulkRestoreUserIds}
          bulkRestoreUser={bulkRestoreUser}
          setBulkRestoreUser={setBulkRestoreUser}
          setRowSelection={setRowSelection}
        />
      ) : null}

      {bulkPermanentDeleteUser ? (
        <BulkPermanentDeletePopup
          setUserDeletedToTrue={setUserDeletedToTrue}
          Userbulkid={bulkPermanentDeleteUserIds}
          bulkPermanentDeleteUser={bulkPermanentDeleteUser}
          setBulkPermanentDeleteUser={setBulkPermanentDeleteUser}
          setRowSelection={setRowSelection}
        />
      ) : null}
      <div className="document-container">
        <div className="navbar">
          <Navbar />
        </div>
        <div className="documents-content">
          <div className="document__table mt-3">
            <div className="w-full bg-white px-2">
              <div className="flex justify-between items-center py-4 flex-wrap gap-4">
                <Input
                  placeholder="Search..."
                  value={globalFilter}
                  onChange={(event) =>
                    table.setGlobalFilter(event.target.value)
                  }
                  className="max-w-sm"
                />
                <div className="flex gap-2">
                  <Button
                    variant="outline"
                    className=""
                    onClick={() => setShowFilterButton(!showFilterButton)}
                  >
                    <MixerHorizontalIcon className="mr-1" />
                    Filter
                  </Button>

                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button variant="outline" className="ml-auto">
                        Columns <ChevronDownIcon className="ml-2 h-4 w-4" />
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="end">
                      {table
                        .getAllColumns()
                        .filter((column) => column.getCanHide())
                        .map((column) => {
                          return (
                            <DropdownMenuCheckboxItem
                              key={column.id}
                              className="capitalize"
                              checked={column.getIsVisible()}
                              onCheckedChange={(value) =>
                                column.toggleVisibility(!!value)
                              }
                            >
                              {column.columnDef.displayName}
                            </DropdownMenuCheckboxItem>
                          );
                        })}
                    </DropdownMenuContent>
                  </DropdownMenu>
                </div>
              </div>
              {showFilterButton && (
                <DataToolbar
                  table={table}
                  apiUrl="/api/get-deleteduser-filter-name"
                  createdAt={createdAt}
                  updatedAt={updatedAt}
                  setCreatedAt={setCreatedAt}
                  setUpdatedAt={setUpdatedAt}
                />
              )}
              <div className="rounded-md border">
                {loading ? (
                  <TableSkeleton />
                ) : (
                  <Table>
                    <TableHeader className="pb-4 sticky top-0 bg-white z-[1]">
                      {table.getHeaderGroups().map((headerGroup) => (
                        <TableRow key={headerGroup.id}>
                          {headerGroup.headers.map((header) => {
                            return (
                              <TableHead key={header.id}>
                                {header.isPlaceholder
                                  ? null
                                  : flexRender(
                                      header.column.columnDef.header,
                                      header.getContext()
                                    )}
                              </TableHead>
                            );
                          })}
                        </TableRow>
                      ))}
                    </TableHeader>
                    <TableBody>
                      {table.getRowModel().rows?.length ? (
                        table.getRowModel().rows.map((row) => (
                          <TableRow
                            key={row.id}
                            data-state={row.getIsSelected() && "selected"}
                          >
                            {row.getVisibleCells().map((cell) => (
                              <TableCell key={cell.id}>
                                {flexRender(
                                  cell.column.columnDef.cell,
                                  cell.getContext()
                                )}
                              </TableCell>
                            ))}
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell
                            colSpan={columns.length}
                            className="h-24 text-center"
                          >
                            {loading ? <TableSkeleton /> : "no records found"}
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                )}
              </div>
              <div className="flex items-center justify-between space-x-2 py-4 flex-wrap gap-4">
                <div className="flex items-center gap-5">
                  <div className="flex-1 text-sm text-muted-foreground">
                    {table.getFilteredSelectedRowModel().rows.length} of{" "}
                    {table.getFilteredRowModel().rows.length} row(s) selected.
                  </div>
                  <div className="flex items-center space-x-2">
                    <p className="text-sm font-medium">Rows per page</p>
                    <Select
                      value={`${table.getState().pageSize}`}
                      onValueChange={(value) => {
                        setPageSize(Number(value));
                        setCurrentPage(1);
                      }}
                    >
                      <SelectTrigger className="w-auto">
                        <SelectValue placeholder={table.getState().pageSize} />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectGroup>
                          {[10, 20, 30, 40, 50].map((pageSize) => (
                            <SelectItem key={pageSize} value={`${pageSize}`}>
                              {pageSize}
                            </SelectItem>
                          ))}
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                  </div>
                </div>
                <div className="space-x-2">
                  <PaginationSection
                    setCurrentPage={setCurrentPage}
                    totalPages={totalPages}
                    currentPage={currentPage}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TrashUsers;
